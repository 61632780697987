import React from 'react'
import './style.css'
import axios from 'axios'
import CircularProgress from '@material-ui/core/CircularProgress';
import {connect} from 'react-redux'
import { setAvatar } from '../../../actions/userActions';
import { Mixpanel } from '../../../Mixpanel'

class Avatar extends React.Component{

    //constructor
    constructor(props){
        super(props)
        this.state = {
          // Set initial files
          files: [],
          position:null,
          avatarphoto:'/assets/img/avatar.png'
      };
    }

    componentDidMount(){
        Mixpanel.track('Avatar Pageview')

    }


    handleChange = event =>{
        this.setState({position:event.currentTarget.dataset.figure_type})
    
        const basedev = "https://api.mentalhappy.com/api/"
        const data = new FormData()
        data.append('filepond', event.target.files[0],event.target.files[0].name)
    
          const headers = {
            'Authorization':"Bearer " + localStorage.getItem('access_token')
        }  
        var endpoint =basedev+'user/avatar'
            this.setState({position:'avatar'})
        
          axios.post(endpoint ,data ,{headers:headers})
          .then(res => {
            this.setState({avatarphoto:'https://mentalhappy.s3.us-west-2.amazonaws.com/profile/avatar/'+res.data.filename,position:null})
            this.props.setAvatar('https://mentalhappy.s3.us-west-2.amazonaws.com/profile/avatar/'+res.data.filename)
            Mixpanel.track('Avatar Changed')
          }).catch(error=>{
            this.setState({position:null})
    
          })
          
          
    
      }
    

    render(){
        return(
            <div>
                <div className="widget-header bordered no-actions d-flex align-items-center">
                    <h4>Upload Profile Picture</h4>
                </div>                
                <div className="avatar-box">
                    <img src={this.state.avatarphoto}  alt="..." style={{width: 150,height:150}} className="avatar rounded-circle d-block mx-auto"  />

                    <div className="form-group">
                        <div className="input-group">
                         
                            <input type="file"  data-figure_type="avatar" id='figure1' className="form-control upload"  onChange={this.handleChange} />
                        </div>
                    </div>
                </div>

{this.state.position==="avatar" ?<CircularProgress style={{position:'absolute',left:'44%',top:220}} />:null} 

            </div>
        )
    }





}

const mapDispatchToProps = dispatch=>{
    return{
        setAvatar:(data)=>dispatch(setAvatar(data))
    }
}

export default connect(null,mapDispatchToProps) (Avatar)