import React from 'react'
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {Elements} from 'react-stripe-elements';
import InjectedCheckoutForm from '../../../screens/payment/CheckoutFormDash';

import '../../../screens/payment/Payment.css'

import { Mixpanel } from '../../../Mixpanel'
import { StripeProvider } from 'react-stripe-elements';



class PaymentDialog extends React.Component{

  state = {
    open: false,
    paid:false
  };

  componentDidMount(){

    if (window.Stripe) {
      this.setState({stripe: window.Stripe('pk_live_VqXDUfG9MoJu4lmzw3gNlXyp')});
    } else {
      document.querySelector('#stripe-js').addEventListener('load', () => {
        // Create Stripe instance once Stripe.js loads
        this.setState({stripe: window.Stripe('pk_live_VqXDUfG9MoJu4lmzw3gNlXyp')});
      });
    }

    

    this.setState({paid:false})
    Mixpanel.track('Payment Dialog')



  }


      handleClickOpen = () => {
        this.setState({ open: true });
      };
    
      handleClose = () => {
        this.setState({ open: false });
      };

      handleSuccess =()=>{
        this.setState({paid:true})
        this.props.getcredits()
      }
    componentWillUpdate(props){
 
    }

    render() {

        if(this.state.paid === true){
          Mixpanel.track('Payment Completed')

          return(<div>
            <div className="alert alert-outline-success" role="alert">
                                            <strong>Yay!</strong> Your credits have been added.
                                        </div>

          </div>)
        }else
        return (
          <div>
                                    <div class="button text-center" style={{display:'block',margin:'0 auto'}}>
                        <button type="submit" className="button buttonGreen"  onClick={this.handleClickOpen}>
                        Order
    <div className="ripples buttonRipples"><span className="ripplesCircle"></span></div>
  </button>
                        </div>

            <Dialog
              open={this.state.open}
              onClose={this.handleClose}
              aria-labelledby="form-dialog-title"
            >
              <DialogTitle id="form-dialog-title"></DialogTitle>
              <DialogContent>
                  
              <div className="pricing-tables-01 pricing-wrapper" style={{border:'none'}}>

                <div className="title">{this.props.price.package_amount} </div>
                <div className="container-text">Kits at {this.props.price.price} ea.</div>
                <div className="main-number">{this.props.price.package_amount * this.props.price.price}</div>
            </div>
            <StripeProvider apiKey="pk_live_VqXDUfG9MoJu4lmzw3gNlXyp">

              <Elements>
                            <InjectedCheckoutForm  onSuccess={this.handleSuccess}  />
                        </Elements>

            </StripeProvider>


              </DialogContent>
              <DialogActions>
               {/* <Button onClick={this.handleClose} color="primary">
                  Cancel
                </Button>
                <Button onClick={this.handleClose} color="primary">
                  Subscribe
        </Button> */}
              </DialogActions>
            </Dialog>
          </div>
        );
      }



}

export default PaymentDialog