import React from 'react'
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';




import { withStyles} from '@material-ui/core/styles';



const styles = theme => ({

    cssLabel: {
      '&$cssFocused': {
        color:"#1aa8bc",
      },
      '&:after': {
        borderBottomColor:"#1aa8bc",
      }  
    },
    cssFocused: {},
    cssUnderline: {
      '&:after': {
        borderBottomColor: "#1aa8bc",
      },
    },
    cssOutlinedInput: {
      '&$cssFocused $notchedOutline': {
        borderColor:  "#1aa8bc",
      },
    },
  
  });
class SigneeInvite extends React.Component{

  state = {
    open: false,
    paid:false
  };

  componentDidMount(){

    //this.setState({paid:false})
   // Mixpanel.track('Payment Dialog')



  }


      handleClickOpen = () => {
       this.setState({ open: true });
      };
    
      handleClose = () => {
       this.setState({ open: false });
      };

      handleSuccess =()=>{
       // this.setState({paid:true})
       // this.props.getcredits()
      }
    componentWillUpdate(props){
 
    }

    render() {
        const { classes } = this.props;
        console.log("called")
        return (
          <div>
                        <button type="submit" className="button buttonGreen"  onClick={this.handleClickOpen}>
                        Invite co-workers to sign this note.
    <div className="ripples buttonRipples"><span className="ripplesCircle"></span></div>
  </button>

            <Dialog
              open={this.state.open}
              onClose={this.handleClose}
              aria-labelledby="form-dialog-title"
            >
              <DialogTitle id="form-dialog-title"></DialogTitle>
              <DialogContent>
                  
                     
              <TextField
                                    label="Email Address"
                                    defaultValue=""
                                    margin="normal"
                                    className="auth-input "
                                    name="email"
                                    defaultValue={this.props.firstName} 
                                    onChange={(e) => {this.props.onSetName(this.props.gender,e.target.value, this.props.lastName,this.props.email);}}                                      
                                    InputLabelProps={{
                                        classes:{
                                        root: classes.cssLabel,
                                        focused: classes.cssFocused,
                                        underline: classes.cssOutlinedInput
                                        }
                                    }}
                                    
                                    />                 


              </DialogContent>
              <DialogActions>
               {/* <Button onClick={this.handleClose} color="primary">
                  Cancel
                </Button>
                <Button onClick={this.handleClose} color="primary">
                  Subscribe
        </Button> */}
              </DialogActions>
            </Dialog>
          </div>
        );
      }



}

export default withStyles(SigneeInvite)