import React from 'react'
import Typography from '@material-ui/core/Typography';



class PricingFeatures extends React.Component{


    render(){
        return(
            <div>
                <div style={{textAlign:'center'}}>
                <Typography variant="headline">Every Package Includes</Typography> 
                </div>



            </div>
        )
    }

}

export default PricingFeatures