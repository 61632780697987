import { SET_PRICING,Get_PRICING,PRICING_REQUEST,USER_ERROR} from '../actions/types'

const initialState = {
    pricing:[],
    errors:null,
    setprice:{}

}


export default function(state = initialState,action){

    switch(action.type){
        case Get_PRICING:
            return{
                ...state,
                pricing:action.payload.results,
                errors:null,
            }
        case SET_PRICING:
            return{
                ...state,
                setprice:action.payload,
                errors:null,
            }            
        case PRICING_REQUEST:
            return{
                ...state,
                errors:null,
            }
        case USER_ERROR:
            return{
                ...state,
                errors:true
            }
        default:
            return state;

    }

}