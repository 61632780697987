import React, { Component } from 'react';
import { Switch, Route ,Link} from 'react-router-dom';

import { connect } from 'react-redux'

import Wrapper from '../../components/wrapper/Wrapper';
import CheerBoxLogo from '../../assets/img/cheerbox-logo.svg';
import { getUser,createUser } from '../../actions/userActions'
import './Payment.css'
import { referrerInvite  } from '../../actions/inviteAction'
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

class ReferrerContact extends Component {
    state = {
        multiline: 'Controlled',
        name:'',
        email:''
      };
    
      handleChange = name => event => {
        this.setState({
          [name]: event.target.value,
        });
      };

      sendInvite = ()=>{

        let postData = {
            name:this.state.name,
            email:this.state.email
        }

        this.props.sendReferrer(postData)

      }
    


  render() {

    if(this.props.referrer_status === false){
    return (
      <Wrapper>
			<div className="form-box">
				<div className="container">
					<div className="row">
                    <div className="col-md-12  animated fadeIn">

                            <div class="form-group row mb-3">
                            <div class="col-xl-6 mb-3 col-md-10 col-lg-10" style={{margin:'0 auto'}}>
                                <label class="form-control-label">Name<span class="text-danger ml-2">*</span></label>
                                <input type="text" value={this.state.name} class="form-control" onChange={this.handleChange('name')} />
                            </div>
                            <div class="col-xl-6">
                                <label class="form-control-label">Email<span class="text-danger ml-2">*</span></label>
                                <input type="email" value={this.state.email} class="form-control" onChange={this.handleChange('email')}
 />
                            </div>
                        </div>

                        <div class="section-title mt-5 mb-5" style={{width:'100%',textAlign:'center'}}>
                                                                <h4>Forwardable Email</h4>
                                                            </div>

                    <div  class="col-xl-10 col-lg-8 has-shadow" style={{height:300,paddingTop:50,fontSize:15,margin:'0 auto'}}>
                    Hi {this.state.name},<br/><br/>

{this.props.user.firstname} asked us to send you $295 to use MentalHappy for employee wellness and support. <br/><br/>

Your friend {this.props.user.firstname} has invited you to check out MentalHappy Kits. Just accept this invite for a free account, plus a $295 in account credit on Starter, Pro or Growth options. 
<br/><br/>
Thank You,<br/>
MentalHappy Team
                    </div>



                    <div style={{flexDirection:'row',alignItems:'center',width:'100%',textAlign:'center',paddingTop:20}}>
                <Button variant="contained" color="primary" 
                  onClick={this.sendInvite}
                >
                  Send Invite
                </Button>

                <div style={{flexDirection:'column',alignItems:'center',width:'100%',textAlign:'center',paddingTop:20,paddingBottom:50}}>

                <Link  to="/dashboard/order"> Go to dashboard
  </Link>
  </div>
              </div>
                    </div>

                    
                    </div>
					</div>
			</div>


      	</Wrapper>
    )
}else{
    return(
        <Wrapper>
        <div className="form-box">
            <div className="container">
                <div className="row">
                <div className="col-md-12  animated fadeIn">

                    <div class="section-title mt-5 mb-5" style={{width:'100%',textAlign:'center'}}>
                                                            <h4>Great! Thank you for the referral. <br/>Once {this.state.name} makes a purchase you will be notified to claim your reward.</h4>
                                                        </div>


            <div style={{flexDirection:'column',alignItems:'center',width:'100%',textAlign:'center',paddingTop:20,paddingBottom:50}}>

            <Link  to="/dashboard/order"> Go to dashboard
</Link>
</div>
                </div>

                
                </div>
                </div>
        </div>


      </Wrapper>
    )
    }
  }
}

const mapStatesToProps = state =>{
    return{
        user:state.user.user,
        token:state.user.userToken,
        referrer_status:state.invite.referrer_status

    }
}

const mapDispatchToProps = dispatch =>{
    return{
        getUser: ()=>dispatch(getUser()),
        sendReferrer:(data)=>dispatch(referrerInvite(data))
        
    }
}
export default connect(mapStatesToProps,mapDispatchToProps) (ReferrerContact);
