import { Get_PRICING,PRICING_REQUEST,REQUEST_ERROR, SET_PRICING} from './types'
import axios from 'axios';

const basedev = "https://api.mentalhappy.com/api/"
//const basedev = 'http://cheerbox.dev.com/api/'

export const getPricing=()=>dispatch=>{

    
    var endpoint = basedev +'pricing'
    var headers = {
        'Accept': 'application/json',
    } 
        dispatch({
            type:PRICING_REQUEST
        })
      axios.get(endpoint , {headers: headers})
        .then(res => {

            dispatch({
                type:Get_PRICING,
                payload: res.data
            })
        }).catch(error=>{
            dispatch({
                type:REQUEST_ERROR,
                payload:null
            })
        })
}


export const setPricing=(data)=>dispatch=>{

    dispatch({
        type:SET_PRICING,
        payload:data
    })
}

