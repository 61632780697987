import React, { Component } from 'react';
import {connect} from 'react-redux'
import { Mixpanel } from '../../../Mixpanel'

class Profile extends Component {


    componentDidMount(){
        Mixpanel.track('Profile Pageview')
    }

  render() {

    return (
      <div>
                                    <div className="widget-header bordered no-actions d-flex align-items-center">
                                        <h4>Update Profile</h4>
                                    </div>
                                    <div className="widget-body">
                                        <div className="col-10 ml-auto">
                                            <div className="section-title mt-3 mb-3">
                                                <h4>Personal Information</h4>
                                            </div>
                                        </div>
                                        <form className="form-horizontal">
                                            <div className="form-group row d-flex align-items-center mb-5">
                                                <label className="col-lg-2 form-control-label d-flex justify-content-lg-end">Full Name</label>
                                                <div className="col-lg-6">
                                                    <input type="text" className="form-control" defaultValue={this.props.user && this.props.user.firstname+' '+this.props.user.lastname} placeholder="David Green" />
                                                </div>
                                            </div>
                                            <div className="form-group row d-flex align-items-center mb-5">
                                                <label className="col-lg-2 form-control-label d-flex justify-content-lg-end">Email</label>
                                                <div className="col-lg-6">
                                                    <input type="text" className="form-control" defaultValue={this.props.user && this.props.user.email} placeholder="email address"/>
                                                </div>
                                            </div>

                                        </form>

                                        <div className="em-separator separator-dashed"></div>
                                        <div className="text-right">
                                            <button className="button buttonGreen" type="submit">Save Changes</button>
                                            <button className="btn btn-shadow" type="reset">Cancel</button>
                                        </div>
                                    </div>
                                          </div>
    );
  }
}

const mapStateToProps = state =>{
    return{
      user:state.user.user.user,
      avatar:state.user.avatar
    }
  }
  
  
  export default connect(mapStateToProps,null) (Profile)
