import React, { Component } from 'react';
import { Switch, Route ,Link,Redirect} from 'react-router-dom';

import { connect } from 'react-redux'

import Wrapper from '../../components/wrapper/Wrapper';
import CheerBoxLogo from '../../assets/img/cheerbox-logo.svg';
import {createUser } from '../../actions/userActions'
import Typography from '@material-ui/core/Typography';
import { withStyles} from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import TextField from '@material-ui/core/TextField';
import { Mixpanel } from '../../Mixpanel'
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import ReferrerContact from './ReferrerContact';


const styles = theme => ({
    root: {
        width: '90%',
    },
    overrides: {
        MuiStepIcon: {
         root: {
           '&$completed': {
             color: 'pink',
           },
           '&$active': {
             color: 'red',
           },
         },
         active: {},
         completed: {},
       }},
    button: {
        marginTop: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        background:'#1aa8bc',
        '&$active': {
            color: '#1aa8bc',
          },
          color:'#fff'
    },   
    actionsContainer: {
        marginBottom: theme.spacing.unit * 2,
    },
    resetContainer: {
        padding: theme.spacing.unit * 3,
        width:'100%'
    },
    stepIcon: {
        color: "#1bb1c3 !important",

              },
              container: {
                display: 'flex',
                flexWrap: 'wrap',
              },
              textField: {
                marginLeft: theme.spacing.unit,
                marginRight: theme.spacing.unit,
              },
              dense: {
                marginTop: 16,
              },
              menu: {
                width: 200,
              }
              
});



function getSteps() {
    return ['Invite a Business','They purchase Kits','$295 Reward'];
}

const country = [
    { 'label': 'United States', name: 'US' },
    { 'label': 'Canada', name: 'CA' },
    { 'label': 'United Kingdom', name: 'UK' },
    { 'label': 'Australia', name: 'Au' },

]


function getStepContent(step) {
    switch (step) {
        case 0:
            Mixpanel.track('Cheerbox Type Field')

            return (
<div class="tab-pane active show" id="tab1">
<ReferrerContact />

                                                </div>
    
            )       
        case 1:
        Mixpanel.track('Cheerbox Recipient Field')

            return (

<div className="form-box">
				<div className="container">
					<div className="row">
                    <div className="col-md-12  animated fadeIn">
                    <div class="section-title mt-5 mb-5">
                        <h4>Purchasing MentalHappy Kits</h4>
                    </div>





                    </div>
                    </div>
                    </div>
                    </div>

            )
        case 2:
        Mixpanel.track('Cheerbox Note Field')
            return (
<div></div>

            )

        default:
            return 'Unknown step';
    }
}


class Referrer extends Component {

    state ={
        firstName:'',
        lastName:'',
        email:'',
        password:'',
        agree:false,
        agreeclass:'',
        activeStep: 0,
        confirm:false,
        completed:false,
        multiline:'Controlled'
      }


      componentDidMount(){
        document.title="Mentalhappy | Referrer Page "
        Mixpanel.track('Referrer Page')

    }

  handleChange = e=>{
    this.setState({ [e.target.name]: e.target.value })

  }

  handleNext = () => {
    if(this.state.activeStep === 4){
    this.setState(state => ({
        activeStep: state.activeStep + 1,confirm:true,completed:false
    }));

    }else{
        this.setState(state => ({
            activeStep: state.activeStep + 1
        }));
    }

};

handleBack = () => {
    this.setState(state => ({
        activeStep: state.activeStep - 1,
    }));
};



  render() {

    if(this.props.created === true){
        this.props.history.push('/payment')
    }



    const { classes } = this.props;
    const steps = getSteps();
    const { activeStep } = this.state;
    return (
      <Wrapper>
                  <div className="logo-section">
			<div className="container">
				<div className="row">
					<div className="col-md-4 head-group">
						<div className="mental-happy-logo">
              <img src="../../../assets/img/mental-happy-logo.png" alt="Mental happy logo" width='120'/>

						</div>
					</div>
				</div>
			</div>
		</div>

        
                    
        <div className="container">
          <div className="row ">
          <div class="section-title mt-5 mb-5" style={{width:'100%',textAlign:'center'}}>
          <img src="https://mentalhappy.s3-us-west-2.amazonaws.com/corporate-assets/youthebest.png" className="img-responsive" style={{width:100}} />

            <h2>Tell business owners about MentalHappy, you both get $295</h2>


        </div>
        <Stepper activeStep={activeStep} alternativeLabel style={{width:'100%',background:'inherit'}}>
          {steps.map(label => (
            <Step key={label}>
                <StepLabel 
                                                      StepIconProps={{
                                                        classes: { root: classes.stepIcon }
                                                      }}
                                    >{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        </div>

        <div>
          {this.state.activeStep === steps.length ? (
            <div>
              <Typography className={classes.instructions}>All steps completed</Typography>
              <Button onClick={this.handleReset}>Reset</Button>
            </div>
          ) : (
            <div>
              {getStepContent(activeStep)}
             {/*<div style={{flexDirection:'row',alignItems:'center',width:'100%',textAlign:'center',paddingTop:20}}>
                <Button variant="contained" color="primary" 
                  onClick={this.sendInvite}
                >
                  Send Invite
                </Button>
          </div> */}
            </div>
          )}
        </div>
      </div>
    
      	</Wrapper>
    );
  }
}


const mapStateToProps = state =>{
    return{
      user:state.user.user,
      error:state.user.errors,
      created:state.user.created
    }
  }
  
  const mapDispatchToProps = dispatch =>{
    return{
      create:(data)=>dispatch(createUser(data))

    }
  }
  export default connect(mapStateToProps,mapDispatchToProps) (withStyles(styles)(Referrer));
