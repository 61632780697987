import {USER_ERROR, USER_REQUEST,USER_FETCH,CREATE_USER,GET_USER,REQUEST_ERROR,GET_AVATAR,CREATE_AVATAR,GET_AVATAR_REQUEST,SET_AVATAR} from './types'
import axios from 'axios';

const basedev = "https://api.mentalhappy.com/api/"
//var basedev = "http://cheerbox.dev.com/api/"

export const getUser=()=>dispatch=>{

    
    var endpoint = basedev +'user'
    var headers = {
        'Accept': 'application/json',
        'Authorization':"Bearer " + localStorage.getItem('access_token')

    } 
        dispatch({
            type:USER_REQUEST,
            payload:null
        })
      axios.get(endpoint , {headers: headers})
        .then(res => {
            dispatch({
                type:USER_FETCH,
                payload: res.data
            })
        }).catch(error=>{

        })
}

export const createUser=(data)=>dispatch=>{

    
    var endpoint = basedev +'user'
    var headers = {
        'Accept': 'application/json',
    } 
        dispatch({
            type:USER_REQUEST
        })
      axios.post(endpoint,data, {headers: headers})
        .then(res => {
            localStorage.setItem('access_token',res.data.access_token);

            dispatch({
                type:CREATE_USER,
                payload: res.data
            })
        }).catch(error=>{
            dispatch({
                type:REQUEST_ERROR,
                payload:error.response.data
            })
        })
}

export const setAvatar=(data)=>dispatch=>{

    dispatch({
        type:SET_AVATAR,
        payload:data
    })
}

export const createAvatar=(data)=>dispatch=>{

    
    var endpoint = basedev +'avatar'
    var headers = {
        'Accept': 'application/json',
    } 
        dispatch({
            type:GET_AVATAR_REQUEST
        })
      axios.post(endpoint,data, {headers: headers})
        .then(res => {

            dispatch({
                type:CREATE_AVATAR,
                payload: res.data
            })
        }).catch(error=>{
            dispatch({
                type:REQUEST_ERROR,
                payload:null
            })
        })
}
