import React, { Component } from 'react';
import { Link } from 'react-router-dom';


class SummaryWidgetCredit extends Component {


  render() {


    if(this.props.count !== undefined && this.props.count.length >0){

      var countamount=this.props.count[0].total_credits - this.props.count[0].credits_used;

    }
    else
    var countamount=0;

    //
    return (
      <div className="col-xl-4 col-md-6 col-sm-6">
        <div className="widget widget-12 has-shadow">
          <div className="widget-body">
            <div className="media">
              <div className="align-self-center ml-5 mr-5">

                <span className="dashboard-count total-cheerbox-sent">
                  {countamount} </span>
                
               
              </div>

              <div className="media-body align-self-center">
                <div className="title text-twitter">{this.props.title}</div>
                <div className="number">{this.props.action}</div>
               

              </div>
            
            </div>
            <div style={{textAlign: 'center', marginTop: 8}}>

            <Link className="button buttonGreen" to="/dashboard/credits" style={{marginLeft: 10}}>Add Credits</Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SummaryWidgetCredit;
