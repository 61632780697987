import * as actions from '../actions/cheerActions';

const initialState = {
    type: -1,
    reason: '',
    expertNote: true,
    relationship: '',

    recipient_first_name:'',
    recipient_last_name:'',
    recipient_email:'',
    notes:'',
    user_notes:'',
    notefrom:'',
    recipient_address_country:'',
    recipient_address:'',
    recipient_route:'',
    recipient_address_city:'',
    recipient_address_state:'',
    recipient_address_zip:'',
    box_preference:'',
    gender_specific:'',

    orders:[],
    create_status:false,
    credit_status:false
}

const reducer = (state = initialState, action) => {
    switch(action.type) {
        case actions.SET_TYPE:
            var preference
            switch(action.payload.type){
                case 0:
                    preference="Loss"
                    break;
                case 1:
                    preference="Injury/Illness"
                    break;
                case 2:
                    preference="New Parents"
                    break;
                case 3:
                    preference="Appreciation"
                    break;
                default:
                    preference="General"
                    

            }
            return {
                ...state,
                type: action.payload.type,
                box_preference:preference
            };
        case actions.SET_NAME:
            return {
                ...state,
                recipient_first_name: action.payload.firstName,
                recipient_last_name: action.payload.lastName,
                recipient_email:action.payload.email,
                gender_specific:action.payload.gender
            };
        case actions.SET_REASON:
            return {
                ...state,
                reason: action.payload.reason,
            };
        case actions.SET_NOTE:
            return {
                ...state,
                notefrom: action.payload.noteSignedBy,
                user_notes: action.payload.userNote,
            };
        case actions.SET_RELATIONSHIP:
            return {
                ...state,
                relationship: action.payload.relationship,
            };
        case actions.SET_DETAILS:
            return {
                ...state,
                notes: action.payload.details,
            };            
        case actions.SET_ADDRESS:
            return {
                ...state,
                recipient_address_country: action.payload.country,
                recipient_address: action.payload.street,
                recipient_route: action.payload.street2,
                recipient_address_city: action.payload.city,
                recipient_address_state: action.payload.state,
                recipient_address_zip: action.payload.zip,

            };   
        case actions.CREATE_ORDER:
            return {
                ...state,
                orders: [...action.payload],
                create_status:true
            };  
        case actions.CREDIT_ERROR:
            return {
                ...state,
                credit_status:true
            };  

        default: 
            return {...state};
    }
}

export default reducer;