import React from 'react'
import { connect } from 'react-redux'
import '../../../screens/pricing/Pricing.css'
import { Link } from 'react-router-dom';
import PricingCards from '../../../screens/pricing/PricingCards'
import { getPricing,setPricing } from '../../../actions/pricingActions'
import {getCredits } from '../../../actions/paymentActions'

import PaymentDialog from '../payment/PaymentDialog';
import SummaryWidgetCredit from '../../summary-widget/SummaryWidgetCredit';


class Credits extends React.Component{


    state ={
        error:true
    }
    componentDidMount(){
        document.title="MentalHappy | Add Kit Credits "

        this.props.getPricing()
        this.props.getCredits()


    }

    haveSelected = ()=>{

        console.log("is clicked")
        //this.setState({error:true})


    }


    render(){

        let creditsAvailable=0;

        if(this.props.credit !== null){

            creditsAvailable = (this.props.credit.total_credits - this.props.credit.credits_used)

        }
        console.log(this.props.pricing.length)

        if(this.props.pricing.length === 0){
            return(
                <div></div>
            )
        }else{

        return(

            <div>
                    <div class="container-fluid">



                        <div class="row">
                            <div class="page-header">
	                            <div class="d-flex align-items-center">
	                                <h2 class="page-header-title">Add MentalHappy Kit Credits</h2>
                                    <div>
                                        <ul class="breadcrumb">
                                            <li class="breadcrumb-item"><Link to="/dashboard"><i class="ti ti-home"></i></Link></li>
                                            <li class="breadcrumb-item active">MentalHappy Credits</li>
                                        </ul>
                                    </div>	                            
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-md-6 col-sm-6">
        <div className="widget widget-12 has-shadow">
          <div className="widget-body">
            <div className="media">
              <div className="align-self-center ml-5 mr-5">

                <span className="dashboard-count total-cheerbox-sent" style={{color:'#5e5c67 !important'}}>
                  {creditsAvailable} </span>
                
               
              </div>

              <div className="media-body align-self-center">
                <div className="title" >Available Kit Credits</div>
                <div className="number">{this.props.action}</div>
               

              </div>
            
            </div>
            <div style={{textAlign: 'center', marginTop: 8}}>

            </div>
          </div>
        </div>
      </div>

            </div>

			<div className="form-box">
				<div className="container">
					<div className="row">
                    <div className="col-lg-12 col-xl-12 col-sm-12" >
      <div className="col-xl-4 col-lg-4  col-md-6 col-sm-12 has-shadow " style={{float:'left',height:150,padding:10}}>
<h3 style={{textAlign:'center'}}>Not A Subscription</h3>   

<p style={{fontSize:16,textAlign:'center'}}>We don't autobill you. Once your MentalHappy Kits credits run out you decide if you want to purchase more.</p>

</div>

<div className="col-xl-4 col-lg-4   col-md-6 col-sm-12 has-shadow" style={{float:'left',height:150,padding:10}}>
<h3  style={{textAlign:'center'}}>Credits Expiration</h3>   

<p style={{fontSize:16,textAlign:'center'}}>Your MentalHappy Kits credits expire after one year if you are not actively using it. If you are then they rollover</p>

</div>

<div className="col-xl-4  col-lg-4  col-md-6 col-sm-12 has-shadow" style={{float:'left',height:150,padding:10}}>
<h3  style={{textAlign:'center',textAlign:'center'}}>We Handle Shipping</h3>   

<p style={{fontSize:16,textAlign:'center'}}>Shipping is included in each kit you send. You don't have to pay additional fees for shipping. </p>

</div>
</div> 
                                <div className='pricing-holder animated fadeIn'>
                        {this.props.pricing ? (<PricingCards prices={this.props.pricing}></PricingCards>):null}
                        
                        {this.state.error ? null:(<div class="alert alert-outline-danger dotted" role="alert">
                                            <strong>Oh Oh!</strong> Please select a package
                                        </div>)}
                        </div>

                    
                    </div>

                    <span>* 1 Credit = 1 Kit | Shipping included.</span>

					</div>
			</div>


            <div className="container">
                    <div className="row button-row">
                        <div className="col-md-4"></div>
                        <div className="col-md-4">
                            <div className="button-group">
                            <PaymentDialog price={this.props.setprice}  isSelect={this.haveSelected}  getcredits={this.props.getCredits}  />

                            </div>
                        </div>
                        <div className="col-md-4"></div>
                    </div>
                </div>


            </div>
        )
    }

    }
}

const mapStatesToProps = state =>{
    return{
        pricing:state.pricing.pricing,
        setprice:state.pricing.setprice,
        credit: state.payment.credits
    }
}

const mapDispatchToProps = dispatch =>{
    return{
        getPricing: ()=>dispatch(getPricing()),
        setPricing:(data)=>dispatch(setPricing(data)),
        getCredits:()=>dispatch(getCredits())
        
    }
}
export default  connect(mapStatesToProps,mapDispatchToProps) (Credits)