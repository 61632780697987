import React from 'react'
import './Order.css'
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Wrapper from '../../components/wrapper/Wrapper';
import CheerBoxLogo from '../../assets/img/cheerbox-logo.svg';
import Address from './Address'
import NoteCard from './Note'
import Recipient from './Recipient'
import Details from './Details'
import {connect} from 'react-redux'
import CheerboxType from '../../components/cheerbox/cheerbox-type/CheerType';
import {createOrder} from '../../actions/cheerActions'
import {getCredits} from '../../actions/creditActions'
import { Link } from 'react-router-dom';
import { Mixpanel } from '../../Mixpanel'

const styles = theme => ({
    root: {
        width: '90%',
    },
    overrides: {
        MuiStepIcon: {
         root: {
           '&$completed': {
             color: 'pink',
           },
           '&$active': {
             color: 'red',
           },
         },
         active: {},
         completed: {},
       }},
    button: {
        marginTop: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        background:'#1aa8bc',
        '&$active': {
            color: '#1aa8bc',
          },
          color:'#fff'
    },   
    actionsContainer: {
        marginBottom: theme.spacing.unit * 2,
    },
    resetContainer: {
        padding: theme.spacing.unit * 3,
        width:'100%'
    },
    stepIcon: {
        color: "#1bb1c3 !important",

              }
});

function getSteps() {
    return ['Type of Kit','Recipient Name','Personal Note','Additional Information', 'Delivery Address'];
}

const country = [
    { 'label': 'United States', name: 'US' },
    { 'label': 'Canada', name: 'CA' },
    { 'label': 'United Kingdom', name: 'UK' },
    { 'label': 'Australia', name: 'Au' },

]


function getStepContent(step) {
    switch (step) {
        case 0:
            Mixpanel.track('Cheerbox Type Field')
            return (

                <CheerboxType />
            )       
        case 1:
        Mixpanel.track('Cheerbox Recipient Field')

            return (

                <Recipient />
            )
        case 2:
        Mixpanel.track('Cheerbox Note Field')
            return (
                <NoteCard />

            )
            
        case 3:
        Mixpanel.track('Cheerbox Details Field')

            return (
                <Details />
                
            );
        case 4:
        Mixpanel.track('Cheerbox Address Field')

            return (

                <Address />
            )
        default:
            return 'Unknown step';
    }
}


class OrderFlow extends React.Component {

    componentDidMount(){

        this.props.getCredits()
        document.title="MentalHappy | New Kit Request "
      }

    state = {
        activeStep: 0,
        confirm:false,
        completed:false
    };

    handleConfirm =()=>{
        if(this.props.credits !==null){

        let data ={
            gender_specific:this.props.gender,
            recipient_first_name: this.props.firstName,
            recipient_last_name:this.props.lastName,
            recipient_email:this.props.email,
            user_notes:this.props.usernote,
            notefrom:this.props.notesigned,
    
            recipient_address_country:this.props.country,
            recipient_address:this.props.street,
            recipient_route:this.props.street2,
            recipient_address_city:this.props.city,
            recipient_address_state:this.props.state,
            recipient_address_zip:this.props.zip,
            notes:this.props.details,
            box_preference:this.props.box_preference
        }
        this.props.create(data)

        this.setState(state => ({
            activeStep: state.activeStep + 1,confirm:false,completed:true
        }));
    }


    }
    handleNext = () => {
        if(this.state.activeStep === 4){
        this.setState(state => ({
            activeStep: state.activeStep + 1,confirm:true,completed:false
        }));

        }else{
            this.setState(state => ({
                activeStep: state.activeStep + 1
            }));
        }

    };

    handleBack = () => {
        this.setState(state => ({
            activeStep: state.activeStep - 1,
        }));
    };

    addCredits = () => {
        Mixpanel.track('Add Credits Order Page')

        this.props.history.push('/dashboard/credits')
    };

    handleReset = () => {
        this.setState({
            activeStep: 0,confirm:false,completed:false
        });
    };


    render() {
        const { classes } = this.props;
        const steps = getSteps();
        const { activeStep } = this.state;

        if(this.state.confirm === false && this.state.completed === false){
            return (
                <div>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="page-header">
                                    <div className="d-flex align-items-center">
                                        <h2 className="page-header-title">Send a Kit</h2>
                                        <div>
                                            <ul className="breadcrumb">
                                            <li className="breadcrumb-item"><Link to="/dashboard"><i className="ti ti-home"></i></Link></li>
                                                <li className="breadcrumb-item active">Send Kit</li>
                                            </ul>
                                        </div>	                            
                                    </div>
                                </div>
                            </div>
                            </div>

                    
                            <div className="container">
                    <div className="row ">
                    {this.props.credits === null ? (
                        <div className="alert alert-warning alert-dissmissible fade show" role="alert" style={{margin:'0 auto'}}>
                        <button type="button" className="close" data-dismiss="alert" aria-label="Close"></button>
                        <strong>No Credits! </strong>You are out of credits. Please add credits to send MentalHappy Kits.
                        <Button onClick={this.addCredits} className={classes.button} style={{display:'block',margin:'0 auto'}}>
                                                                Add Credits
                                                        </Button>

                    </div>

                           ):null}                           


                        <Stepper activeStep={activeStep} orientation="vertical" style={{ width: '100%',background:'inherit' }}>
                            {steps.map((label, index) => (
                                <Step key={label}> 
                                    <StepLabel 
                                                      StepIconProps={{
                                                        classes: { root: classes.stepIcon }
                                                      }}
                                    >{label}</StepLabel>
                                    <StepContent>
                                        <div>{getStepContent(index)}</div>
                                        <div className={classes.actionsContainer}>
                                            <div>
                                                <Button
                                                    disabled={activeStep === 0}
                                                    onClick={this.handleBack}
                                                    style={{color:'#000'}}
                                                >
                                                    Back
                          </Button>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={this.handleNext}
                                                    className={classes.button}
                                                >
                                                    {activeStep === steps.length - 1 ? 'Send' : 'Next'}
                                                </Button>
                                            </div>
                                        </div>
                                    </StepContent>
                                </Step>
                            ))}
                        </Stepper>
    
                        {this.props.created && (

                            
                            <Paper square elevation={0} className={classes.resetContainer}>
                                <Typography variant='headline'>Thank you - you&apos;re MentalHappy Kit is being made.</Typography>
                                <Button onClick={this.handleReset} className={classes.button}>
                                    Send another
                            </Button>
                            </Paper>
                        )}
                    </div>
                </div>
                            
                </div>
            );
        }else if(this.state.confirm === true && this.state.completed=== false){
            Mixpanel.track('Cheerbox Confirmation Page')

            return(
                <div>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="page-header">
                                    <div className="d-flex align-items-center">
                                        <h2 className="page-header-title">Send a Kit</h2>
                                        <div>
                                            <ul className="breadcrumb">
                                            <li className="breadcrumb-item"><Link to="/dashboard"><i className="ti ti-home"></i></Link></li>
                                                <li className="breadcrumb-item active">Send Kit</li>
                                            </ul>
                                        </div>	                            
                                    </div>
                                </div>
                            </div>
                            </div>

                    
<div className="container">
                                        <div className="widget " style={{background:'inherit'}}>
                                            <div className="widget-header bordered no-actions d-flex align-items-center" style={{background:'inherit'}}>
                                                <h3>Please confirm your order.</h3>
                                            </div>
                                            <div className="widget-body">
                                                <div className="about-infos d-flex flex-column">
                                                    <div className="about-title">Type of Kit</div>
                                                    <div className="about-text">
                                                    {this.props.box_preference}
                                                    </div>
                                                </div>

                                                <div className="about-infos d-flex flex-column">
                                                    <div className="about-title">Recipient Name</div>
                                                    <div className="about-text">{this.props.gender}</div>
                                                    <div className="about-text">{this.props.firstName} {this.props.lastName}</div>
                                                    <div className="about-text">{this.props.email}</div>
                                                   
                                                </div>
                                                <div className="about-infos d-flex flex-column">
                                                    <div className="about-title">Personal Note</div>
                                                    <div className="about-text">{this.props.usernote}</div>
                                                    <div className="about-text">{this.props.notesigned}</div>

                                                </div>
                                                <div className="about-infos d-flex flex-column">
                                                    <div className="about-title">Additional Details</div>
                                                    <div className="about-text">{this.props.details}</div>

                                                </div>
                                                <div className="about-infos d-flex flex-column">
                                                    <div className="about-title">Delivery Address</div>
                                                    <div className="about-text">{this.props.country}</div>
                                                    <div className="about-text">{this.props.street}</div>
                                                    <div className="about-text">{this.props.street2}</div>
                                                    <div className="about-text">{this.props.city} {this.props.state} {this.props.zip}</div>

                                                </div>
                                                <div className="button-group">
                                                </div>

{this.props.credits === null ? (
<div className="alert alert-warning alert-dissmissible fade show" role="alert" style={{margin:'0 auto'}}>
<button type="button" className="close" data-dismiss="alert" aria-label="Close"></button>
<strong>No Credits! </strong>You are out of credits. Please add credits to send MentalHappy Kits.
<Button onClick={this.addCredits} className={classes.button} style={{display:'block',margin:'0 auto'}}>
                                        Add Credits
                                </Button>

</div>

   ):(<div>
    <Button onClick={this.handleReset} style={{marginRight:20}}>
                            Edit Order 
                    </Button> 
    <button type="submit" className="button buttonGreen" onClick={this.handleConfirm}>Confirm Order
      <div className="ripples buttonRipples"><span className="ripplesCircle"></span></div>
    </button>  </div>)}  
          
            </div>

            
                                            </div>
                                        </div>
                </div>

                
            )
        }else if(this.state.completed === true){
            Mixpanel.track('Cheerbox Completion PageView')

            return(
                <div>
                                            <div className="container-fluid">
                            <div className="row">
                                <div className="page-header">
                                    <div className="d-flex align-items-center">
                                        <h2 className="page-header-title">Send a Kit</h2>
                                        <div>
                                            <ul className="breadcrumb">
                                            <li className="breadcrumb-item"><Link to="/dashboard"><i className="ti ti-home"></i></Link></li>
                                                <li className="breadcrumb-item active">Send Kit</li>
                                            </ul>
                                        </div>	                            
                                    </div>
                                </div>
                            </div>
                            </div>

        <div className="container-fluid h-100 overflow-y">
            <div className="row flex-row h-100">
                <div className="col-12 my-auto">
                    <div className="mail-confirm mx-auto" style={{boxShadow:'none',background:'inherit'}}>
                        <div className="animated-icon" style={{background:'#0e8696'}}>
                            <div className="icon"><i className="la la-check"></i></div>
                        </div>
                        <h3>Kit Order completed</h3>
                        <p>
                            A confirmation email has been sent to <a href="#">{this.props.user && this.props.user.email}</a> 
                        </p>
                        <p>
                            We will email you tracking information once your order has been shipped.
                        </p>
                        <div className="button text-center" style={{display:'block',margin:'0 auto'}}>
                        <button type="submit" className="button buttonGreen" onClick={this.handleReset}>Send Another
    <div className="ripples buttonRipples"><span className="ripplesCircle"></span></div>
  </button>
                        </div>

                        {this.props.credits === null ? (
                        <div className="alert alert-warning alert-dissmissible fade show" role="alert" style={{margin:'0 auto'}}>
                        <button type="button" className="close" data-dismiss="alert" aria-label="Close"></button>
                        <strong>No Credits! </strong>You are out of credits. Please add credits to send Mentalhappy Kits.
                        <Button onClick={this.addCredits} className={classes.button} style={{display:'block',margin:'0 auto'}}>
                                                                Add Credits
                                                        </Button>

                    </div>

                           ):null}    
                    </div>        
                </div>
            </div>
        </div> 
                </div>
            )
        }

    }


}

OrderFlow.propTypes = {
    classes: PropTypes.object,
};

const mapStateToProps = state=>{
    return{
        orders:state.cheer.orders,
        ordering:state.cheer.create_status,

        gender:state.cheer.gender_specific,
        firstName: state.cheer.recipient_first_name,
        lastName: state.cheer.recipient_last_name,
        email: state.cheer.recipient_email,
        usernote: state.cheer.user_notes,
        notesigned: state.cheer.notefrom,

        country: state.cheer.recipient_address_country,
        street: state.cheer.recipient_address,
        street2: state.cheer.recipient_route,
        city: state.cheer.recipient_address_city,
        state: state.cheer.recipient_address_state,
        zip: state.cheer.recipient_address_zip,
        details: state.cheer.notes,
        box_preference: state.cheer.box_preference,

        nocredits:state.cheer.credit_status,
        created:state.cheer.create_status,

        user:state.user.user.user,
        credits:state.credit.credits



    }
}

const mapDispatchToProps = dispatch=> {

    return{
        create:(data)=>dispatch(createOrder(data)),
        getCredits:()=>dispatch(getCredits())

    }

}

export default connect(mapStateToProps,mapDispatchToProps) (withStyles(styles)(OrderFlow));