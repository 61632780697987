import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';

import './Dashboard.css';
import Header from '../../components/header/Header';
import Sidebar from '../../components/sidebar/Sidebar';
import Invite from '../../components/dashboard/invite/Invite';
import Profile from '../../components/dashboard/account/profile';
import Settings from '../../components/dashboard/account/settings';
import Logout from '../../components/dashboard/logout/Logout';
import Dash from '../../components/dashboard/home/dash/Dash';
import RightBar from '../../components/right-bar/RightBar';
import { connect } from 'react-redux';
import OrderFlow from '../order/OrderFlow';
import FAQ from '../../components/dashboard/account/faq';
import Avatar from '../../components/dashboard/account/avatar';
import credits from '../../components/dashboard/credits/credits';
import Bulk from '../../components/dashboard/bulk/bulk';


class Dashboard extends Component {

    componentDidMount() {
        document.title = 'MentalHappy - Dashboard';
    }


    render() {
        return (
            <div >
                <div className="page">
                    <Header />
                    <div className="page-content d-flex align-items-stretch">
                        <Sidebar />

                        <div className="content-inner">
                            <Switch>
                                <Route path='/dashboard/invite' component={Invite} />
                                <Route path='/dashboard/logout' component={Logout} />
                                <Route path='/dashboard/profile' component={Profile} />
                                <Route path='/dashboard/settings' component={Settings} />
                                <Route path='/dashboard/order' component={OrderFlow} />
                                <Route path='/dashboard/credits' component={credits} />
                                <Route path='/dashboard/bulk' component={Bulk} />
                             
                                {/* <Route path='/dashboard' component={Dash} /> */}
                                <Route path='/' component={Dash} />
                                <Route path='*' render={() => (<div>Not found</div>)} />
                            </Switch>
                            <RightBar />
                        </div>
                    </div>
                </div>
                <div id="delay-modal" className="modal fade">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-body text-center">
                                <div className="sa-icon sa-success animate" style={{ display: "block" }}>
                                    <span className="sa-line sa-tip animateSuccessTip"></span>
                                    <span className="sa-line sa-long animateSuccessLong"></span>
                                    <div className="sa-placeholder"></div>
                                    <div className="sa-fix"></div>
                                </div>
                                <div className="section-title mt-5 mb-5">
                                    <h2 className="text-dark">Meeting successfully created</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="modal-view-event" className="modal modal-top fade calendar-modal">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title event-title"></h4>
                                <button type="button" className="close" data-dismiss="modal">
                                    <span aria-hidden="true">×</span>
                                    <span className="sr-only">close</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="media">
                                    <div className="media-left align-self-center mr-3">
                                        <div className="event-icon"></div>
                                    </div>
                                    <div className="media-body align-self-center mt-3 mb-3">
                                        <div className="event-body"></div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        // cheers: state.cheers
    };
};

const mapDispatchToProps = dispatch => {
    return {
        // onGetCheers: (userId) => dispatch({type: actionTypes.GET_CHEERS, data: {user: userId}}),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
