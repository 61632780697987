
import axios from 'axios';
import { GET_PAYMENT,REQUEST_ERROR} from './types'
export const FILTER_DATE_ASYNC = "FILTER_DATE_ASYNC";
export const FILTER_DATE = "FILTER_DATE";
export const SET_DATE = "SET_DATE";
export const SET_COUNT = "SET_COUNT";


export const DASHBOARD_REQUEST = "DASHBOARD_REQUEST";
export const GET_DASHBOARD = "GET_DASHBOARD";
export const DASHBOARD_ERROR = "DASHBOARD_ERROR";


//const basedev = 'https://api.cheerbox.com/api/'
//const basedev = 'http://cheerbox.dev.com/api/'
const basedev = "https://api.mentalhappy.com/api/"


const onDateChanged = (date, type) => {
    console.log("Date changed now", date, type);
    return {
        type: SET_DATE,
        payload: {
            date,
            type
        }
    }
}

export const filterDate = (date, type) => {
    console.log("KLJLKLJL", date, type);
    return dispatch => {
        setTimeout(()=>{
            dispatch(onDateChanged(date, type));
        }, 3000);
    }
}


export const getDashboard=()=>dispatch=>{

    
    var endpoint = basedev +'dashboard'
    var headers = {
        'Accept': 'application/json',
        'Authorization':"Bearer " + localStorage.getItem('access_token')

    } 
        dispatch({
            type:DASHBOARD_REQUEST
        })
      axios.get(endpoint , {headers: headers})
        .then(res => {

            dispatch({
                type:GET_DASHBOARD,
                payload: res.data.results
            })
        }).catch(error=>{
            dispatch({
                type:DASHBOARD_ERROR,
                payload:null
            })
        })


}

export const getPayment=()=>dispatch=>{

    
    var endpoint = basedev +'payment'
    var headers = {
        'Accept': 'application/json',
    } 
        dispatch({
            type:GET_PAYMENT
        })
      axios.get(endpoint , {headers: headers})
        .then(res => {

            dispatch({
                type:GET_PAYMENT,
                payload: res.data
            })
        }).catch(error=>{
            dispatch({
                type:REQUEST_ERROR,
                payload:null
            })
        })
}
