import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

// import "./Cheerbox.css";
import Wrapper from '../../wrapper/Wrapper';
import * as actionTypes from '../../../actions/cheerActions';

class CheerReason extends Component {
    componentDidMount() {
        document.title = 'Cheerbox - Select Cheerbox Reason';
    }
    render() {
        return (
            <Wrapper>
                <div className="header">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-3"></div>
                            <div className="col-md-6 ">
                                <div className="page-title">
                                    <span>I'm Sending this Cheerbox Because</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="form-box">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-2"></div>
                            <div className="col-md-8">
                                <form>
                                    <div className="form-row">
                                        <div className="col-2"></div>
                                        <div className="col-8">
                                            <textarea value={this.props.reason} className="form-control" id="exampleFormControlTextarea1" rows="10" placeholder="Give us more details...." onChange={e => {
                                                this.props.onSetReason(e.target.value);
                                            }}></textarea>
                                        </div>
                                        <div className="col-2"></div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="col-md-2"></div>
                    </div>
                </div>
                <div className="container">
                    <div className="row button-row">
                        <div className="col-md-4"></div>
                        <div className="col-md-4">
                            <div className="button-group">
                                <Link to="/cheerbox/set-cheer-note" className="button buttonGreen">Continue
    <div className="ripples buttonRipples"><span className="ripplesCircle"></span></div>
                                </Link>
                            </div>
                        </div>
                        <div className="col-md-4"></div>
                    </div>
                </div>
            </Wrapper>
        );
    }
}
const mapStateToProps = state => {
    return {
      reason: state.cheer.reason,
    };
  };
  const mapDispatchToProps = dispatch => {
    return {
      onSetReason: (reason) => dispatch({type: actionTypes.SET_REASON, payload: {reason}}),
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(CheerReason);
