export const Get_PRICING = 'Get_PRICING'
export const PRICING_REQUEST = 'PRICING_REQUEST'
export const REQUEST_ERROR = 'REQUEST_ERROR'


//PRICING
export const SET_PRICING = 'SET_PRICING'


//USERS
export const CREATE_USER = 'CREATE_USER'
export const GET_USER = 'GET_USER'
export const USER_REQUEST = 'USER_REQUEST'
export const USER_FETCH = 'USER_FETCH'
export const USER_ERROR = 'USER_ERROR'


//PAYMENT
export const CREATE_PAYMENT = 'CREATE_PAYMENT'
export const GET_PAYMENT = 'GET_PAYMENT'
export const SET_TOKEN = 'SET_TOKEN'
export const PAYMENT_REQUEST = 'PAYMENT_REQUEST'


//Auth
export const LOGIN_USER = 'LOGIN_USER'
export const LOGIN_REQUEST = 'LOGIN_REQUEST'
export const LOGIN_FAILED = 'LOGIN_FAILED'


//dashboard
export const PENDING_ORDER = 'PENDING_ORDER'
export const TOTAL_ORDERS = 'TOTAL_ORDERS'
export const TOTAL_CREDITS = 'TOTAL_CREDITS'


//credits
export const GET_CREDITS = 'GET_CREDITS'
export const ADD_CREDITS = 'ADD_CREDITS'

//Avatar
export const GET_AVATAR = 'GET_AVATAR'
export const CREATE_AVATAR = 'CREATE_AVATAR'
export const GET_AVATAR_REQUEST = 'GET_AVATAR_REQUEST'
export const SET_AVATAR = 'SET_AVATAR'


//Invite
export const INVITE_REQUEST='INVITE_REQUEST'
export const INVITE_USER='INVITE_USER'
export const INVITE_ERROR='INVITE_ERROR'


export const BULK_UPLOAD = 'BULK_UPLOAD'
export const BULK_REQUEST = 'BULK_REQUEST'
export const BULK_ERROR = 'BULK_ERROR'


export const PASSWORD_RESET = 'PASSWORD_RESET'
export const PASSWORD_RESET_ERROR = 'PASSWORD_RESET_ERROR'
export const PASSWORD_POST = 'PASSWORD_POST'
export const PASSWORD_POST_ERROR = 'PASSWORD_POST_ERROR'
export const GET_CLIENT = "GET_CLIENT"
export const CLIENT_REQUEST = "CLIENT_REQUEST"
export const CLIENT_ERROR = "CLIENT_ERROR"

export const PASSWORD_RESET_REQUEST = "PASSWORD_RESET_REQUEST"


export const CREDIT_ERROR = "CREDIT_ERROR"
export const GET_CREDIT = "GET_CREDIT"
export const CREDIT_REQUEST = "CREDIT_REQUEST"



export const UPDATE_PASSWORD_REQUEST = "UPDATE_PASSWORD_REQUEST"
export const UPDATE_PASSWORD = "UPDATE_PASSWORD"
export const UPDATE_PASSWORD_ERROR = "UPDATE_PASSWORD_ERROR"
export const INVITE_REFERRER = "INVITE_REFERRER"

export const ADD_ANSWER = "ADD_ANSWER"

