import React, { Component } from 'react';
import CheerboxSentChartWidget from '../CheerboxSentWidget/CheerboxSentChartWidget';
import DateSelector from '../date-picker/DateSelector';
import { connect } from 'react-redux';
import * as actionTypes from '../../actions/dashboardActions';

class CheerboxSentWidget extends Component {
  render() {

    
    return (

      <div className="row">
    
     <CheerboxSentChartWidget />

<div className="col-md-6" >
<div className="widget has-shadow">
                                    <div className="widget-header bordered no-actions d-flex align-items-center">
                                        <h4>Kit Recipients</h4>
                                        <div className="date-selector-div">
                                      
          
                                        </div>
                                    </div>
                                    <div className="widget-body">
                                    <div className="table-responsive">
    <div id="sorting-table_wrapper" className="dataTables_wrapper container-fluid dt-bootstrap4 no-footer">
        {/* <div className="row">
            <div className="col-sm-12 col-md-6">
                <div className="dataTables_length" id="sorting-table_length">
                    <label>Show
                        <select name="sorting-table_length" aria-controls="sorting-table" className="form-control form-control-sm">
                            <option value="10">10</option>
                            <option value="15">15</option>
                            <option value="20">20</option>
                            <option value="-1">All</option>
                        </select> entries</label>
                </div>
            </div>
            <div className="col-sm-12 col-md-6">
                <div id="sorting-table_filter" className="dataTables_filter">
                    <label>Search:
                        <input type="search" className="form-control form-control-sm" placeholder="" aria-controls="sorting-table" />
                    </label>
                </div>
            </div>
        </div> */}
        <div className="row ">
            <div className="col-sm-12 table-overflow-box">
                <table id="sorting-table" className="table mb-0 dataTable no-footer" role="grid" aria-describedby="sorting-table_info">
                    <thead>
                        <tr role="row">
                            <th className="sorting"  tabIndex="0" aria-controls="sorting-table" rowSpan="1" colSpan="1" aria-label="Order ID: activate to sort column ascending" style={{width: 76}}>Recipient</th>
                            <th className="sorting" tabIndex="0" aria-controls="sorting-table" rowSpan="1" colSpan="1" aria-label="Customer Name: activate to sort column ascending" style={{width: 143}}> Type</th>
                            <th className="sorting_desc" tabIndex="0" aria-controls="sorting-table" rowSpan="1" colSpan="1" aria-label="Country: activate to sort column ascending" aria-sort="descending" style={{width: 72}}>Sender</th>
                            <th className="sorting" tabIndex="0" aria-controls="sorting-table" rowSpan="1" colSpan="1" aria-label="Ship Date: activate to sort column ascending" style={{width: 86}}>Date</th>
                          
                          
                        </tr>
                    </thead>
                    <tbody>



        {this.props.recipients &&  this.props.recipients.map((recipient,index) => {
           return (<tr role="row" className="odd" key={index}>
              
                <td>{recipient.address.recipient_first_name} {recipient.address.recipient_last_name}</td>
                <td className="sorting_1">{recipient.preference}</td>
                <td className="sorting_1">{recipient.user.firstname} {recipient.user.lastname}</td>
                <td className="">{ recipient.created_at}</td>
               
              
            </tr>)
        })}
                      
                     
                    


                    </tbody>
                </table>
            </div>
        </div>
        {/* <div className="row">
            <div className="col-sm-12 col-md-5">
                <div className="dataTables_info" id="sorting-table_info" role="status" aria-live="polite">Showing 1 to 10 of 20 entries</div>
            </div>
            <div className="col-sm-12 col-md-7">
                <div className="dataTables_paginate paging_simple_numbers" id="sorting-table_paginate">
                    <ul className="pagination">
                        <li className="paginate_button page-item previous disabled" id="sorting-table_previous"><a href="#" aria-controls="sorting-table" data-dt-idx="0" tabindex="0" className="page-link">Previous</a></li>
                        <li className="paginate_button page-item active"><a href="#" aria-controls="sorting-table" data-dt-idx="1" tabindex="0" className="page-link">1</a></li>
                        <li className="paginate_button page-item "><a href="#" aria-controls="sorting-table" data-dt-idx="2" tabindex="0" className="page-link">2</a></li>
                        <li className="paginate_button page-item next" id="sorting-table_next"><a href="#" aria-controls="sorting-table" data-dt-idx="3" tabindex="0" className="page-link">Next</a></li>
                    </ul>
                </div>
            </div>
        </div> */}
    </div>
</div>
</div>
                                </div>
      
</div>
      
     
      </div>
    );
  }
}


const mapStateToProps = state => {
    return {
        recipientStartDate: state.dashboard.recipientStartDate,
        recipients : state.dashboard.orders,
        
    };
  };
  const mapDispatchToProps = dispatch => {
    return {
      onDateChanged: (date, type) => dispatch(actionTypes.filterDate(date, type)),
    }
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(CheerboxSentWidget);


