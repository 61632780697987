import React, { Component } from 'react';
import { Switch, Route ,Link} from 'react-router-dom';

import { connect } from 'react-redux'

import Wrapper from '../../components/wrapper/Wrapper';
import CheerBoxLogo from '../../assets/img/cheerbox-logo.svg';
import { getUser,createUser } from '../../actions/userActions'
import {Elements} from 'react-stripe-elements';
import InjectedCheckoutForm from './CheckoutForm';
import Typography from '@material-ui/core/Typography';
import './Payment.css'
import { getPricing,setPricing  } from '../../actions/pricingActions'
import { StripeProvider } from 'react-stripe-elements';

class Pricing extends Component {

    componentDidMount(){
        document.title="MentalHappy | Payment Page "

        //this.props.getUser()

        if (window.Stripe) {
            this.setState({stripe: window.Stripe('pk_live_VqXDUfG9MoJu4lmzw3gNlXyp')});
          } else {
            document.querySelector('#stripe-js').addEventListener('load', () => {
              // Create Stripe instance once Stripe.js loads
              this.setState({stripe: window.Stripe('pk_live_VqXDUfG9MoJu4lmzw3gNlXyp')});
            });
          }
  
          

        this.props.getPricing()

    }

    componentDidUpdate(prevProps){


        if(this.props.pricing.length > prevProps.pricing.length){
            this.props.setPricing(this.props.pricing[0])


        }
    }


  render() {


    if(this.props.pricing.length === 0){

        this.props.setPricing(this.props.pricing[0])

    }else{


        const priceOptions = this.props.pricing.map((amount,index)=>{

            return(
                <option value={index}>{amount.package_amount}</option>
    
            )
        })
    }

    


    return (
      <Wrapper>
        <div className="logo-section">
			<div className="container">
				<div className="row">
					<div className="col-md-4 head-group">
						<div className="mental-happy-logo">
              <img src="../../../assets/img/mental-happy-logo.png" alt="Mental happy logo" width='120'/>

						</div>
					</div>
				</div>
			</div>
		</div>
			<div className="header">
				<div className="container">
					<div className="row">
						<div className="col-md-4"></div>
						<div className="col-md-4 ">
                    <div className="page-title">
                            <span>Let's finish setting up your account.</span>

                    </div>
						</div>
					</div>
				</div>
			</div>
			<div className="form-box">
				<div className="container">
					<div className="row">
                    <div className="col-md-12  animated fadeIn">

                    <div className="col-md-4 pull-left">
                    <div className="page-title">
                            <span>Payment Information</span>
                            

                    </div>                    
                    <Typography variant="caption">  
                    </Typography><br/>
                    <StripeProvider apiKey="pk_live_VqXDUfG9MoJu4lmzw3gNlXyp">

                        <Elements>
                            <InjectedCheckoutForm />
                        </Elements>
                    </StripeProvider>

                        
                    </div>

                    
                    <div className="col-md-7 pull-right">
                    <table className="table">
                    <thead>
                        <tr>
                        <th scope="col">Order</th>
                        <th scope="col">Total Kits</th>
                        <th scope="col">Total Kit Cost</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                        <td>MentalHappy Kits</td>
                        <td>
                        <span style={{padding:10}}>Quantity: {this.props.price && this.props.price.package_amount}</span>


 </td>
                        
                        <td> 

                        {this.props.price && '$'+this.props.price.price}</td>
                        </tr>

                    </tbody>
                    </table>

                        <div style={{float:'right',marginTop:'20%',fontSize:30}}>
                           Total: {this.props.price && '$'+this.props.price.price*this.props.price.package_amount}
                        </div>
                    </div>

                    </div>

                    
                    </div>
					</div>
			</div>


      	</Wrapper>
    );
  }
}

const mapStatesToProps = state =>{
    return{
        user:state.user.user,
        token:state.user.userToken,
        price:state.pricing.setprice,
        pricing:state.pricing.pricing,

    }
}

const mapDispatchToProps = dispatch =>{
    return{
        getUser: ()=>dispatch(getUser()),
        createUser:(data)=>dispatch(createUser(data)),
        setPricing:(data)=>dispatch(setPricing(data)),
        getPricing: ()=>dispatch(getPricing()),
        
    }
}
export default connect(mapStatesToProps,mapDispatchToProps) (Pricing);
