import React, { Component } from 'react';
import {connect} from 'react-redux'
import { Route, Switch,Link } from 'react-router-dom';
import Avatar from './avatar';
import FAQ from './faq';
import Profile from './profile';
import { Mixpanel } from '../../../Mixpanel'

class Settings extends Component {


    componentDidMount(){
        Mixpanel.track('Setting Pageview')
    }


    handleLogout = () =>{

        //remove token
        localStorage.removeItem('access_token');
        this.props.history.push('/')

        //this.props.history.push('/signin');
    }


  render() {
      console.log(this.props.user)
    return (
      <div>
                    <div class="container-fluid">
                        <div className="row flex-row">
                            <div className="col-xl-3">
                                <div className="widget has-shadow">
                                    <div className="widget-body">
                                        <div className="mt-5">
                                        {this.props.avatar ? (<img src={this.props.avatar} alt="..." style={{width: 150,height:150}} className="avatar rounded-circle d-block mx-auto" />):(<img src="/assets/img/avatar.png" alt="..." style={{width: 120}} className="avatar rounded-circle d-block mx-auto" />)}
                                        </div>
                                        <h3 className="text-center mt-3 mb-1">{this.props.user && this.props.user.firstname} {this.props.user && this.props.user.lastname}</h3>
                                        <p className="text-center">{this.props.user && this.props.user.email}</p>
                                        <div className="em-separator separator-dashed"></div>
                                        <ul className="nav flex-column">
                                            <li className="nav-item">
                                            <Link to='/dashboard/settings'><i className="la la-gears la-2x align-middle pr-2"></i> Settings</Link>
                                            </li>
                                            <li className="nav-item">
                                            <Link to='/dashboard/settings/avatar'><i className="la la-user la-2x align-middle pr-2"></i> Avatar</Link>

                                            </li>                                           
                                            <li className="nav-item">
                                            <Link to='/dashboard/settings/faq'><i className="la la-question-circle la-2x align-middle pr-2"></i> FAQ</Link>

                                            </li>
                                            <li className="nav-item">
                                            <Link to='/referrer'><i className="la la-money la-2x align-middle pr-2"></i> Referral Program</Link>

                                            </li>                                            
                                            <li className="nav-item">
                                            <Link to='' onClick={this.handleLogout}><i className="la la-sign-out la-2x align-middle pr-2"></i> Logout</Link>

                                            </li>
                                                                                      
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-9">
                                <div className="widget has-shadow">
                                <Switch>
                                <Route path='/dashboard/settings/faq' component={FAQ} />
                                <Route path='/dashboard/settings/avatar' component={Avatar} />
                                <Route path='/' component={Profile} />
              
                            </Switch>                                

                                </div>
                            </div>
                        </div>
                        
                              </div>
                              </div>
    );
  }
}

const mapStateToProps = state =>{
  return{
    user:state.user.user.user,
    avatar:state.user.avatar
  }
}



export default connect(mapStateToProps,null) (Settings);
