import React, { Component } from 'react';

import Wrapper from '../../../wrapper/Wrapper';

class Cheers extends Component {
  render() {
    console.log("Rendering cheers");
    return (
      <Wrapper>
        Cheers page
      </Wrapper>
    );
  }
}

export default Cheers;
