import React from 'react';
import { connect } from 'react-redux';
import * as actionTypes from '../../actions/cheerActions';
import TextField from '@material-ui/core/TextField';
import { withStyles} from '@material-ui/core/styles';



const styles = theme => ({

    cssLabel: {
      '&$cssFocused': {
        color:"#1aa8bc",
      },
      '&:after': {
        borderBottomColor:"#1aa8bc",
      }  
    },
    cssFocused: {},
    cssUnderline: {
      '&:after': {
        borderBottomColor: "#1aa8bc",
      },
    },

  
  });
  

class Address extends React.Component{



    render(){
        const { classes } = this.props;

        return(
            <div className="form-box">
            <div className="container">
                <div className="row">
                    <div className="col-md-8">
    
                        <form>
                            <div className="form-row">
                                <div className="col">
    
                                    <select required defaultValue={this.props.country} className="custom-select custom-select-lg mb-3 required"   onChange={(e) => {this.props.onSetAddress(e.target.value,this.props.street,this.props.street2,this.props.city,this.props.state,this.props.zip);}} >
                                        <option >Please a country</option>
                                        <option value="US">United States</option>
                                        <option value="CA">Canada</option>
                                        <option value="UK">United Kingdom</option>
                                        <option value="AU">Australia</option>
    
                                    </select>
                                </div>
                            </div>
    
                            <div className="form-row">
                                <div className="col ">
                   
                                <TextField
                                    label="Street Address"
                                    defaultValue=""
                                    margin="normal"
                                    className="auth-input "
                                    defaultValue={this.props.street}  
                                    onChange={(e) => {this.props.onSetAddress( this.props.country,e.target.value,this.props.street2,this.props.city,this.props.state,this.props.zip);}} 
                                    InputLabelProps={{
                                        classes:{
                                        root: classes.cssLabel,
                                        focused: classes.cssFocused,
                                        }
                                    }}
                                    
                                    />

                                  </div>
                            </div>
    
                            <div className="form-row">
    
                                <div className="col">
                   
                                <TextField
                                    label="Street 2"
                                    defaultValue=""
                                    margin="normal"
                                    className="auth-input "
                                    defaultValue={this.props.street2}  
                                    onChange={(e) => {this.props.onSetAddress( this.props.country,this.props.street,e.target.value,this.props.city,this.props.state,this.props.zip);}}                                      
                                    InputLabelProps={{
                                        classes:{
                                        root: classes.cssLabel,
                                        focused: classes.cssFocused,
                                        }
                                    }}
                                    
                                    />
                                                             

                                  </div>
    
                            </div>
    
                            <div className="form-row">
    
                                <div className="col">
                   
                                <TextField
                                    label="City"
                                    defaultValue=""
                                    margin="normal"
                                    className="auth-input "
                                    defaultValue={this.props.city}  
                                    onChange={(e) => {this.props.onSetAddress( this.props.country,this.props.street,this.props.street2,e.target.value,this.props.state,this.props.zip);}}
                                    InputLabelProps={{
                                        classes:{
                                        root: classes.cssLabel,
                                        focused: classes.cssFocused,
                                        }
                                    }}
                                    
                                    />
                                    </div>
    
                                <div className="col">
                   
                                <TextField
                                    label="State/Province"
                                    defaultValue=""
                                    margin="normal"
                                    className="auth-input "
                                    defaultValue={this.props.state} 
                                    onChange={(e) => {this.props.onSetAddress(  this.props.country,this.props.street,this.props.street2,this.props.city,e.target.value,this.props.zip);}} 
                                    InputLabelProps={{
                                        classes:{
                                        root: classes.cssLabel,
                                        focused: classes.cssFocused,
                                        }
                                    }}
                                    
                                    />
                          
         </div>
    
    
                                <div className="col">
                   
                                <TextField
                                    label="Zip Code"
                                    defaultValue=""
                                    margin="normal"
                                    className="auth-input "
                                    defaultValue={this.props.zip} 
                                    onChange={(e) => {this.props.onSetAddress( this.props.country,this.props.street,this.props.street2,this.props.city,this.props.state,e.target.value);}}
                                    InputLabelProps={{
                                        classes:{
                                        root: classes.cssLabel,
                                        focused: classes.cssFocused,
                                        }
                                    }}
                                    
                                    />
                             </div>
                            </div>
    
                        </form>
                        <div className="col-md-4"></div>
                    </div>
                </div>
            </div>
            </div>

        )
    }
}


const mapStateToProps = state => {
    return {
        country: state.cheer.recipient_address_country,
        street: state.cheer.recipient_address,
        street2: state.cheer.recipient_route,
        city: state.cheer.recipient_address_city,
        state: state.cheer.recipient_address_state,
        zip: state.cheer.recipient_address_zip,

    };
  };

  const mapDispatchToProps = dispatch => {
    return {
      onSetAddress: (country,street,street2,city,state,zip) => dispatch({type: actionTypes.SET_ADDRESS, payload: {country,street,street2,city,state,zip}}),
    }
  };


export default connect(mapStateToProps,mapDispatchToProps)(withStyles(styles)(Address));

