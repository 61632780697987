import React, { Component } from 'react';
import './FeedbackWidget.css';
import DateSelector from '../date-picker/DateSelector';
import { connect } from 'react-redux';
import * as actionTypes from '../../actions/dashboardActions';

class FeedbackWidget extends Component {
  render() {
    return (

      <div className="row">
    
    

<div className="col-md-12" >
<div className="widget has-shadow">
                                    <div className="widget-header bordered no-actions d-flex align-items-center">
                                        <h4>Feedback from MentalHappy Kit Recipients </h4>
                                        <div className="date-selector-div">
                                      
                                      
                <div className="col-lg-12">
                                                    <div className="form-group">
                                                        <div className="input-group">
                                                            <span className="input-group-addon">
                                                                <i className="la la-calendar"></i>
                                                            </span>
                                                            <input type="text" className="form-control date" id="date" placeholder="Select value"/>
                                                        </div>
                                                    </div>
                                                </div>


                                        </div>
                                    </div>
                                    <div className="widget-body">
                                    <div className="table-responsive">
    <div id="sorting-table_wrapper" className="dataTables_wrapper container-fluid dt-bootstrap4 no-footer">
     
        <div className="row">
            <div className="col-sm-12">
                <table id="sorting-table" className="table mb-0 dataTable no-footer" role="grid" aria-describedby="sorting-table_info">
                    <thead>
                        <tr role="row">
                            {/* <th className="sorting" tabindex="0" aria-controls="sorting-table" rowspan="1" colspan="1" aria-label="Order ID: activate to sort column ascending" style={{width: 76}}>Location</th>
                            <th className="sorting" tabindex="0" aria-controls="sorting-table" rowspan="1" colspan="1" aria-label="Customer Name: activate to sort column ascending" style={{width: 143}}> Cheerboxes</th> */}
                          
                           
                          
                          
                        </tr>
                    </thead>
                    <tbody>

                        {  this.props.feedback.map((feedback,index) => {
           return (<tr role="row" className="odd" key={index}>
                   <td><i className="la la-user feedback-user-icon"></i></td>
                <td>{feedback.name}</td>
                <td className="sorting_1">{feedback.feedback}</td>
              
                <td className="">{ feedback.date.toDateString()}</td>
               
              
            </tr>)
        })}


                    </tbody>
                </table>
            </div>
        </div>
      
    </div>
</div>
</div>
                                </div>
      
</div>
      
     
      </div>
    );
  }
}


const mapStateToProps = state => {
  return {
      FeedbackStartDate: state.dashboard.FeedbackStartDate,
      feedback: state.dashboard.cheerboxFeedback
      
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onDateChanged: (date, type) => dispatch(actionTypes.filterDate(date, type)),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(FeedbackWidget);

