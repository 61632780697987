


import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Sidebar extends Component {
  render() {
    return (
      <div className="default-sidebar">
        <nav className="side-navbar box-scroll sidebar-scroll">
          <ul className="list-unstyled">
          <li><Link to="/dashboard"><i className="la la-home"></i><span>Dashboard</span></Link></li>

          {/*  <li className="active"><a href="#dropdown-db" aria-expanded="true" data-toggle="collapse"><i className="la la-home"></i><span>Dashboard</span></a>
              <ul id="dropdown-db" className="collapse list-unstyled show pt-0">
                <li><Link className="active" to="/dashboard">Default</Link></li>
                <li><Link to="/dashboard/cheers">Cheers</Link></li>
                <li><Link to="/dashboard/sales">Sales</Link></li>
  
              </ul>
            </li>
              */}
          </ul>
          <ul className="list-unstyled ">
          <li className="nav-link"><Link to="/dashboard/order"><i className="la la-send"></i><span>Send Kit</span></Link></li>
          <li className="nav-link"><Link to="/dashboard/bulk"><i className="la la-cubes"></i><span>Bulk Order</span></Link></li>
          <li className="nav-link"><Link to="/dashboard/credits"><i className="la la-dollar"></i><span>Add Credits</span></Link></li>
          <li className="nav-link"><Link to='/dashboard/invite'><i className="la la-plus"></i><span>Invite Members</span></Link> </li>
          <li className="nav-link"><Link to='/dashboard/referrer'><i className="la la-users"></i><span>Referral Program</span></Link> </li>

            <li className="nav-link"><a href="#dropdown-ui" aria-expanded="false" data-toggle="collapse"><i className="la la-shield"></i><span>Account</span></a>
              <ul id="dropdown-ui" className="collapse list-unstyled pt-0">
                <li><Link to='/dashboard/settings'>Settings</Link></li>
              </ul>
            </li>

           {/* <li><Link to="/dashboard/logout"><i className="la la-power-off"></i><span>Log out</span></Link></li>*/}
          </ul>
        </nav>
      </div>
    );
  }
}

export default Sidebar;
