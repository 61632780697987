import React, { Component } from 'react';
import { connect } from 'react-redux';

// import "./Cheerbox.css";
import Wrapper from '../../wrapper/Wrapper';
import * as actionTypes from '../../../actions/cheerActions';

class CheerRelationship extends Component {
    componentDidMount() {
        document.title = 'Cheerbox - Select Cheerbox Relationship';
    }
    render() {
        return (
            <Wrapper>
                
<div className="header">
	<div className="container">
		<div className="row">
			<div className="col-md-3"></div>
			<div className="col-md-6 ">
<div className="page-title">
<span>{`${this.props.firstName} ${this.props.lastName} is my `}</span>
</div>
			</div>
		</div>
	</div>
</div>
<div className="form-box">
	<div className="container">
		<div className="row">
		<div className="col-md-2"></div>
<div className="col-md-8">
<form>
  <div className="form-row">
<div className="col-2"></div>
    <div className="col-8">
   <div className="row cheerbox-r-row">
       <div className="col-5 cheerbox-r-type coworker-box">
         <span className="r-name">
             Co-worker
         </span>
       </div>
       <div className="col-2"></div>
       <div className="col-5 cheerbox-r-type">
       <span className="r-name">
             Boss
         </span>
       </div>
       
   </div>
   <div className="row cheerbox-r-row">
       <div className="col-5 cheerbox-r-type">
       <span className="r-name">
             Employee
         </span>
       </div>
       <div className="col-2"></div>
       <div className="col-5 cheerbox-r-type">
       <span className="r-name">
             Client
         </span>
       </div>
       
   </div>
    </div>
<div className="col-2"></div>
  </div>
</form>
			</div>
	
			
			</div>
		<div className="col-md-2"></div>
			</div>
		</div>
<div className="container">
<div className="row button-row">
				<div className="col-md-4"></div>
				<div className="col-md-4">
					
		<div className="button-group">
  <a className="button buttonGreen">Continue
    <div className="ripples buttonRipples"><span className="ripplesCircle"></span></div>
</a>
  </div>
				</div>
				<div className="col-md-4"></div>
			</div>
</div>
            </Wrapper>
        );
    }
}
const mapStateToProps = state => {
    return {
      reason: state.cheer.reason,
      firstName: state.cheer.firstName,
      lastName: state.cheer.lastName,
    };
  };
  const mapDispatchToProps = dispatch => {
    return {
      onSetReason: (reason) => dispatch({type: actionTypes.SET_REASON, payload: {reason}}),
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(CheerRelationship);
