import { ADD_ANSWER,INVITE_REQUEST } from '../actions/types'

const initialState = {

    answers:[]

}

const reducer = (state = initialState, action) => {
    //console.log(state)
    switch(action.type) {
        case ADD_ANSWER:

        for(var i=0; i<state.answers.length; i++){
            if(action.answer.question == state.answers[i].question){
                state.answers.splice(i,1)
            }
        }
            return {
                ...state,
                answers:[...state.answers,action.answer],
                
            };
        default: 
            return {...state};
    }
}

export default reducer;