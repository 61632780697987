import axios from 'axios';
import { LOGIN_USER,LOGIN_REQUEST,LOGIN_FAILED,PASSWORD_RESET,PASSWORD_POST,PASSWORD_POST_ERROR,PASSWORD_RESET_ERROR,UPDATE_PASSWORD_REQUEST,UPDATE_PASSWORD,UPDATE_PASSWORD_ERROR,PASSWORD_RESET_REQUEST} from './types'

var basedev = "https://api.mentalhappy.com/api/"
//var basedev = "http://cheerbox.dev.com/api/"

export const loginAsync = (data) =>dispatch=> {

    console.log(data)
    var endpoint = basedev +'login'
    var headers = {
    } 
        dispatch({
            type:LOGIN_REQUEST
        })
      axios.post(endpoint,data, {headers: headers})
        .then(res => {

            dispatch({
                type:LOGIN_USER,
                payload: res.data
            })
        }).catch(error=>{
            dispatch({
                type:LOGIN_FAILED,
                payload:error.response.data
            })
        })
        
        


    /* return dispatch => {
        // Utils.login(email, password)
        //     .then(user => {
        //         dispatch(login());
        //     })
        //     .catch(error => {

        //     });
        setTimeout(()=>{
            dispatch(login());
        }, 4000);
    }  */
}






export const passwordReset=(credentials)=>dispatch=> {
    var endpoint = basedev +'reset/request'

    var headers = {
        'Accept': 'application/json',
    }
    dispatch({
        type:PASSWORD_RESET_REQUEST
    })
      axios.post(endpoint, credentials , {headers: headers})
        .then(res => {
  
            dispatch({
                type:PASSWORD_RESET,
                payload:res.data
            })  
          //this.props.history.push('/');
        }).catch(error =>{

            dispatch({
                type:PASSWORD_RESET_ERROR,
                payload:null
            })
        })


    
}



export const resetPassword=(credentials)=>dispatch=> {

    var endpoint = basedev +'password/reset'

    var headers = {
        'Accept': 'application/json',
    }
    dispatch({
        type:LOGIN_REQUEST
    })
      axios.post(endpoint, credentials , {headers: headers})
        .then(res => {
            localStorage.setItem('access_token',res.data.access.access_token);

            dispatch({
                type:PASSWORD_POST,
                payload:res.data
            })
  
        }).catch(error =>{

            dispatch({
                type:PASSWORD_POST_ERROR,
                payload:null
            })
        })


    
}




export const updatePassword=(credentials)=>dispatch=> {

    var endpoint = basedev +'password/update'

    var headers = {
        'Accept': 'application/json',
        'Authorization':"Bearer " + localStorage.getItem('access_token')

    }
    dispatch({
        type:UPDATE_PASSWORD_REQUEST
    })
      axios.post(endpoint, credentials , {headers: headers})
        .then(res => {
            dispatch({
                type:UPDATE_PASSWORD,
                payload:res.data
            })
  
        }).catch(error =>{

            dispatch({
                type:UPDATE_PASSWORD_ERROR,
                payload:null
            })
        })


    
}



