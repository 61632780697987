import React from 'react'
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';



class ShowSign extends React.Component{


    state = {
        open: false,
        paid:false,
        fullWidth: true,
        maxWidth: 'md',
        invitee:[],
        email:''
      };
    
      handleClickOpen = () => {
        this.setState({ open: true });
       };
     
       handleClose = () => {
        this.setState({ open: false });
       };
              
       addEmail = (e)=>{
        this.setState({email:e.target.value})
    }

       addRecipient = (e)=>{
        
           this.setState({invitee:[...this.state.invitee, this.state.email]})

       }

       removeUser =(e)=>{

        let item =e.currentTarget.dataset.user
        var index = this.state.invitee.indexOf(item);
        if (index !== -1) this.state.invitee.splice(index, 1);
        this.setState({invitee:[...this.state.invitee]})

           console.log(e.currentTarget.dataset.user)
       }
 

    render(){
        const { classes } = this.props;
        //console.log(this.state)

            const invitees = this.state.invitee

            const showinvites=invitees.map((user,index)=>{

                return(<div key={index}>

                <div>
                <span>{user}</span> <span ><i className="la la-trash" data-user={user} onClick={this.removeUser}></i></span>

                </div>
                </div>)
            })
        return(

            <div>


<Button variant="outlined"  className="button buttonGreen" style={{float:'right',background:'#1ab1c3'}} onClick={this.handleClickOpen}>
Invite co-workers to sign this note.
        </Button>
<Dialog
  open={this.state.open}
  onClose={this.handleClose}
  aria-labelledby="Add co-workers"
  fullWidth={this.state.fullWidth}
  maxWidth={this.state.maxWidth}

>
  <DialogTitle id="form-dialog-title">Invite co-workers to sign this note.</DialogTitle>
  <DialogContent>
         <div style={{margin:'0 auto',width:'500px'}}>
         {showinvites}

                    <TextField
                        label="Email Address"
                        defaultValue=""
                        margin="normal"
                        className="auth-input "
                        name="email"
                        onChange= {this.addEmail}                                 

                        
                        />                 

<div>
<Button variant="contained"   color="primary"                        onClick= {this.addRecipient}                                 
 >
        Add
      </Button>

</div>
</div>
  </DialogContent>
  <DialogActions>
   <Button onClick={this.handleClose} color="primary">
      Done
    </Button>
    {/* <Button onClick={this.handleClose} color="primary">
      Subscribe
</Button> */}
  </DialogActions>
</Dialog>
</div>
        )
    }
}

export default ShowSign