import React, { Component } from 'react';

class SectionHeader extends Component {
    
  render() {
  
    return (

      <div className="row">
      <div className="col-md-12">
      <h4> {this.props.text} </h4>
      </div>
     
        
     
      </div>
    );
  }
}

export default SectionHeader;
