import React from 'react';

const type = (props) => {
    return (
        <div {...props} className={props.selected ? "cheerbox-type-two cheerbox-selected" : 'cheerbox-type-two '}>

            <img src={props.image} width='175' />
            <div className="btn-select-cheer">
            {props.label}

            </div>

        </div>
    );
}

export default type;