import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import Wrapper from '../../../components/wrapper/Wrapper';
import {loginAsync,updatePassword} from '../../../actions/authActions';

import {Redirect} from 'react-router-dom'
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import { Mixpanel } from '../../../Mixpanel'


const styles = theme => ({

  cssLabel: {
    '&$cssFocused': {
      color:"#1aa8bc",
    },
    '&:after': {
      borderBottomColor:"#1aa8bc",
    }  
  },
  cssFocused: {},
  cssUnderline: {
    '&:after': {
      borderBottomColor: "#1aa8bc",
    },
  },
  cssOutlinedInput: {
    '&$cssFocused $notchedOutline': {
      borderColor:  "#1aa8bc",
    },
  },

});

class Login extends Component {
  state = {
    newpassword: '',
    confirmpassword: '',
    confirmed:null
  }

  componentDidMount(){
    document.title="MentalHappy "
  }
  render() {

    const { classes } = this.props;

    //var errors;
    const {errorMessages} = this.props

     /*  if(this.props.auth !== true){
        return(
          <Redirect to="/login" />
        )
      }
 if(errorMessages.length !==0){
       errors = errorMessages.errors.map((error,index)=>{
        return(
          <div>
            <div className="alert alert-danger" role="alert">
              <strong>Opps!</strong> {error}
            </div>
          </div>

        )
      })
    }else{
       errors = false
    }
    */
   if(this.props.changeError === false ){
    return(
      <Redirect to="/dashboard" />
    )
  }
    return (
      <Wrapper>
			<form className='auth-form' onSubmit={this.changePassword}>

      <TextField
          label="New Password"
          margin="normal"
          className="auth-input "
          type="password"

          value={this.state.newpassword} onChange={this.newPassword} 
          InputLabelProps={{
            classes:{
              root: classes.cssLabel,
              focused: classes.cssFocused
            }
          }}
        
        />

      <TextField
          label="Confirm Password"
          className="auth-input "
          margin="normal"
          type="password"
          value={this.state.confirmpassword} onChange={this.confirmPassword}     
          InputLabelProps={{
            classes:{
              root: classes.cssLabel,
              focused: classes.cssFocused

            }
          }}  
        />
{this.state.confirmed===false ?(<div style={{width:'100%',textAlign:'center'}} >Password Do Not Match</div>):null}
  <div className="button-group">
  <button type="submit" className="button buttonGreen">Submit
    <div className="ripples buttonRipples"><span className="ripplesCircle"></span></div>
  </button>
  </div>
</form>


{this.props.error && (          <div>
  <div className="alert alert-danger" role="alert">
    <strong>Opps!</strong> Please check your credentials and try again.
  </div>
</div>)}
      </Wrapper>
    );
  }

  newPassword = (e) => {
    this.setState({
      newpassword: e.target.value,
    });
  }

  confirmPassword = (e) => {
    this.setState({
      confirmpassword: e.target.value,
    })
  }

  changePassword = (e) => {
    e.preventDefault();
    //TODO: validate email and password
    //console.log(this.state)
    if(this.state.newpassword === this.state.confirmpassword){
        this.props.updatepassword(this.state);
        Mixpanel.track("New Password Update")

        console.log(this.props.changeError)
        if(this.props.changeError !== true && this.props.changeError !== 'null')
        this.props.history.push('login')
    }
     else
     this.setState({confirmed:false})
    //this.props.history.push("/dashboard");
  }
}

const mapStateToProps = state => {
   return {
     errorMessages: state.auth.errorMessage,
     error:state.auth.error,
     auth:state.auth.auth,
     changeError: state.auth.changeError
   }
 }

const mapDispatchToProps = dispatch => {
  return {
    login: (data) => dispatch(loginAsync(data)),
    updatepassword:(data)=>dispatch(updatePassword(data))
  }
}


export default connect(mapStateToProps, mapDispatchToProps) (withStyles(styles)(Login));
