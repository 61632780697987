import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import Wrapper from '../../wrapper/Wrapper';
// import RightBar from '../../right-bar/RightBar';
import Footer from '../../footer/Footer';
import SummaryWidget from '../../summary-widget/SummaryWidget';
import CheerboxSentWidget from '../../CheerboxSentWidget/CheerboxSentWidget';
import SectionHeader from '../../Section-Header/SectionHeader';
import CheerboxLocator from '../../CheerboxLocator/CheerboxLocator';
import FeedbackWidget from '../../FeedbackWidget/FeedbackWidget';
import * as actionTypes from '../../../actions/dashboardActions';
import SummaryWidgetCredit from '../../summary-widget/SummaryWidgetCredit';

import {getDashboard} from '../../../actions/dashboardActions';
import { Mixpanel } from '../../../Mixpanel'


class Home extends Component {

  componentDidMount(){
    document.title="MentalHappy | Dashboard "
    Mixpanel.track('Dashboard Main Pageview')
    this.props.getDashboard()
  }
  render() {
    return (
      <Wrapper>
        <div className="container-fluid">
          <div className="row">
            <div className="page-header">
              <div className="d-flex align-items-center">
                <h2 className="page-header-title">Dashboard</h2>
                <div>
                  <div className="page-header-tools">
                    <Link className="button buttonGreen" to="/dashboard/order">Send Kit</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row flex-row">

            <SummaryWidget
              count={this.props.made}
              title="Total Kits"
              action="sent"
              startDate={this.props.totalDate}
              handleChange={(date) => this.props.onDateChanged(date, "total")}
            />
            <SummaryWidget
              count={this.props.notmade}
              title="Pending Kits"
              action="sent"
              startDate={this.props.pendingDate}
              handleChange={(date) => this.props.onDateChanged(date, "pending")}
            />

      <SummaryWidgetCredit
              count={this.props.creditCount}
              title="Total Kits Credits"
                       
            
            />
          
             
    

          </div>
          {/* <SummaryStats /> */}

       <SectionHeader 
        text="Kits Being Sent"
        element="Calendar"
       />
          <div >
          <CheerboxSentWidget 
             />

<SectionHeader 
        text="Where Kits are being sent"
        element="Calendar"
       />

    <CheerboxLocator/>

   
   {/* <FeedbackWidget />*/}
        </div>


        </div>

      
        <Footer />

        <Link to="#" className="go-top"><i className="la la-arrow-up"></i></Link>
        {/* <RightBar /> */}
      </Wrapper>
    );
  }

}



const mapStateToProps = state => {
  return {
    totalDate: state.dashboard.totalDate,
    totalCount : state.dashboard.totalCount,
    pendingDate : state.dashboard.pendingDate,
    pendingCount : state.dashboard.pendingCount,
    creditDate : state.dashboard.creditDate,
    creditCount : state.dashboard.creditCount,
    paid:state.dashboard.paid,
    made:state.dashboard.made,
    notmade:state.dashboard.notmade
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onDateChanged: (date, type) => dispatch(actionTypes.filterDate(date, type)),

    getDashboard:()=>dispatch(getDashboard())
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
