import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import { getPricing,setPricing } from '../../actions/pricingActions'
import { connect } from 'react-redux'
import PriceTab from './PriceBox';;


class PricingCards extends Component{



    render(){
        const price = this.props.prices.map((amount,index)=>{
            return(

                    <PriceTab key={index}
                    selected={this.props.setprice.id === amount.id}
                    onClick={() => this.props.setPricing(amount)}
                    amount={amount.package_amount}
                    price={amount.price}
                    />

            )
        })

        return(
            <div>
                {price}
            </div>
        )
    }
}

const mapStatesToProps = (state,ownProps) =>{
    return{
        pricing:ownProps.prices,
        setprice:state.pricing.setprice
    }
}

const mapDispatchToProps = dispatch =>{
    return{
        setPricing:(data)=>dispatch(setPricing(data))
        
    }
}
export default connect(mapStatesToProps,mapDispatchToProps) (PricingCards);