import React from 'react';
import {injectStripe} from 'react-stripe-elements';

import CardSection from './CardSection';
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom';

import { creditsPayment,setToken } from '../../actions/paymentActions'
import CircularProgress from '@material-ui/core/CircularProgress';

class CheckoutFormDash extends React.Component {

    state ={
      token:null
    }
  handleSubmit = (ev) => {
    // We don't want to let default form submission happen here, which would refresh the page.
    ev.preventDefault();

    // Within the context of `Elements`, this call to createToken knows which Element to
    // tokenize, since there's only one in this group.
  this.props.stripe.createToken({name: this.props.user.firstname+' '+this.props.user.lastname}).then(({token}) => {
      this.props.setToken(token.id)
      //this.setState({token:token.id})

    var data ={
      token: token.id,
      charge_type:'credits',
      resource_id:this.props.setprice.id


    }

    this.props.makePayment(data)



    //this.props.history.push('/dashboard')
  });
  

  };

  render() {

    //console.log(this.props.user)
    if(this.props.paymentstatus === true)
        this.props.onSuccess()


      return (
        <form onSubmit={this.handleSubmit}  >

        <hr/>
                            
          <CardSection />
  
                                
                                {this.props.fetching===true ?<CircularProgress style={{position:'absolute',left:'44%'}} />:(<button style={{margin:'0 auto',display:'block'}} className="button buttonGreen">Submit
                                    <div className="ripples buttonRipples"><span className="ripplesCircle"></span></div>
                                </button>)} 

                      </form>
      );


  }
}

const mapStateToProps = state =>{
  return{
    user: state.user.user,
    setprice:state.pricing.setprice,
    fetching:state.payment.fetching,
    paymentstatus:state.payment.paymentstatus

  }
}

const mapDispatchToProps = dispatch =>{
  return{
   setToken: token => dispatch(setToken(token)),
   makePayment:(data)=>dispatch(creditsPayment(data))
  }
}

export default withRouter(connect(mapStateToProps,mapDispatchToProps) (injectStripe(CheckoutFormDash)));