import axios from 'axios'
import { CLIENT_REQUEST,GET_CLIENT,CLIENT_ERROR} from './types'

const basedev = "https://api.mentalhappy.com/api/"


export const getCompany=()=>dispatch=>{

    
    var full = window.location.host;
    var parts = full.split('.')
    var client = parts[0]

    //client='app'


    var endpoint = basedev +'client/'+client
    var headers = {
        'Accept': 'application/json',
    } 
        dispatch({
            type:CLIENT_REQUEST
        })
      axios.get(endpoint , {headers: headers})
        .then(res => {
            dispatch({
                type:GET_CLIENT,
                payload: res.data.results
            })
        }).catch(error=>{
            dispatch({
                type:CLIENT_ERROR,
                payload:null
            })
        })
}
