import React, { Component } from 'react'
import Button from '@material-ui/core/Button';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux'
import { addAnswer } from '../../actions/quizActions';


const styles = theme => ({
    root: {
      display: 'flex',
      maxWidth:300,
      margin:'0 auto',
      width:'100%'
    },
    formControl: {
      margin: theme.spacing.unit * 3,
    },
    group: {
      margin: `${theme.spacing.unit}px 0`,
    },
  });
  const questions = [
    'I feel run down and drained of physical or emotional energy.',					
    'I have negative thoughts about my job.',		
    'I am harder and less sympathetic with people than perhaps they deserve.',					
    'I am easily irritated by small problems, or by my co-workers and team.',					
    'I feel misunderstood or unappreciated by my co-workers.',				
    'I feel that I have no one to talk to.',
    'I feel that I am achieving less than I should.',
    'I feel under an unpleasant level of pressure to succeed.',
    'I feel that I am not getting what I want out of my job.',
    'I feel that I am in the wrong organization or the wrong profession.',
    'I am frustrated with parts of my job.',
    'I feel that organizational politics or bureaucracy frustrate my ability to do a good job.',
    'I feel that there is more work to do than I practically have the ability to do.',
    'I feel that I do not have time to do many of the things that are important to doing a good quality job.',
    'I find that I do not have time to plan as much as I would like to.',
    'Thank you, click finish to view your results.'

]

class  BurnoutQuestions extends Component{


    

    state = {
        value: 'female',
        question:''
      };
      
    componentDidMount(){


        this.setState({question:questions[this.props.question]})

    }

    componentDidUpdate(oldProp,oldState){
        if(this.props.question !== oldProp.question){
            this.setState({question:questions[this.props.question],value:''})
        }
    }


    handleChange = event => {
        this.setState({value: event.target.value });
    
        const answers = {
          'value':event.target.value,
          'question':this.props.question
        };
        
        this.props.addAnswer(answers)
    
      };
    

    render(){
        const { classes } = this.props;

        
        return(
            <div style={{width:500,margin:'0 auto'}}>
            <h3 style={{textAlign:'center'}}>{this.state.question} </h3>

            <RadioGroup
            aria-label="Expertise"
            name="expertise"
            className={classes.group}
            value={this.state.value}
            onChange={this.handleChange}
          >
            <FormControlLabel value="1" control={<Radio  />} label="Not At All" />           
            <FormControlLabel value="2" control={<Radio />} label="Rarely" />
            <FormControlLabel value="3" control={<Radio />} label="Sometimes" />
            <FormControlLabel value="4" control={<Radio />} label="Often" />
            <FormControlLabel value="5" control={<Radio />} label="Very Often" />

            </RadioGroup>



            </div>
        )
    }
}
const mapStatesToProps = state =>{
    return{
        pricing:state.pricing.pricing,
    }
}

const mapDispatchToProps = dispatch =>{
    return{
        addAnswer: (answer)=>dispatch(addAnswer(answer)),
        
    }
}
export default  connect(mapStatesToProps,mapDispatchToProps) (withStyles(styles)(BurnoutQuestions) );