import { CREATE_PAYMENT,GET_PAYMENT,REQUEST_ERROR,SET_TOKEN,PAYMENT_REQUEST, GET_CREDITS} from './types'
import axios from 'axios';

const basedev = "https://api.mentalhappy.com/api/"
//const basedev = 'http://cheerbox.dev.com/api/'

export const getPayment=()=>dispatch=>{

    
    var endpoint = basedev +'payment'
    var headers = {
        'Accept': 'application/json',
    } 
        dispatch({
            type:PAYMENT_REQUEST
        })
      axios.get(endpoint , {headers: headers})
        .then(res => {

            dispatch({
                type:GET_PAYMENT,
                payload: res.data
            })
        }).catch(error=>{
            dispatch({
                type:REQUEST_ERROR,
                payload:null
            })
        })
}


export const setToken=(token)=>dispatch=>{

    dispatch({
        type:SET_TOKEN,
        payload:token
    })
    
}


export const creditsPayment=(data)=>dispatch=>{

    
    var endpoint = basedev +'credit/charge'
    var headers = {
        'Accept': 'application/json',
        'Authorization':"Bearer " + localStorage.getItem('access_token')

    } 
        dispatch({
            type:PAYMENT_REQUEST
        })
      axios.post(endpoint,data, {headers: headers})
        .then(res => {

            dispatch({
                type:CREATE_PAYMENT,
                payload: res.data
            })
        }).catch(error=>{
            dispatch({
                type:REQUEST_ERROR,
                payload:null
            })
        })
}





export const getCredits=()=>dispatch=>{

    
    var endpoint = basedev +'credit'
    var headers = {
        'Accept': 'application/json',
        'Authorization':"Bearer " + localStorage.getItem('access_token')

    } 
        dispatch({
            type:REQUEST_ERROR
        })
      axios.get(endpoint, {headers: headers})
        .then(res => {

            dispatch({
                type:GET_CREDITS,
                payload: res.data
            })
        }).catch(error=>{
            dispatch({
                type:REQUEST_ERROR,
                payload:null
            })
        })
}



