import React from 'react';
import {connect} from 'react-redux'
import * as actionTypes from '../../actions/cheerActions';


class Recipient extends React.Component{

    render(){
        return(
            <div className="form-box">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
 
                                                
                        <form>
                            <div className="form-row">
                                <div className="col-8">
                                                                   <div className="page-title">
    
                                            <span>Any extra information you want to provide?</span>
    
                                    </div>
                                    <textarea value={this.props.details} style={{height:300}} className="form-control details_textarea" id="exampleFormControlTextarea1" rows="10" placeholder="Give us more details...."   onChange={(e) => {this.props.onSetDetails(e.target.value);}} ></textarea>
                                </div>
                                <div className="col-4">
                                <div className="order_info_card">
                                    <ul className="list-group">
                                    <li className="list-group-item"><span className="order_info_card_question">Why do I need to provide details.</span><br/>
                                        <span className="order_info_card_answer"> We customize each Cheerbox that goes out. Providing us with details as to why you are sending the Cheerbox helps us achieve this.</span>
                                    </li>
    
                                    <li className="list-group-item"><span className="order_info_card_question">Will the recipient see what I wrote?</span><br/>
                                        <span className="order_info_card_answer"> Never. Our curators is the only one who has access to this. Once they are done with your Cheerbox it's no longer accessible.</span>
                                    </li>
    
    
                                    </ul>                        
                            
                            </div>           
                                <div>
    
    
                </div>
                
                                            
                                
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="col-md-4">
    
                
                </div>
            </div>
            </div>

        )
    }
}

const mapStateToProps = state => {
    return {
      details: state.cheer.notes,
    };
  };

  const mapDispatchToProps = dispatch => {
    return {
      onSetDetails: (details) => dispatch({type: actionTypes.SET_DETAILS, payload: {details}}),
    }
  };


export default connect(mapStateToProps,mapDispatchToProps)(Recipient);

