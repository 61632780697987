import  { CLIENT_REQUEST,GET_CLIENT,CLIENT_ERROR} from '../actions/types'

const initialState = {
    client:{},
    error:null


}


export default function(state = initialState,action){

    switch(action.type){
        case GET_CLIENT:
            return{
                ...state,
                client:action.payload,
                errors:null,
            }
        case CLIENT_REQUEST:
            return{
                ...state,
                userToken:action.payload,
                errors:null,
            }            
        case CLIENT_ERROR:
            return{
                ...state,
                errors:true
            }
        default:
            return state;

    }

}