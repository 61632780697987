import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Wrapper from '../../components/wrapper/Wrapper';
import CheerBoxLogo from '../../assets/img/cheerbox-logo.svg';
import BurnoutQuestions from './burnoutquestions';
import {connect} from 'react-redux'
import BurnoutResults from './burnoutresults';
const styles = theme => ({
  root: {
    width: '90%',
  },
  button: {
    marginRight: theme.spacing.unit,
  },
  instructions: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
  },
  overrides: {
    MuiStepIcon: {
     root: {
       '&$completed': {
         color: 'pink',
       },
       '&$active': {
         color: 'red',
       },
     },
     active: {},
     completed: {},
   }},
button: {
    marginTop: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    background:'#1aa8bc',
    '&$active': {
        color: '#1aa8bc',
      },
      color:'#fff'
},   
actionsContainer: {
    marginBottom: theme.spacing.unit * 2,
},
resetContainer: {
    padding: theme.spacing.unit * 3,
    width:'100%'
},
stepIcon: {
    color: "#1bb1c3 !important",

          },
          container: {
            display: 'flex',
            flexWrap: 'wrap',
          },
          textField: {
            marginLeft: theme.spacing.unit,
            marginRight: theme.spacing.unit,
          },
          dense: {
            marginTop: 16,
          },
          menu: {
            width: 200,
          }
     
});

function getSteps() {
  return ['1', '2', '3','4','5', '6', '7','8','9','10','11','12','13','14','15'];
}

function getStepContent(step) {
  switch (step) {
    case 0:
      return <BurnoutQuestions question={0} />;
    case 1:
      return <BurnoutQuestions question={1} />;
    case 2:
      return <BurnoutQuestions question={2} />;
    case 3:
      return <BurnoutQuestions question={3} />;
    case 4:
      return <BurnoutQuestions question={4} />;
    case 5:
      return <BurnoutQuestions question={5} />;
    case 6:
      return <BurnoutQuestions question={6} />;
    case 7:
      return <BurnoutQuestions question={7} />;
    case 8:
      return <BurnoutQuestions question={8} />;
    case 9:
      return <BurnoutQuestions question={9} />;
    case 10:
      return <BurnoutQuestions question={10} />;
    case 11:
      return <BurnoutQuestions question={11} />;      
    case 12:
      return <BurnoutQuestions question={12} />;    
    case 13:
      return <BurnoutQuestions question={13} />; 
    case 14:
      return <BurnoutQuestions question={14} />;          
    case 15:
      return <BurnoutQuestions question={15} />;                                    
    default:
      return 'Unknown step';
  }
}

class BurnoutQuiz extends React.Component {
  state = {
    activeStep: 0,
    skipped: new Set(),
    results:0
  };

 // isStepOptional = step => step === 1;

  //isStepFailed = step => step === 1;

  handleNext = () => {
    const { activeStep } = this.state;
    let { skipped } = this.state;
    if (this.isStepSkipped(activeStep)) {
      skipped = new Set(skipped.values());
      skipped.delete(activeStep);
    }
    const steps = getSteps();

    console.log(activeStep)
    if(activeStep === 14){

        console.log(this.props.answer)
        var total=0
        const results = this.props.answer.map((aw,index)=>{
                let answernumber = parseInt(aw.value,10)

                total = total + answernumber;


               // this.setState({results:resultadd})
            //let total = total + aw.value

            //return total;


        })

    }
    this.setState({
      activeStep: activeStep + 1,
      skipped,
      results:total
    });


  };

  handleBack = () => {
    this.setState(state => ({
      activeStep: state.activeStep - 1,
    }));
  };

  handleSkip = () => {
    const { activeStep } = this.state;


    this.setState(state => {
      const skipped = new Set(state.skipped.values());
      skipped.add(activeStep);
      return {
        activeStep: state.activeStep + 1,
        skipped,
      };
    });


  };

  handleReset = () => {
    this.setState({
      activeStep: 0,
    });
  };

  isStepSkipped(step) {
    return this.state.skipped.has(step);
  }

  render() {
    const { classes } = this.props;
    const steps = getSteps();
    const { activeStep } = this.state;

    return (
        <Wrapper>
                 <div className={classes.root}>
                 <div className="logo-section">
			<div className="container">
				<div className="row">
					<div className="col-md-4 head-group">
						<div className="mental-happy-logo">
							<img src="../../../assets/img/mental-happy-logo.png" alt="Mental happy logo" width='120'/>
						</div>
					</div>
				</div>
			</div>
		</div>
        <div className="header">
				<div className="container">
					<div className="row">
						<div className="col-md-4"></div>
						<div className="col-md-4 ">
                    <div className="page-title">
                            <span>Burnout Assessment Quiz</span>

                    </div>
                    <Typography variant="caption" style={{textAlign:'center',fontSize:16}}>Take our burnout assessment quiz.
                    <br/>                    <span>* Not a replacement for diagnostic assessment for intervention.</span>

                    </Typography>

						</div>
					</div>
				</div>
			</div>
			<div className="form-box">
            
				<div className="container">
					<div className="row">
                    <div className="col-lg-12 col-xl-12 col-sm-12"  >
        <Stepper activeStep={activeStep} style={{background:'inherit'}}>
          {steps.map((label, index) => {
            const props = {};
            const labelProps = {};

            if (this.isStepSkipped(index)) {
              props.completed = false;
            }
            return (
              <Step key={label} {...props}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        <div>
          {activeStep === steps.length ? (
            <div>
              <div className={classes.instructions}>
               <BurnoutResults results={this.state.results} />
              </div>
              <Button onClick={this.handleReset} className={classes.button}>
                Reset
              </Button>
            </div>
          ) : (
            <div>
              <Typography className={classes.instructions}>{getStepContent(activeStep)}</Typography>
              <div style={{textAlign:'center'}}>
                <Button
                  disabled={activeStep === 0}
                  onClick={this.handleBack}
                  className={classes.button}
                >
                  Back
                </Button>
   
                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.handleNext}
                  className={classes.button}
                >
                  {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
      </div>
      </div>
      </div>
      </div>
      </Wrapper>
 
    );
  }
}

BurnoutQuiz.propTypes = {
  classes: PropTypes.object,
};

const mapStatesToProps = state =>{
    return{
        answer:state.answers.answers,
    }
}


export default  connect(mapStatesToProps,null) (withStyles(styles)(BurnoutQuiz) );


