import React, { Component } from 'react';

import Avatar2 from "../../assets/img/avatar/avatar-02.jpg";
import Avatar3 from "../../assets/img/avatar/avatar-03.jpg";
import Avatar4 from "../../assets/img/avatar/avatar-04.jpg";
import Avatar5 from "../../assets/img/avatar/avatar-05.jpg";
import Avatar6 from "../../assets/img/avatar/avatar-06.jpg";
import "./RightBar.css";

class RightBar extends Component {
  render() {
    return (
      <div className="off-sidebar from-right">
        <div className="off-sidebar-container">
          <header className="off-sidebar-header">
            <ul className="button-nav nav nav-tabs mt-3 mb-3 ml-3" role="tablist" id="weather-tab">
              <li><a className="active" data-toggle="tab" href="#messenger" role="tab" id="messenger-tab">Messages</a></li>
              <li><a data-toggle="tab" href="#today" role="tab" id="today-tab">Today</a></li>
            </ul>
            <a href="#" className="off-sidebar-close"></a>
          </header>
          <div className="off-sidebar-content offcanvas-scroll auto-scroll">
            <div className="tab-content">

              <div role="tabpanel" className="tab-pane show active fade" id="messenger" aria-labelledby="messenger-tab">

                <span className="date">Today</span>
                <div className="messenger-message messenger-message-sender">
                  <img className="messenger-image messenger-image-default" src={ Avatar2 } alt="..." />
                  <div className="messenger-message-wrapper">
                    <div className="messenger-message-content">
                      <p>
                        <span className="mb-2">Brandon wrote</span>
                        Hi David, what's up?
                          </p>
                    </div>
                    <div className="messenger-details">
                      <span className="messenger-message-localization font-size-small">2 minutes ago</span>
                    </div>
                  </div>
                </div>
                <div className="messenger-message messenger-message-recipient">
                  <div className="messenger-message-wrapper">
                    <div className="messenger-message-content">
                      <p>
                        Hi Brandon, fine and you?
                         </p>
                      <p>
                        I'm working on the next update of Cheerbox
                         </p>
                    </div>
                    <div className="messenger-details">
                      <span className="messenger-message-localisation font-size-small">3 minutes ago</span>
                    </div>
                  </div>
                </div>
                <div className="messenger-message messenger-message-sender">
                  <img className="messenger-image messenger-image-default" src={ Avatar2 } alt="..." />
                  <div className="messenger-message-wrapper">
                    <div className="messenger-message-content">
                      <p>
                        <span className="mb-2">Brandon wrote</span>
                        I can't wait to see
                          </p>
                    </div>
                    <div className="messenger-details">
                      <span className="messenger-message-localization font-size-small">5 minutes ago</span>
                    </div>
                  </div>
                </div>
                <div className="messenger-message messenger-message-recipient">
                  <div className="messenger-message-wrapper">
                    <div className="messenger-message-content">
                      <p>
                        I'm almost done
                         </p>
                    </div>
                    <div className="messenger-details">
                      <span className="messenger-message-localisation font-size-small">10 minutes ago</span>
                    </div>
                  </div>
                </div>
                <span className="date">Yesterday</span>
                <div className="messenger-message messenger-message-sender">
                  <img className="messenger-image messenger-image-default" src={ Avatar5 } alt="..." />
                  <div className="messenger-message-wrapper">
                    <div className="messenger-message-content">
                      <p>
                        I updated the server tonight
                          </p>
                    </div>
                  </div>
                </div>
                <div className="messenger-message messenger-message-recipient">
                  <div className="messenger-message-wrapper">
                    <div className="messenger-message-content">
                      <p>
                        Didn't you have any problems?
                         </p>
                    </div>
                  </div>
                </div>
                <div className="messenger-message messenger-message-sender">
                  <img className="messenger-image messenger-image-default" src={ Avatar5 } alt="..." />
                  <div className="messenger-message-wrapper">
                    <div className="messenger-message-content">
                      <p>No!</p>
                    </div>
                  </div>
                </div>
                <div className="messenger-message messenger-message-recipient">
                  <div className="messenger-message-wrapper">
                    <div className="messenger-message-content">
                      <p>Great!</p>
                      <p>See you later!</p>
                    </div>
                  </div>
                </div>
                <div className="enter-message">
                  <div className="enter-message-form">
                    <input type="text" placeholder="Enter your message..." />
                  </div>
                  <div className="enter-message-button">
                    <a href="#" className="send"><i className="ion-paper-airplane"></i></a>
                  </div>
                </div>
              </div>
              <div role="tabpanel" className="tab-pane fade" id="today" aria-labelledby="today-tab">
                <div className="sidebar-heading pt-0">Today</div>
                <div className="today-stats mt-3 mb-3">
                  <div className="row">
                    <div className="col-4 text-center">
                      <i className="la la-users"></i>
                      <div className="counter">264</div>
                      <div className="heading">Clients</div>
                    </div>
                    <div className="col-4 text-center">
                      <i className="la la-cart-arrow-down"></i>
                      <div className="counter">360</div>
                      <div className="heading">Sales</div>
                    </div>
                    <div className="col-4 text-center">
                      <i className="la la-money"></i>
                      <div className="counter">$ 4,565</div>
                      <div className="heading">Earnings</div>
                    </div>
                  </div>
                </div>
                <div className="sidebar-heading">Friends</div>
                <div className="quick-friends mt-3 mb-3">
                  <ul className="list-group w-100">
                    <li className="list-group-item">
                      <div className="media">
                        <div className="media-left align-self-center mr-3">
                          <img src={ Avatar5 } alt="..." className="img-fluid rounded-circle"  />
                        </div>
                        <div className="media-body align-self-center">
                          <a href="javascript:void(0);">Brandon Smith</a>
                        </div>
                      </div>
                    </li>
                    <li className="list-group-item">
                      <div className="media">
                        <div className="media-left align-self-center mr-3">
                          <img src={Avatar3} alt="..." className="img-fluid rounded-circle"  />
                        </div>
                        <div className="media-body align-self-center">
                          <a href="javascript:void(0);">Louis Henry</a>
                        </div>
                      </div>
                    </li>
                    <li className="list-group-item">
                      <div className="media">
                        <div className="media-left align-self-center mr-3">
                          <img src={ Avatar4 } alt="..." className="img-fluid rounded-circle"  />
                        </div>
                        <div className="media-body align-self-center">
                          <a href="javascript:void(0);">Nathan Hunter</a>
                        </div>
                      </div>
                    </li>
                    <li className="list-group-item">
                      <div className="media">
                        <div className="media-left align-self-center mr-3">
                          <img src={ Avatar5 } alt="..." className="img-fluid rounded-circle"  />
                        </div>
                        <div className="media-body align-self-center">
                          <a href="javascript:void(0);">Megan Duncan</a>
                        </div>
                      </div>
                    </li>
                    <li className="list-group-item">
                      <div className="media">
                        <div className="media-left align-self-center mr-3">
                          <img src={ Avatar6 } alt="..." className="img-fluid rounded-circle"  />
                        </div>
                        <div className="media-body align-self-center">
                          <a href="javascript:void(0);">Beverly Oliver</a>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="sidebar-heading">Settings</div>
                <div className="quick-settings mt-3 mb-3">
                  <ul className="list-group w-100">
                    <li className="list-group-item">
                      <div className="media">
                        <div className="media-body align-self-center">
                          <p className="text-dark">Notifications Email</p>
                        </div>
                        <div className="media-right align-self-center">
                          <label>
                            <input className="toggle-checkbox" type="checkbox" defaultChecked />
                            <span>
                              <span></span>
                            </span>
                          </label>
                        </div>
                      </div>
                    </li>
                    <li className="list-group-item">
                      <div className="media">
                        <div className="media-body align-self-center">
                          <p className="text-dark">Notifications Sound</p>
                        </div>
                        <div className="media-right align-self-center">
                          <label>
                            <input className="toggle-checkbox" type="checkbox" />
                            <span>
                              <span></span>
                            </span>
                          </label>
                        </div>
                      </div>
                    </li>
                    <li className="list-group-item">
                      <div className="media">
                        <div className="media-body align-self-center">
                          <p className="text-dark">Chat Message Sound</p>
                        </div>
                        <div className="media-right align-self-center">
                          <label>
                            <input className="toggle-checkbox" type="checkbox" defaultChecked />
                            <span>
                              <span></span>
                            </span>
                          </label>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default RightBar;
