import React from 'react'
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import axios from 'axios'
import CircularProgress from '@material-ui/core/CircularProgress';
import './bulk.css'
import {Link } from 'react-router-dom';
import { Mixpanel } from '../../../Mixpanel'

const styles = theme => ({
    button: {
      margin: theme.spacing.unit,
    },
    input: {
      display: 'none',
    },
  });
  

class Bulk extends React.Component{


    constructor(props){
        super(props)
        this.state = {
          // Set initial files
          files: [],
          position:null,
          reason:'',
          filename:'',
          filesource:''

      };
    }

    componentDidMount(){
        document.title="MentalHappy Kits | Bulk Upload "
        Mixpanel.track('Bulk Upload')
    }

    handleSubmit = event=>{
        this.setState({position:event.currentTarget.dataset.figure_type})
    
        const basedev = "https://api.mentalhappy.com/api/"
        const data = new FormData()
        data.append('bulkfile', this.state.filesource,this.state.filename)
        data.append('reason',this.state.reason)

          const headers = {
            'Authorization':"Bearer " + localStorage.getItem('access_token')
        }  
        var endpoint =basedev+'bulk/upload'
            this.setState({position:'bulk'})
        
          axios.post(endpoint ,data ,{headers:headers})
          .then(res => {
            this.setState({position:null,success:true})

            //this.setState({avatarphoto:'https://mentalhappy.s3.us-west-2.amazonaws.com/profile/avatar/'+res.data.filename,position:null})
            //this.props.setAvatar('https://mentalhappy.s3.us-west-2.amazonaws.com/profile/avatar/'+res.data.filename)
    
          }).catch(error=>{
            this.setState({position:null})
    
          })
          
          
    }

    handleChange = event =>{

        this.setState({'filename':event.target.files[0].name,'filesource':event.target.files[0]})

    
      }

      onReason = e=>{

        this.setState({reason:e.target.value})

      }
    

    render(){
        const { classes } = this.props;

        return(
            <div>
                <div className="container-fluid">
                        <div className="row">
                            <div className="page-header">
	                            <div className="d-flex align-items-center">
	                                <h2 className="page-header-title">Bulk MentalHappy Kits</h2>
                                    <div>
                                        <ul className="breadcrumb">
                                        <li className="breadcrumb-item"><Link to="/dashboard"><i className="ti ti-home"></i></Link></li>
                                            <li className="breadcrumb-item active">Bulk Order</li>
                                        </ul>
                                    </div>	                            
                                </div>
                            </div>
                        </div>
                        <div className="container-fluid">
                        <div className="row flex-row">
                            <div className="col-xl-8">
                                <div className="widget has-shadow">
                                    <div className="widget-body">


                                    <div className="bulk-context">
                                    <div className="bulk-directions">
                                        <h3>All uploads must include the following columns.</h3>
                                        <ul className="no-style">
                                            <li><span className="column-title">Name </span> - First and Last name of the Kit recipient</li>
                                            <li><span className="column-title">Address </span> - The shipping address where the Kit should be sent</li>
                                            <li><span className="column-title">Reason </span> - Why you are sending the Kit</li>
                                            <li><span className="column-title">Note </span> - The message you want included in the Kit. If you don't provide one we will write a note on your behalf.</li>

                                        </ul>
                                        


                                    </div>
                                    <div className="em-separator separator-dashed"></div>
                                    <label style={{float:'left',fontWeight:700}}> What's this bulk order for?</label>
                                    <textarea style={{height:50}} autoFocus className="form-control" id="exampleFormControlTextarea1" rows="10" placeholder="What is the purpose of this bulk order? Is it for an event or special occasion?" onChange={this.onReason}  ></textarea>

            <div style={{marginTop:20}}>
                                    <input
        accept="*"
        className={classes.input}
        id="contained-button-file"
        multiple
        type="file"
        onChange={this.handleChange}
      />
      {this.state.position==="bulk" ?<CircularProgress  style={{display:'block',margin:'0 auto'}} />:(<div>      <label htmlFor="contained-button-file">
        <Button variant="contained" component="span">
          Select Excel or CSV File
        </Button>
      </label> <br/>{this.state.filename !=='' ? (<div><span style={{fontSize:20}}><i className="la la-paperclip" ></i>{this.state.filename}<br/></span>    <span style={{display:'block'}}> <button type="submit" className="button buttonGreen  btn-sm"  onClick={this.handleSubmit}>Upload<div className="ripples buttonRipples"><span className="ripplesCircle"></span></div></button></span>
 </div>):null} <br/><a href="/assets/samples/Cheerbox-Request-Bulk.csv" download>Download a sample CSV</a></div>  )} 

  
   
      </div>

      {this.state.success===true ?(
<div>
  <div className="alert alert-outline-success" role="alert">
                                  <strong>Yay!</strong> Your bulk order has been sent. Your account manager will contact you soon. 
                              </div>

</div>):null}
            </div>


                   </div>
                                </div>
                            </div>
                            <div className="col-xl-4" >

                                <div className="widget " style={{background:'inherit'}}>
                                <div className="order_info_card" style={{marginTop:50}}>
                                <ul className="list-group">
                                <li className="list-group-item"><span className="order_info_card_question">Which Columns does my csv file need to have?</span><br/>
                                    <span className="order_info_card_answer"> You can order your columns any way you like, our team will review it to make sure it's correct. <a href="/assets/samples/Cheerbox-Request-Bulk.csv" download>Download a sample CSV</a></span>
                                </li>
                                
                                <li className="list-group-item"><span className="order_info_card_question">How does this work?</span><br/>
                                    <span className="order_info_card_answer"> Uploading a csv or excel file is easy! Just click the browse button on the right to upload your file.</span>
                                </li>

                                </ul>                        
                        
                        </div>

                                </div>
                            </div>
                        </div>
                        
                              </div>                        
                </div>


            </div>
        )
    }
}

Bulk.propTypes = {
    classes: PropTypes.object.isRequired,
  };
  
export default  withStyles(styles)(Bulk)