import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import Wrapper from '../../../components/wrapper/Wrapper';

class VerifyEmail extends Component {
  render() {
    return (
      <Wrapper>
<div class="bottom-links">
<p>A verification mail has been sent to your email. <br/>
Please check your mail to verify your account.
</p>
<p>Didn't get the verification mail. <Link to="#">Resend</Link></p><br/>
<p>Just take me to the <Link to='/dashboard'>Dashboard</Link></p>
</div>

      </Wrapper>
    );
  }
}
export default VerifyEmail;
