import React, { Component } from 'react';

class Footer extends Component {
  render() {
    return (
      <footer className="main-footer">
        <div className="row">
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 d-flex align-items-center justify-content-xl-start justify-content-lg-start justify-content-md-start justify-content-center">
            <p className="text-gradient-02">Design By MentalHappy</p>
          </div>

        </div>
      </footer>
    );
  }
}

export default Footer;
