import axios from 'axios'
import { CREDIT_REQUEST,GET_CREDIT,CREDIT_ERROR} from './types'

const basedev = "https://api.mentalhappy.com/api/"


export const getCredits=()=>dispatch=>{



    var endpoint = basedev +'credit'
    var headers = {
        'Accept': 'application/json',
        'Authorization':"Bearer " + localStorage.getItem('access_token')

    } 
        dispatch({
            type:CREDIT_REQUEST
        })
      axios.get(endpoint , {headers: headers})
        .then(res => {
            dispatch({
                type:GET_CREDIT,
                payload: res.data.results
            })
        }).catch(error=>{
            dispatch({
                type:CREDIT_ERROR,
                payload:null
            })
        })
}
