import React from 'react';
import { connect } from 'react-redux';
import * as actionTypes from '../../actions/cheerActions';
import TextField from '@material-ui/core/TextField';
import { withStyles} from '@material-ui/core/styles';



const styles = theme => ({

    cssLabel: {
      '&$cssFocused': {
        color:"#1aa8bc",
      },
      '&:after': {
        borderBottomColor:"#1aa8bc",
      }  
    },
    cssFocused: {},
    cssUnderline: {
      '&:after': {
        borderBottomColor: "#1aa8bc",
      },
    }
  
  });
  
class Recipient extends React.Component{

    render(){
        const { classes } = this.props;

        return(
            <div className="form-box">
            <div className="container">
                <div className="row">
                    <div className="col-md-8">

                        <div className="page-title">

                            <span>Who is the recipient of this Cheerbox?</span>

                        </div>
                        <form>
                        <div className="form-row">
                                <div className="col">
    
                                    <select defaultValue={this.props.gender} className="custom-select custom-select-lg mb-3"   onChange={(e) => {this.props.onSetName(e.target.value,this.props.firstName,this.props.lastName,this.props.email);}} >
                                        <option >Gender Specific Pronouns</option>
                                        <option value="he-him-his">(he/him/his)</option>
                                        <option value="she-her-hers">(she/her/hers)</option>
                                        <option value="zie-they-them">(zie/they/them)</option>
                                    </select>
                                </div>
                            </div>
    
                            <div className="form-row">
                                <div className="col">
                    
                                <TextField
                                    label="Recipient's First Name"
                                    defaultValue=""
                                    margin="normal"
                                    className="auth-input "
                                    name="email"
                                    defaultValue={this.props.firstName} 
                                    value={this.props.firstName}
                                    onChange={(e) => {this.props.onSetName(this.props.gender,e.target.value, this.props.lastName,this.props.email);}}                                      
                                    InputLabelProps={{
                                        classes:{
                                        root: classes.cssLabel,
                                        focused: classes.cssFocused
                                        }
                                    }}
                                    
                                    />
                             
                                               </div>
                                <div className="col">

                    
                                <TextField
                                    label="Recipient's Last Name"
                                    margin="normal"
                                    className="auth-input "
                                    defaultValue={this.props.lastName}
                                    value={this.props.lastName}
                                    name="firstName"
                                    onChange={(e) => {this.props.onSetName( this.props.gender,this.props.firstName,e.target.value,this.props.email);}}
                                    InputLabelProps={{
                                        classes:{
                                        root: classes.cssLabel,
                                        focused: classes.cssFocused,
                                        }
                                    }}
                                    
                                    />
                                               </div>

                            </div>
                            <div className="form-row">

                                <div className="col">
                    
                                <TextField
                                    label="Recipient's Email Name"
                                    margin="normal"
                                    className="auth-input "
                                    name="lastName"
                                    defaultValue={this.props.email} 
                                    onChange={(e) => {this.props.onSetName(this.props.gender,this.props.firstName,this.props.lastName,e.target.value);}} 
                                    InputLabelProps={{
                                        classes:{
                                        root: classes.cssLabel,
                                        focused: classes.cssFocused,
                                        }
                                    }}
                                    
                                    />
                    
                                               </div>
                            </div>
                        </form>
                    </div>
                    <div className="col-md-4">
                    <div>
                        <div className="order_info_card">
                                <ul className="list-group">
                                <li className="list-group-item"><span className="order_info_card_question">Why do we need their name.</span><br/>
                                    <span className="order_info_card_answer">  We will use the recipient name on your notecard.</span>
                                </li>
                                <li className="list-group-item"><span className="order_info_card_question">Why do we need their email.</span><br/>
                                    <span className="order_info_card_answer">  We email your recipient after they've recieved their Cheerbox to get feedback for you.</span>
                                </li>

                                </ul>                        
                        
                        </div>

                    </div>

                    
                    </div>
                </div>
            </div>
        </div>


        )
    }
}

const mapStateToProps = state => {
    return {
      gender:state.cheer.gender_specific,
      firstName: state.cheer.recipient_first_name,
      lastName: state.cheer.recipient_last_name,
      email: state.cheer.recipient_email
    };
  };

  const mapDispatchToProps = dispatch => {
    return {
      onSetName: (gender,firstName, lastName, email) => dispatch({type: actionTypes.SET_NAME, payload: {gender,firstName, lastName,email}}),
    }
  };


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Recipient));