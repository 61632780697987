import axios from 'axios';
import { ADD_ANSWER} from './types'

const basedev = "https://api.mentalhappy.com/api/"

export const addAnswer = (data) =>dispatch=> {


        dispatch({
            type:ADD_ANSWER,
            answer:data
        })
}
