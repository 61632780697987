import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import CssModules from 'react-css-modules';

import styles from "./Cheerbox.css";
import Wrapper from '../../components/wrapper/Wrapper';
import Logo from '../../assets/img/cheerbox-logo.svg';
import CheerType from '../../components/cheerbox/cheerbox-type/CheerType';
import CheerName from '../../components/cheerbox/cheerbox-name/CheerName';
import CheerReason from '../../components/cheerbox/cheerbox-reason/CheerReason';
import CheerNote from '../../components/cheerbox/cheerbox-note/CheerNote';
import CheerRelationship from '../../components/cheerbox/cheerbox-relationship/CheerRelationship';

class Cheerbox extends Component {
    componentDidMount() {
        // document.title = 'Cheerbox - New Cheerbox';
    }
    render() {
        return (
            <Wrapper>

                <div className="logo-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-4 head-group">
                                <div className="mental-happy-logo">
                                    <img src={Logo} alt="Mental happy logo" width="120" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                    <Switch>
                        <Route path='/cheerbox/set-recipient-name' component={CheerName} />
                        <Route path='/cheerbox/set-cheer-reason' component={CheerReason} />
                        <Route path='/cheerbox/set-cheer-note' component={CheerNote} />
                        <Route path='/cheerbox/set-relationship' component={CheerRelationship} />
                        <Route path='/' component={CheerType} />
                        <Route path='/' render={() => (<div>Not found</div>)} />
                    </Switch>
                <div className="footer">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-4"></div>
                            <div className="col-md-4">
                            </div>
                        </div>
                    </div>
                </div>
                
            </Wrapper>
        );
    }
}
const mapStateToProps = state => {
    return {
        // cheers: state.cheers
    };
};
const mapDispatchToProps = dispatch => {
    return {
        // onGetCheers: (userId) => dispatch({type: actionTypes.GET_CHEERS, data: {user: userId}}),
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(Cheerbox);
