import React from 'react';
import {connect} from 'react-redux'
import * as actionTypes from '../../actions/cheerActions';
import SigneeInvite from './SigneeInvite';
import ShowSign from './ShowSign';

class Notecard extends React.Component{


    render(){
        return(
            <div className="form-box">

            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <form>
                            <div className="form-row">
                                <div className="col-8">
                                    <div className="page-title">

                                            <span>Write your own personalized note for Recipient.</span>

                                    </div>
                                    <div className="notecard">
    
                                    <span className="notecard_heading">Dear {this.props.firstname && this.props.firstname}</span>
    
                                    <div className="form-group">
                                        <textarea value={this.props.usernote}  className="notecard_textarea" defaultValue={this.props.usernote} id="exampleFormControlTextarea1" rows="10" placeholder="Give us more details...."
                                         onChange={(e) => {this.props.onSetNote(e.target.value, this.props.notesigned);}}   ></textarea>
                                    </div>
    
                                    <div style={{display:'block'}}>
                                        <div style={{float:'left'}} >
                                            <span className="notecard_signee" >On behalf of: </span>
                                            <input value={this.props.notesigned} type="text"  className="notecard_signee_line" placeholder="Name"  onChange={(e) => {this.props.onSetNote(this.props.usernote,e.target.value);}}  />
                                        </div>
                                    </div>

                                    <ShowSign />

                                    </div>                            

                                </div>
                                <div className="col-4">
                                <div>
                            <div className="order_info_card" style={{marginTop:50}}>
                                    <ul className="list-group">
                                    <li className="list-group-item"><span className="order_info_card_question">Why do I need a note.</span><br/>
                                        <span className="order_info_card_answer"> The note allows you to personalize your message to the recipient. It will come off as more authentic.</span>
                                    </li>
    
                                    <li className="list-group-item"><span className="order_info_card_question">Can we write the note?</span><br/>
                                        <span className="order_info_card_answer"> If your account has the option for us to write the note, we will write one and include it in the Cheerbox.</span>
                                    </li>
    
                                    <li className="list-group-item"><span className="order_info_card_question">Can I review the note the Cheerbox team writes?</span><br/>
                                        <span className="order_info_card_answer"> No! This will delay your Cheerbox going out. </span>
                                    </li>
    
                                    </ul>                        
                            
                            </div>
    
                </div>                            
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="col-md-4">
    
                
                </div>
            </div>
            </div>


        )
    }
}


const mapStateToProps = state => {
    return {
      usernote: state.cheer.user_notes,
      notesigned: state.cheer.notefrom,
      firstname:state.cheer.recipient_first_name
    };
  };

  const mapDispatchToProps = dispatch => {
    return {
      onSetNote: (userNote, noteSignedBy) => dispatch({type: actionTypes.SET_NOTE, payload: {userNote, noteSignedBy}}),
    }
  };


export default connect(mapStateToProps,mapDispatchToProps)(Notecard);