
import { LOGIN_USER,LOGIN_REQUEST,LOGIN_FAILED,PASSWORD_RESET, PASSWORD_RESET_ERROR,PASSWORD_POST,PASSWORD_POST_ERROR,UPDATE_PASSWORD_REQUEST,UPDATE_PASSWORD,UPDATE_PASSWORD_ERROR,PASSWORD_RESET_REQUEST} from '../actions/types'

const initialState = {
    email: '',
    password: '',
    error:false,
    errorMessage:[],
    accessToken:'',
    auth:false,
    fetching:false,
    resetERROR:null,
    reset:false,
    code:null,
    changeError:null,
    istemp:null
}

const reducer = (state = initialState, action) => {
    switch(action.type) {
        case LOGIN_USER:
        localStorage.setItem('access_token',action.payload.access.access_token);
            return {
                ...state,
                email: action.email,
                password: action.password,
                error:false,
                accessToken:action.payload.access.access_token,
                auth:true,
                istemp:action.payload.temp
                
            };
        case LOGIN_FAILED:
            return {
                ...state,
                error:true,
                errorMessage:action.payload,
                auth:false

            };
            case PASSWORD_RESET:

            return{
                ...state,
                authError:null,
                createError:null,
                fetching:false,
                resetERROR:false,

            }
            case UPDATE_PASSWORD:
            return{
                ...state,
                authError:null,
                createError:null,
                fetching:false,
                resetERROR:false,
                changeError:false
            }
            case UPDATE_PASSWORD_REQUEST:
            return{
                ...state,
                authError:null,
                createError:null,
                fetching:false,
                resetERROR:false,

            }
                                        
            case UPDATE_PASSWORD_ERROR:
            return{
                ...state,
                authError:null,
                createError:null,
                fetching:false,
                resetERROR:false,
                changeError:true,

            }            
        case PASSWORD_RESET_ERROR:
            return{
                ...state,
                resetERROR:true,
                reset:false,
                fetching:false,
                changeError:true

            }
        case PASSWORD_RESET_REQUEST:
            return{
                ...state,
                fetching:true
            }
        case PASSWORD_POST:
            return{
                ...state,
                authError:null,
                reset:true,
                createError:null,
                fetching:false,
                postERROR:false,
                auth:true,
                avatar:action.payload.avatar,
                about:action.payload.about,
                user: action.payload.user,
                accessToken:action.payload.access.access_token

            }
        case PASSWORD_POST_ERROR:
            return{
                ...state,
                postERROR:true,
                reset:false,
                fetching:false

            }

        default: 
            return {...state};
    }
}

export default reducer;