import { USER_REQUEST,USER_FETCH,CREATE_USER,GET_USER,REQUEST_ERROR,GET_AVATAR,CREATE_AVATAR,GET_AVATAR_REQUEST,SET_AVATAR} from '../actions/types'

const initialState = {
    userToken:{},
    user:{},
    avatar:null,
    errors:null,
    created:false

}


export default function(state = initialState,action){

    switch(action.type){
        case USER_FETCH:
        if(action.payload.avatar !== null)
            var avatarImage='https://mentalhappy.s3.us-west-2.amazonaws.com/profile/avatar/'+action.payload.avatar.photoPath
        else
            var avatarImage=null
            
            return{
                ...state,
                user:action.payload,
                avatar:avatarImage,
                errors:null
            }
            

            break;

        case CREATE_USER:
            return{
                ...state,
                userToken:action.payload,
                errors:null,
                created:true
            }  
            break;
              
        case CREATE_AVATAR:
            return{
                ...state,
                avatar:action.payload.imagePath,
                errors:null,
            }   
            break;
                             
        case USER_REQUEST:
            return{
                ...state,
                errors:null,
                created:false

            }
            break;
        case SET_AVATAR:
            return{
                ...state,
                avatar:action.payload,
            }     
            break;

        case REQUEST_ERROR:
            return{
                ...state,
                errors:true,
                created:false
            }
            break;

        default:
            return state;

    }

}