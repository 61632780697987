import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import withMobileDialog from '@material-ui/core/withMobileDialog';

class CheerboxExamples extends React.Component {
  state = {
    open: false,
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  render() {
    const { fullScreen } = this.props;
    var frame_url
    switch(this.props.exampleContent){
        case '1':
             frame_url='https://mentalhappy.com/kits/appreciation';
            break;
        case '2':
             frame_url='https://mentalhappy.com/kits/illness-injury-male';
            break;
        case '3':
             frame_url='https://mentalhappy.com/kits/loss-standard';
            break;
        case '4':
             frame_url='https://mentalhappy.com/kits/appreciation';
            break;
        case '5':
             frame_url='https://mentalhappy.com/kits/healing';
            break;
        case '6':
             frame_url='https://mentalhappy.com/kits/new-mom';
            break;
        case '7':
             frame_url='https://mentalhappy.com/kits/illness-injury-female';
            break;
        case '8':
             frame_url='https://www.slideshare.net/slideshow/embed_code/key/llWiTPfQ2Rjb0Q';
             break;
        case '10':
             frame_url='https://mentalhappy.com/kits/new-dad';
             break;             
        case '9':
             frame_url='https://mentalhappy.com/kits/loss-spiritual';
             break;             
        default:
             frame_url='https://landing.mentalhappy.com/hope-after-loss-2-1-0'
                
    }


    return (
      <div>
        <a  href="javascript:void(0)" className="nav-item" onClick={this.handleClickOpen } style={{marginTop:5,display:'block'}}>
          {this.props.exampleTitle}
        </a>
        <Dialog
          fullWidth={true}        
          fullScreen={fullScreen}
          open={this.state.open}
          maxWidth='lg'

          onClose={this.handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">{"Example "+this.props.exampleTitle}</DialogTitle>
          <DialogContent>
            <DialogContentText>
            <iframe src={frame_url} style={{height:500,width:'100%',border:0}}>

</iframe>

            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} className="button buttonGreen" autoFocus>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

CheerboxExamples.propTypes = {
  fullScreen: PropTypes.bool.isRequired,
};


export default withMobileDialog()(CheerboxExamples);