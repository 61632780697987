import { createStore, combineReducers, applyMiddleware,compose } from 'redux';
import authReducer from '../reducers/authReducer';
import cheerReducer from '../reducers/cheerReducer';
import thunk from 'redux-thunk';
import pricingReducer from '../reducers/pricingReducer';
import userReducer from '../reducers/userReducer';
import dashboardReducer from '../reducers/dashboardReducer';
import inviteReducer  from '../reducers/inviteReducer';
import paymentReducer from '../reducers/paymentReducer'
import clientReducer from '../reducers/clientReducer'
import creditReducer from '../reducers/creditReducer';
import quizReducer from '../reducers/quizReducer';

const reducer = combineReducers({
    auth: authReducer,
    cheer: cheerReducer,
    pricing:pricingReducer,
    user:userReducer,
    dashboard: dashboardReducer,
    invite: inviteReducer,
    payment:paymentReducer,
    client:clientReducer,
    credit:creditReducer,
    answers:quizReducer

});

const middleWare = compose(applyMiddleware(thunk));

const store = createStore(reducer, middleWare);

export default store;