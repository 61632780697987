import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

// import "./Cheerbox.css";
import Wrapper from '../../wrapper/Wrapper';
import * as actionTypes from '../../../actions/cheerActions';

class CheerName extends Component {

  componentDidMount() {
    document.title = 'Cheerbox - Select Cheerbox Type';
  }

  render() {
    return (
      <Wrapper>
        <div className="header">
          <div className="container">
            <div className="row">
              <div className="col-md-3"></div>
              <div className="col-md-6 ">
                <div className="page-title">
                  <span>I want to send a Cheerbox to</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="form-box">
          <div className="container">
            <div className="row">
              <div className="col-md-2"></div>
              <div className="col-md-8">
                <form>
                  <div className="form-row">
                    <div className="col">
                      <input type="text" value={this.props.firstName} className="form-control" placeholder="First name" onChange={(e) => {
                        this.props.onSetName(e.target.value, this.props.lastName);
                      }} />
                    </div>
                    <div className="col">
                      <input type="text" value={this.props.lastName} className="form-control" placeholder="Last name"  onChange={(e) => {
                        this.props.onSetName(this.props.firstName, e.target.value);
                      }}  />
                    </div>
                  </div>
                </form>
              </div>
            <div className="col-md-2"></div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row button-row">
            <div className="col-md-4"></div>
            <div className="col-md-4">
              <div className="button-group">
                <Link to="/cheerbox/set-cheer-reason" className="button buttonGreen">Continue
    <div className="ripples buttonRipples"><span className="ripplesCircle"></span></div>
                </Link>
              </div>
            </div>
            <div className="col-md-4"></div>
          </div>
        </div>
      </Wrapper>
    );
  }
}
const mapStateToProps = state => {
  return {
    firstName: state.cheer.firstName,
    lastName: state.cheer.lastName,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onSetName: (firstName, lastName) => dispatch({type: actionTypes.SET_NAME, payload: {firstName, lastName}}),
  }
};
export default connect(mapStateToProps, mapDispatchToProps)(CheerName);
