import React from 'react';

const priceBox = (props) => {
    return (




        <div {...props} className={props.selected ? "price-box price-box-selected" : 'price-box '}>
            <div className="price-box-info">
                <span className="price-box-amount">
                    <span style={{fontSize:40}}>
                        {props.amount}
                    </span> <br/>
                <span className="price-cheerbox-label">Cheerboxes</span>


                </span>


            <div className="price-box-price" >
                <span className="cheerbox-title">
                    <span className="price-box-price">
                        {'$'+ props.price}/ea.

                    </span>
                            
                </span>
            </div>
            </div>

        </div>
    );
}

export default priceBox;