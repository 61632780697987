import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import Notification from './notifications/Notifications';
import Profile from './profile/Profile';
//import CheerboxLogo from '../../assets/img/mental-happy-logo.png';
import { connect } from 'react-redux'
import {getUser} from '../../actions/userActions'
class Header extends Component {

    componentDidMount(){
        this.props.getUsers()
    }

    render() {
        return (
            <header className="header">
                <nav className="navbar fixed-top">
                    <div className="search-box">
                        <button className="dismiss"><i className="ion-close-round"></i></button>
                        <form id="searchForm" action="#" role="search">
                            <input type="search" placeholder="Search something ..." className="form-control" />
                        </form>
                    </div>
                    <div className="navbar-holder d-flex align-items-center align-middle justify-content-between">
                        <div className="navbar-header">
                            <Link to="dashboard" className="navbar-brand">
                                <div className="brand-image brand-big">
                                    <img src="../../assets/img/mental-happy-logo.png" alt="logo" className="logo-big" />
                                </div>
                                <div className="brand-image brand-small">
                                    <img src="../../assets/img/mental-happy-logo.png" alt="logo" className="logo-small" />
                                </div>
                            </Link>
                           <a id="toggle-btn" href="javascript:void(0)" className="menu-btn active">
                                <span></span>
                                <span></span>
                                <span></span>
                            </a>
        

                          
                        </div>
                        <ul className="nav-menu list-unstyled d-flex flex-md-row align-items-md-center pull-right">
                            {/*<li className="nav-item d-flex align-items-center"><Link id="search" to="#"><i className="la la-search"></i></Link></li>
                            <Notification />*/}
                            <Profile avatar={this.props.avatar} />
                            {/*<li className="nav-item"><a href="#off-canvas" className="open-sidebar"><i className="la la-ellipsis-h"></i></a></li>*/}
                        </ul>
                    </div>
                </nav>
            </header>
        );
    }
}

const mapStateToProps = state =>{
    return{
        avatar:state.user.avatar,
        user:state.user.user
    }
}

const mapDispatchToProps = dispatch =>{
    return{
        getUsers:()=>dispatch(getUser())
    }
}

export default connect(mapStateToProps,mapDispatchToProps) (Header);
