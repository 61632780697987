import { CREATE_PAYMENT,GET_PAYMENT,REQUEST_ERROR,SET_TOKEN,PAYMENT_REQUEST, GET_CREDITS} from '../actions/types'

const initialState = {
    payment:[],
    errors:null,
    token:null,
    paymentstatus:false,
    fetching:false,
    credits:null,
    ispaiderror:null

}


export default function(state = initialState,action){

    switch(action.type){
        case CREATE_PAYMENT:
            return{
                ...state,
                payment:action.payload.results,
                errors:null,
                paymentstatus:true,
                fetching:false,
                ispaiderror:true
            }
        case SET_TOKEN:
            return{
                ...state,
                token:action.payload,
                errors:null
            }
        case GET_PAYMENT:
            return{
                ...state,
                payment:action.payload,
                errors:null,
            }            
        case REQUEST_ERROR:
            return{
                ...state,
                errors:true,
                fetching:false,
                ispaiderror:false

            }
        case PAYMENT_REQUEST:
            return{
                ...state,
                fetching:true
            } 
        case GET_CREDITS:
            return{
                ...state,
                credits:action.payload.results
            }
        default:
            return state;

    }

}