import React, { Component } from 'react';

import Wrapper from '../../../wrapper/Wrapper';

class Sales extends Component {
  render() {
    console.log("Rendering sales");
    return (
      <Wrapper>
        Sales page
      </Wrapper>
    );
  }
}

export default Sales;
