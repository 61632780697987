import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';


import Wrapper from '../../wrapper/Wrapper';
import Type from './type/Type';
import * as actionTypes from '../../../actions/cheerActions';
import CheerboxExamples from './CheerboxExamples';

class CheerboxType extends Component {
    
    componentDidMount() {
        document.title = 'MentalHappy - Select Cheerbox Type';
    }
    render() {
        return (
                <div>
                <div className="form-box">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8 animated fadeIn ">

                        <div className="page-title">

<span>What is this Cheerbox for?</span>

</div>
                                <div className="cheerbox-holder">
                                    <Type 
                                        image='/assets/img/losscheerbox2.png'
                                        selected={this.props.type === 0}
                                        onClick={() => this.props.onChangeCheers(0)}
                                        label='Loss'/>
                                    <Type 
                                        image='/assets/img/illnessinjury.png'
                                        selected={this.props.type === 1}
                                        onClick={() => this.props.onChangeCheers(1)}
                                        label='Illness/Injury'/>
                                    <Type 
                                        image='/assets/img/NewDadCheerbox.png'
                                        selected={this.props.type === 2}
                                        onClick={() => this.props.onChangeCheers(2)}
                                        label='New Parents'/>
                                    <Type 
                                        image='/assets/img/appreciationcheerbox.png'
                                        selected={this.props.type === 3}
                                        onClick={() => this.props.onChangeCheers(3)}
                                        label='Appreciations'/>      
                                    <Type 
                                        image='/assets/img/guyonboardingCheerbox.png'
                                        selected={this.props.type === 4}
                                        onClick={() => this.props.onChangeCheers(4)}
                                        label='Welness'/>    
                                    <Type 
                                        image='/assets/img/Onboardingcheerbox.png'
                                        selected={this.props.type === 5}
                                        onClick={() => this.props.onChangeCheers(5)}
                                        label='On-boarding'/>                                                                                                                      
                                </div>
                            </div>
                            <div className="col-md-4">
                    <div>
                        <div className="order_info_card">
                                <ul className="list-group">
                                <li className="list-group-item"><span className="order_info_card_question">Cheerbox Examples.</span><br/>
                                  
                                    <span className="order_info_card_answer"> <CheerboxExamples exampleContent="1" exampleTitle="Employee Appreciation"/></span>
                                    <span className="order_info_card_answer"> <CheerboxExamples exampleContent="2" exampleTitle="Illness/Injury - Him"/> </span>
                                    <span className="order_info_card_answer"> <CheerboxExamples exampleContent="7" exampleTitle="Illness/Injury - Her"/> </span>

                                    <span className="order_info_card_answer"> <CheerboxExamples exampleContent="3" exampleTitle="Loss Kit (Secular)"/>  </span>
                                    <span className="order_info_card_answer"> <CheerboxExamples exampleContent="9" exampleTitle="Loss Kit (Spiritual)"/>  </span>

                                    <span className="order_info_card_answer"> <CheerboxExamples exampleContent="5" exampleTitle="Healing Kit"/>   </span>
                                    <span className="order_info_card_answer"> <CheerboxExamples exampleContent="6" exampleTitle="New Mom"/> </span>
                                    <span className="order_info_card_answer"> <CheerboxExamples exampleContent="10" exampleTitle="New Dad"/> </span>


                                </li>

                                </ul>                        
                        
                        </div>

                        <div className="order_info_card" style={{marginTop:100}}>
                                <ul className="list-group">
                                <li className="list-group-item"><span className="order_info_card_question">Looking for Branded Options? </span><br/>
                                  
                                    <span className="order_info_card_answer">Branded Kits will come with your own logo, colors ...<br/><br/> </span>
                                    <span className="order_info_card_answer">Your dedicated account manager will work with you to determine what goes inside each Kit.</span>

                                    <span className="order_info_card_answer"> <CheerboxExamples exampleContent="8" exampleTitle="View Branded Options"/> </span>


                                </li>

                                </ul>                        
                        
                        </div>

                    </div>

                    
                    </div>

                        </div>
                    </div>
                </div>
                </div>

        );
    }
}

const mapStateToProps = state => {
    return {
        type: state.cheer.type
    };
};
const mapDispatchToProps = dispatch => {
    return {
        onChangeCheers: (type) => dispatch({type: actionTypes.SET_TYPE, payload: {type: type}}),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(CheerboxType);
