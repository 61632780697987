import React, { Component } from 'react';
import { Switch, Route ,Link,Redirect} from 'react-router-dom';

import { connect } from 'react-redux'

import Wrapper from '../../components/wrapper/Wrapper';
import CheerBoxLogo from '../../assets/img/cheerbox-logo.svg';
import {createUser } from '../../actions/userActions'
import Typography from '@material-ui/core/Typography';
import { withStyles} from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import TextField from '@material-ui/core/TextField';
import { Mixpanel } from '../../Mixpanel'
import { getPricing,setPricing } from '../../actions/pricingActions' 


const styles = theme => ({

  cssLabel: {
    '&$cssFocused': {
      color:"#1aa8bc",
    },
    '&:after': {
      borderBottomColor:"#1aa8bc",
    }  
  },
  cssFocused: {},
  cssUnderline: {
    '&:after': {
      borderBottomColor: "#1aa8bc",
    },
  },
  cssOutlinedInput: {
    '&$cssFocused $notchedOutline': {
      borderColor:  "#1aa8bc",
    },
  },

});

class Agreement extends Component {

    state ={
        firstName:'',
        lastName:'',
        email:'',
        password:'',
        agree:false,
        agreeclass:''
      }

      componentDidMount(){
        document.title="Mentalhappy | Register "
        Mixpanel.track('Register pageview')
        this.props.getPricing()


    }
        

    register = (e) => { 

        e.preventDefault();

        if(this.state.agree === true){
          this.props.create(this.state)
          this.setState({agreeclass:''})

        }else{
          this.setState({agreeclass:'check-agreement'})

        }
        



        //this.props.history.push('/verify-email')
      }

  
  handleChange = e=>{
    this.setState({ [e.target.name]: e.target.value })

  }

  agree = e =>{
    if(e.target.checked === true)
    Mixpanel.track('Agreement Checked')

    this.setState({ agree: e.target.checked })

   // this.setState({agree:true})
  }

  render() {
    this.props.setPricing(this.props.pricing[0])

    if(this.props.created === true){
        this.props.history.push('/payment')
    }



    const { classes } = this.props;

    return (
      <Wrapper>
        <div className="logo-section">
			<div className="container">
				<div className="row">
					<div className="col-md-4 head-group">
						<div className="mental-happy-logo">
              <img src="../../../assets/img/mental-happy-logo.png" alt="Mental happy logo" width='120'/>

						</div>
					</div>
				</div>
			</div>
		</div>
			<div className="header">
				<div className="container">
					<div className="row">
						<div className="col-md-4"></div>
						<div className="col-md-4 ">
                    <div className="page-title">

                    </div>
                    <Typography variant="caption">   
        </Typography>
						</div>
					</div>
				</div>
			</div>
			<div className="form-box">
				<div className="container">
					<div className="row">
                    <div className="col-md-12  animated fadeIn">

                    <div className="col-md-4 pull-left">
                    <div className="page-title">
                            <span style={{textAlign:'center',fontSize:'1.5em'}}>Select your MentalHappy Kits and delivery dates from your dashboard. </span>
                            

                    </div>   
                    <div className="em-separator separator-dashed"></div>                 
                    <span style={{textAlign:'center',fontWeight:700,width:'100%'}}>Create Your Account.</span>

			<form className='auth-form' onSubmit={this.register} style={{marginTop:0}}>

      <TextField
          label="First Name"
          margin="normal"
          className="auth-input "
          name="first"

          onChange={event => this.handleChange(event)} 
          
          InputLabelProps={{
            classes:{
              root: classes.cssLabel,
              focused: classes.cssFocused,
              underline: classes.cssOutlinedInput
            }
          }}
        
        />


<TextField
          label="Last Name"
          margin="normal"
          className="auth-input "
          name="last"

          onChange={event => this.handleChange(event)} 
          
          InputLabelProps={{
            classes:{
              root: classes.cssLabel,
              focused: classes.cssFocused,
              underline: classes.cssOutlinedInput
            }
          }}
        
        />


      <TextField
          label="Email"
          margin="normal"
          className="auth-input "
          name="email"

          onChange={event => this.handleChange(event)} 
          
          InputLabelProps={{
            classes:{
              root: classes.cssLabel,
              focused: classes.cssFocused,
              underline: classes.cssOutlinedInput
            }
          }}
        
        />

      <TextField
          label="Create Password"
          margin="normal"
          className="auth-input "
          name="password"

          onChange={event => this.handleChange(event)} 
          type="password"
          InputLabelProps={{
            classes:{
              root: classes.cssLabel,
              focused: classes.cssFocused,
              underline: classes.cssOutlinedInput
            }
          }}
        
        />

            <div className="button-group">
            <button type="submit" className="button buttonGreen">Create Account
              <div className="ripples buttonRipples"><span className="ripplesCircle"></span></div>
            </button>

            <div className={"col-md-12 "+ this.state.agreeclass }>
                        <div className="styled-checkbox">
                            <input type="checkbox" name="checkbox" id="check-1" onChange={this.agree} />
                            <label htmlFor="check-1">I agree with the <a href="https://landing.mentalhappy.com/corporate-user-service-agreement" target="_blank" component="a" >Terms of Service</a> </label>
                        </div>
                    </div>            
            </div>

            {this.props.error >0 && (          <div>
  <div className="alert alert-danger" role="alert">
    <strong>Oops!</strong> Please try again.
  </div>
</div>)}
</form>
<div className="bottom-links">
<p className="reset-password-link">  Forgot Password? <Link to="reset-password">Reset</Link></p>

<p>Already have an account? <Link to='/login'>Login</Link></p>
</div>


                    </div>

                    
                    <div className="col-md-7 pull-right">

                        <img src="/assets/img/dashboard_look02.png" className="img-responsive" style={{width:800}} />

                    </div>

                    


                    </div>

                    </div>
					</div>
			</div>

            {/*<div className="container">
                    <div className="row button-row">
                        <div className="col-md-4"></div>
                        <div className="col-md-4">
                            <div className="button-group">
                                <Link to="/cheerbox/set-recipient-name" className="button buttonGreen">Go to Dashboard
                                    <div className="ripples buttonRipples"><span className="ripplesCircle"></span></div>
                                </Link>
                            </div>
                        </div>
                        <div className="col-md-4"></div>
                    </div>
                </div>
    */}

			<div className="footer">
				<div className="container">
					<div className="row">
					<div className="col-md-4"></div>
						<div className="col-md-4">

						</div>
						
					</div>
				</div>
			</div>
      	</Wrapper>
    );
  }
}


const mapStateToProps = state =>{
    return{
      user:state.user.user,
      error:state.user.errors,
      created:state.user.created,
      pricing:state.pricing.pricing,

    }
  }
  
  const mapDispatchToProps = dispatch =>{
    return{
      create:(data)=>dispatch(createUser(data)),
      getPricing: ()=>dispatch(getPricing()),
      setPricing:(data)=>dispatch(setPricing(data))

    }
  }
  export default connect(mapStateToProps,mapDispatchToProps) (withStyles(styles)(Agreement));
