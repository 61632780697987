import React, { Component } from 'react';
import { Switch, Route ,Link} from 'react-router-dom';

import { connect } from 'react-redux'

import Wrapper from '../../components/wrapper/Wrapper';
import CheerBoxLogo from '../../assets/img/cheerbox-logo.svg';
import PricingCards  from './PricingCards'
import { getPricing,setPricing } from '../../actions/pricingActions'
import PricingFeature from './PricingFeatures'
import './Pricing.css'
import { Typography } from '@material-ui/core';
import FAQ from '../../components/dashboard/account/faq';
import { Mixpanel } from '../../Mixpanel'

class Pricing extends Component {

    componentDidMount(){
        document.title="Cheerbox | Pricing "

        this.props.getPricing()
        Mixpanel.track('Pricing pageview')
    }

    handleMore =()=>{
        window.location.href='https://mentalhappy.com/contact'
    }

    handleRegister = ()=>{

        var ispriceempty = !Object.keys(this.props.setprice).length

        if(ispriceempty !== true){
            Mixpanel.track('Register pageview')
            
            this.props.history.push('register')
        }

    }


  render() {




    return (
      <Wrapper>
        <div className="logo-section">
			<div className="container">
				<div className="row">
					<div className="col-md-4 head-group">
						<div className="mental-happy-logo">
							<img src={CheerBoxLogo} alt="Mental happy logo" width='120'/>
						</div>
					</div>
				</div>
			</div>
		</div>
			<div className="header">
				<div className="container">
					<div className="row">
						<div className="col-md-4"></div>
						<div className="col-md-4 ">
                    <div className="page-title">
                            <span>Select Your Cheerbox Size</span>

                    </div>
                    <Typography variant="caption" style={{textAlign:'center',fontSize:16}}>Save money with volume. Send your individual Mentalhappy Kits whenever you want.
                    <br/>                    <span>* 1 Credit = 1 Cheerbox | Shipping included.</span>

                    </Typography>

						</div>
					</div>
				</div>
			</div>
			<div className="form-box">
            
				<div className="container">
					<div className="row">
                    <div className="col-lg-12 col-xl-12 col-sm-12" >
      <div className="col-xl-4 col-lg-4  col-md-6 col-sm-12 has-shadow " style={{float:'left',height:150,padding:10}}>
<h3 style={{textAlign:'center',color:'#19a8bb'}}>Not A Subscription</h3>   

<p style={{fontSize:16,textAlign:'center'}}>We don't autobill you. Once your Kit credits run out you decide if you want to purchase more.</p>

</div>

<div className="col-xl-4 col-lg-4   col-md-6 col-sm-12 has-shadow" style={{float:'left',height:150,padding:10}}>
<h3  style={{textAlign:'center',color:'#19a8bb'}}>Credits Expiration</h3>   

<p style={{fontSize:16,textAlign:'center'}}>Your Cheerbox credits expire after one year if you are not actively using it. If you are then they rollover</p>

</div>

<div className="col-xl-4  col-lg-4  col-md-6 col-sm-12 has-shadow" style={{float:'left',height:150,padding:10}}>
<h3  style={{textAlign:'center',color:'#19a8bb'}}>We Handle Shipping</h3>   

<p style={{fontSize:16,textAlign:'center'}}>Shipping is included in each Cheerbox you send. You don't have to pay additional fees for shipping. </p>

</div>
</div> 
                                <div className='pricing-holder animated fadeIn'>
                        <PricingCards prices={this.props.pricing}  style={{cursor:'pointer'}}></PricingCards>
                        



                        <div amount="48" price="59" className="price-box " onClick={this.handleMore}>
                        <div className="price-box-info"><span className="price-box-amount"><span style={{fontSize: 40}}>More!</span> <br/>
                        <span className="price-cheerbox-label">Cheerboxes</span></span><div className="price-box-price"><span className="cheerbox-title"><span className="price-box-price">contact us.</span></span></div></div>
                        </div>
                        </div>

                    </div>
					</div>
			</div>

            <div className="container">
                    <div className="row button-row">
                        <div className="col-md-4"></div>
                        <div className="col-md-4">
                            <div className="button-group">
                                <button onClick={this.handleRegister} className="button buttonGreen">Continue
                                    <div className="ripples buttonRipples"><span className="ripplesCircle"></span></div>
                                </button>
                            </div>
                        </div>
                        <div className="col-md-4"></div>
                    </div>


                    
                </div>

			<div className="footer">
				<div className="container">
					<div className="row">
					<div className="col-md-4">

                    </div>
						<div className="col-md-4">
						</div>
						
					</div>
                    <div style={{marginTop:200}}>
                    <h2 style={{textAlign:'center'}}>Frequently Asked Questions</h2>
                <FAQ/>
                </div>
				</div>


			</div>
      	</Wrapper>
    );
  }
}

const mapStatesToProps = state =>{
    return{
        pricing:state.pricing.pricing,
        setprice:state.pricing.setprice
    }
}

const mapDispatchToProps = dispatch =>{
    return{
        getPricing: ()=>dispatch(getPricing()),
        setPricing:(data)=>dispatch(setPricing(data))
        
    }
}
export default connect(mapStatesToProps,mapDispatchToProps) (Pricing);
