import React from 'react' 
import { connect }  from 'react-redux'
import Wrapper from '../../components/wrapper/Wrapper';
import CheerBoxLogo from '../../assets/img/cheerbox-logo.svg';
import Button from "@material-ui/core/Button"
import { getUser } from '../../actions/userActions'
import {Link} from 'react-router-dom'


class PaymentSuccess extends React.Component{

    componentDidMount(){
        document.title="Cheerbox | Payment Successful "

        this.props.getUser()
    }

    render(){
        return(
            <Wrapper>
        <div className="logo-section">
			<div className="container">
				<div className="row">
					<div className="col-md-4 head-group">
						<div className="mental-happy-logo">
              <img src="../../../assets/img/mental-happy-logo.png" alt="Mental happy logo" width='120'/>

						</div>
					</div>
				</div>
			</div>
		</div>
            <div>
                        <div className="container-fluid h-100 overflow-y">
            <div className="row flex-row h-100">
                <div className="col-12 my-auto">
                    <div className="mail-confirm mx-auto" style={{boxShadow:'none',background:'inherit'}}>
                        <div className="animated-icon" style={{background:'#0e8696'}}>
                            <div className="icon"><i className="la la-check"></i></div>
                        </div>
                        <h3>Thank you for your purchase.</h3>
                        <p>
                            A confirmation email has been sent to you.
                        </p>
                      
                        <div class="button text-center" style={{display:'block',margin:'0 auto'}}>
                        <Link className="button buttonGreen" to="/referrer"> Send a Kit
    <div className="ripples buttonRipples"><span className="ripplesCircle"></span></div>
  </Link>
                        </div>
  
                    </div>        
                </div>
            </div>
        </div> 

            </div>
            </Wrapper>

        )
    }
}

const mapStatesToProps = state =>{
    return{
        user:state.user.user.user,

    }
}

const mapDispatchToProps = dispatch =>{
    return{
        getUser: ()=>dispatch(getUser()),

        
    }
}
export default connect(mapStatesToProps,mapDispatchToProps) (PaymentSuccess);

