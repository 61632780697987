import React from 'react'
import {Link} from 'react-router-dom'
import { Mixpanel } from '../../../Mixpanel'



const intro=[
    {question:"What is MentalHappy?",answer:' We help employers send tangible tools and resources to employees when they experience a significant life event; injury, illness, loss, burnout, and other emotional traumas with our MentalHappy Kit product.  '},
 
    {question:"Can I use MentalHappy Kit for Clients/Customers? ",answer:`Yes
    `},
    {question:"Do you offer branding options?",answer:`Yes, there is a minimum order and setup fee required.
    `},
    {question:"Am I able to track employee or client engagement? ",answer:`Yes, we offer a full dashboard once you sign-up.  `},
    {question:"What is the size of the MentalHappy Kit?",answer:`It is 9x7x4. Larger box sizes are available upon request for orders of 24 or more. `},

    {question:"What’s inside the MentalHappy Kit?",answer:`      Inside each MentalHappy Kit are “happiness tools” to encourage healing, laughter, gratitude, or a motivational boost. Happiness tools are wellness focused products; games, motivational books, healing guides, delicious superfoods,stress relief products and a handwritten note. See MentalHappy Kit examples on home page. 
    `},
    {question:"What is the turnaround time on an order?  ",answer:`      One to two day turnaround time. For larger projects or bulk orders of 50 or more it is two to three weeks. Expedited bulk order is an option contact support@MentalHappy.com or your dedicated account manager for more details.
    `},
    {question:" Can I pick what items go in the box specifically?  ",answer:`Yes. Contact your dedicated curator for assistance.
    `},    
    {question:" Can I order just One MentalHappy Kit?  ",answer:`The minimum first order size is 12. `},    

    {question:"Can I send an item to add to the MentalHappy Kit?",answer:`Yes, if the items are related to a bulk order/project. All items (or branded items) are subject to approval. We reserve the right to refuse any item that does not meet our brand standards.
    `},
    {question:"Do you store items for me that I can have you send out?",answer:`Yes, but subject to approval. `}    

]

const recipients =[

    {question:"How long will it take for recipient to receive the Cheerbox? ",answer:`Because each Cheerbox is customized to order, please allow up to 1-3 business days from time of order to delivery.
    `},
    {question:"Will the recipient know who is sending the Cheerbox?",answer:'Yes'},
    {question:"Will the information (recipients details) I provide on order be shared with the recipient? ",answer:`All recipient details will never be shared or shown to the recipient for any reason.`}
]

const pricing =[


    {question:"How much does a MentalHappy Kit cost?",answer:`The price of MentalHappy Kits vary based on employee population size, branding requests and order volume. Contact us to Talk to Sales  <a href='https://app.mentalhappy.com/#/pricing'>View Packages Options</a>,`},
    {question:"Do you guys offer volume pricing?",answer:`Yes.
    `}

]

const shipping =[

    {question:"Can I have the MentalHappy Kit rushed or shipped next day? ",answer:`      Yes, if next day shipping is included on your account. MentalHappy Kits are received within one to three days of purchase including Saturdays. 
    `},
    {question:"Do you ship internationally?",answer:' We ship internationally using DHL to 220 Countries.  '},


]

const credits =[
    {question:"What are Credits? ",answer:`MentalHappy Kit credits are prepaid MentalHappy Kits. We do not currently support one time purchases. Think of credits as a savings on buying MentalHappy Kits in bulk. 1 Credit = 1 MH Kit.`},
    {question:"Do credits expire?",answer:`Credits expire 365 days from purchase.
    `},
    {question:'Do credits rollover?', answer:`MentalHappy Kit credits rollover for companies who are actively sending MentalHappy Kits. `},
    {question: "Can I add more credits as I need?", answer:" Yes, you can add credits to your account whenever you want. To add more credits to your account go to the <a href='/#/dashboard/credits'>Add Credits</a> page."},
    {question: "Can anyone in my organization use credits I purchase? ", answer:`You can authorize other people in your organization to use your credits. You can also set budget and user restrictions.  `},
    {question: "Are credits redeemable for cash? ", answer: `Credits cannot be redeemed for cash or any other monetary value. 
    `},

]



class FAQ extends React.Component{


    componentDidMount(){
        Mixpanel.track('FAQ Pageview')
    }

    render(){

    const IntroFaq = intro.map((info,index)=>{
        return(
            <div key={index}>
                <a className="card-header collapsed d-flex align-items-center" data-toggle="collapse" href={"#collapse"+index}>
                    <div className="card-title">{info.question} </div>
                </a>
                <div id={"collapse"+index} className="card-body bg-grey collapse" data-parent="#accordion">
                    <p>
                    {info.answer}
                    </p>
                </div>                
            </div>
        )
    })
    const RecipientsFaq = recipients.map((info,index)=>{
        index=index+10

        return(
            <div key={index}>
                <a className="card-header collapsed d-flex align-items-center" data-toggle="collapse" href={"#collapse"+index}>
                    <div className="card-title">{info.question} </div>
                </a>
                <div id={"collapse"+index} className="card-body bg-grey collapse" data-parent="#accordion">
                    <p>
                    {info.answer}
                    </p>
                </div>                
            </div>
        )
    })


    const PricingFaq = pricing.map((info,index)=>{
        index=index+20

        return(
            <div key={index}>
                <a className="card-header collapsed d-flex align-items-center" data-toggle="collapse" href={"#collapse"+index}>
                    <div className="card-title">{info.question} </div>
                </a>
                <div id={"collapse"+index} className="card-body bg-grey collapse" data-parent="#accordion">
                    <p>
                    <p dangerouslySetInnerHTML={{__html:info.answer}}></p>
                    </p>
                </div>                
            </div>
        )
    })

    const ShippingFaq = shipping.map((info,index)=>{
        index=index+30

        return(
            <div key={index}>
                <a className="card-header collapsed d-flex align-items-center" data-toggle="collapse" href={"#collapse"+index}>
                    <div className="card-title">{info.question} </div>
                </a>
                <div id={"collapse"+index} className="card-body bg-grey collapse" data-parent="#accordion">
                    <p>
                    {info.answer}
                    </p>
                    
                </div>                
            </div>
        )
    })

    const CreditsFaq = credits.map((info,index)=>{
        index=index+40

        return(
            <div key={index}>
                <a className="card-header collapsed d-flex align-items-center" data-toggle="collapse" href={"#collapse"+index}>
                    <div className="card-title">{info.question} </div>
                </a>
                <div id={"collapse"+index} className="card-body bg-grey collapse" data-parent="#accordion">
                    <p>
                    {info.answer}
                    </p>
                </div>                
            </div>
        )
    })

        return(
            <div>
                                        <div className="col-xl-12">
                                            <div className="widget">
                                                <div className="widget-body pt-2">
                                                    <div id="accordion" className="accordion">
                                                        <div className="widget">
                                                            <div className="widget-header no-actions d-flex align-items-center">
                                                                <h2>Introduction</h2>
                                                            </div>
                                                            
                                                            {IntroFaq}
                                                        </div>
                                                        <div className="widget">
                                                            <div className="widget-header no-actions d-flex align-items-center">
                                                                <h2>Pricing </h2>
                                                            </div>
                                                            {PricingFaq}

                                                        </div>
                                                        <div className="widget">
                                                            <div className="widget-header no-actions d-flex align-items-center">
                                                                <h2>Shipping</h2>
                                                            </div>
                                                            {ShippingFaq}

                                                        </div>
                                                        <div className="widget">
                                                            <div className="widget-header no-actions d-flex align-items-center">
                                                                <h2>Credits</h2>
                                                            </div>
                                                            {CreditsFaq}

                                                        </div>                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


            </div>
        )
    }
}

export default FAQ