import React, { Component } from 'react';
import PieChart from '../chart/pie/PieChart';

class CheerboxSentChartWidget extends Component {
  render() {
    return (
      
        
        <div className=" col-md-6 col-sm-6">
        <div className="widget widget-12 has-shadow">
          <div className="widget-body pie-chart-widget-box"  style={{minHeight:299}}>
       
          <PieChart recipients={this.props.recipients} />
            
          </div>
        </div>
      </div>

     
    );
  }
}

export default CheerboxSentChartWidget;
