import React, { Component } from 'react';

import Wrapper from '../../wrapper/Wrapper';
import { connect } from 'react-redux'
import {inviteUser} from '../../../actions/inviteAction'
import CircularProgress from '@material-ui/core/CircularProgress';
import { Mixpanel } from '../../../Mixpanel'

class Invite extends Component {


    state ={
        email:null,
        credits:null,
        invoice:null,
        name:null
    }

    componentDidMount(){
        document.title="MentalHappy | Invite Team Members "
        Mixpanel.track('Invite Pageview')

    }

    handleChange = e=>{

        if(e.target.name === 'credits' && this.state.credits ==='on'){
            this.setState({ [e.target.name]: null})
        }

        if(e.target.name === 'credits' && this.state.credits !=='on'){
            this.setState({ [e.target.name]:e.target.value })
        }

        if(e.target.name === 'invoice' && this.state.invoice ==='on'){
            this.setState({ [e.target.name]: null})
        }

        if(e.target.name === 'invoice' && this.state.invoice !=='on'){
            this.setState({ [e.target.name]:e.target.value })
        }

        if(e.target.name === 'email'){
            this.setState({ [e.target.name]:e.target.value })
        }
        if(e.target.name === 'name'){
            this.setState({ [e.target.name]:e.target.value })
        }



  
      }

      handleSave = ()=>{
        var credits,invoice;
        if(this.state.credits ===null){
             credits = ''
        }else{
             credits = 'credits'
        }

        if(this.state.invoice ===null){
             invoice = ''
        }else{
             invoice = 'invoice'
        }

        let data ={
            name:this.state.name,
            email:this.state.email,
            permissions:credits+'-'+invoice
        }


        Mixpanel.track('Invited Member')


        this.props.createInvite(data)
        
      }


  render() {
    return (
      <Wrapper>
                    <div class="container-fluid">
                        <div class="row">
                            <div class="page-header">
	                            <div class="d-flex align-items-center">
	                                <h2 class="page-header-title">Invite Members</h2>
                                    <div>
                                        <ul class="breadcrumb">
                                            <li class="breadcrumb-item"><a href="db-all.html"><i class="ti ti-home"></i></a></li>
                                            <li class="breadcrumb-item active">Invite Members</li>
                                        </ul>
                                    </div>	                            
                                </div>
                            </div>
                        </div>



                        <div class="row flex-row">
                            <div class="col-xl-12 col-12">
                                <div class="widget has-shadow">
                                    <div class="widget-body">
                                    <div class="form-group row d-flex align-items-top mb-5">
                                                <label class="col-lg-3 form-control-label">Invite Member</label>
                                                <div class="col-lg-9">
                                                <div class="form-group">
                                                        <div class="input-group">
                                                            <span class="input-group-addon addon-primary" style={{background:'#1bb1c3'}}>
                                                                <i class="la la-user"></i>
                                                            </span>
                                                            <input type="text" name="name" onChange={this.handleChange} placeholder="Name" class="form-control" style={{marginTop:0}} />
                                                        </div>
                                                    </div>

                                                    <div class="form-group">
                                                        <div class="input-group">
                                                            <span class="input-group-addon addon-primary" style={{background:'#1bb1c3'}}>
                                                                <i class="la la-envelope"></i>
                                                            </span>
                                                            <input type="text" name="email" onChange={this.handleChange} placeholder="Email Address" class="form-control" style={{marginTop:0}} />
                                                        </div>
                                                    </div>
                                                    <div className="mb-3">
                                                        <div className="styled-checkbox">
                                                            <input type="checkbox" name="credits" id="check-1" onChange={this.handleChange}/>
                                                            <label for="check-1">Can use credits</label>
                                                        </div>
                                                    </div>
                                                    <div className="mb-3">
                                                        <div className="styled-checkbox">
                                                            <input type="checkbox" onChange={this.handleChange} name="invoice" id="check-2" />
                                                            <label for="check-2">Can invoice</label>
                                                        </div>
                                                    </div>
                                                </div>

                                    </div>

                                    {this.props.invite_status===true ?(
<div>
  <div className="alert alert-outline-success" role="alert">
                                  <strong>Yay!</strong> Your invite has been sent. We've also sent you a copy.
                              </div>

</div>):null}

                                    <div className="em-separator separator-dashed"></div>

                                    {this.props.fetching===true ?<CircularProgress style={{display:'block',margin:'0 auto'}} />:(
                                                                        <div className="button text-center" style={{display:'block',margin:'0 auto'}}>
                                                                        <button type="submit" className="button buttonGreen"  onClick={this.handleSave}>
                                                                        Send Invite
                                                    <div className="ripples buttonRipples"><span className="ripplesCircle"></span></div>
                                                  </button>
                                                                        </div>)} 

                                        
                                    </div>

                                </div>
                            </div>
                        </div>

                        </div>
     </Wrapper>
    );
  }
}

const mapStateToProps = state =>{
    return{
        invite_status:state.invite.invite_status,
        fetching:state.invite.fetching
    }
}

const mapDispatchToProps = dispatch =>{
    return{
        createInvite:(data)=>dispatch(inviteUser(data))
    }
}

export default connect(mapStateToProps,mapDispatchToProps) (Invite);
