import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';

import Home from '../Home';
import Cheers from '../cheers/Cheers';
import Sales from '../sales/Sales';
class Dash extends Component {
  render() {
    // console.log("Rendering dash");
    return (
      <Switch>
        <Route path='/dashboard/cheers' component={Cheers} />
        <Route path='/dashboard/sales' component={Sales} />
        <Route path='/dashboard' component={Home} />
        <Route path='/' component={Home} />
      </Switch>
    );
  }
}

export default Dash;
