import React, { Component } from 'react';
import {Link} from 'react-router-dom'

import Avatar1 from '../../../assets/img/avatar/avatar-01.jpg';
class Profile extends Component {

  state={
    open:false,
    expandclass:'close-userdropdown',
  }
  handleOpen =e=>{
    if(this.node.contains(e.target)){
      if(this.state.open === false){
        this.setState({open:true,expandclass:'open-userdropdown'})
  
      }else if(this.state.open === true){
        this.setState({open:false,expandclass:'close-userdropdown'})
  
      }
    }else{
      console.log("outside")
    }


  }
  render() {
    let avatar;
    if(this.props.avatar === null){
       avatar = '/assets/img/avatar.png'
    }else{
       avatar = this.props.avatar
    }


    return (
      <li className="nav-item dropdown" ref={node => { this.node = node; }}><a    onClick={this.handleOpen} id="user"  className="nav-link">
       <img src={avatar} alt="..." className="avatar rounded-circle" /></a>
        <ul aria-labelledby="user" className={"user-size dropdown-menu " + this.state.expandclass }>
          <li className="welcome">
            <Link to="/dashboard/settings"  onClick={this.handleOpen}  className="edit-profil"><i className="la la-gear"></i></Link>
            <img src={avatar} alt="..." className="rounded-circle" />
          </li>
          <li>
            <Link to="/dashboard/settings"   onClick={this.handleOpen}   className="dropdown-item no-padding-bottom">
              Settings
                                </Link>
          </li>
          <li className="separator"></li>
          <li>
            <Link to="/dashboard/settings/faq" href="pages-faq.html"   onClick={this.handleOpen}  className="dropdown-item no-padding-top">
              Faq
                                </Link>
          </li>



        </ul>
      </li>

    );
  }
}

export default Profile;
