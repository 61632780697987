import  { CREDIT_REQUEST,GET_CREDIT,CREDIT_ERROR} from '../actions/types'

const initialState = {
    credits:{},
    error:null


}


export default function(state = initialState,action){

    switch(action.type){
        case GET_CREDIT:
            return{
                ...state,
                credits:action.payload,
                errors:null,
            }
        case CREDIT_REQUEST:
            return{
                ...state,
                errors:null,
            }            
        case CREDIT_ERROR:
            return{
                ...state,
                errors:true
            }
        default:
            return state;

    }

}