import React, {Component} from 'react';
import { Pie } from 'react-chartjs-2';

import { connect } from 'react-redux';
import { Link } from 'react-router-dom'
import * as actionTypes from '../../../actions/dashboardActions';
class PieChart extends Component {

	state = {
		theData: [],
		theLabels: [],
	}

	componentDidMount() {
		this.prepareData(this.props.recipients);
	}

	componentDidUpdate(ownprops){

		if(this.props.recipients.length !== ownprops.recipients.length)
		this.prepareData(this.props.recipients);

	}

    render() {
        let chartData = {
			type: 'pie',
			data: {
				datasets: [{
					data: this.state.theData,
					backgroundColor:['#08a6c3', '#dee2e6', '#3b5998']

				}],
			
				labels: this.state.theLabels
			},
			options: {
				responsive: true,
				// width: '200px',
				// height: '200px',
			
				legend: {
					display: true,
					position: 'bottom',
					labels: {
						// fontColor: "#2e3451",
						fontColor: 'red',
						usePointStyle: true,
						padding: 10,
						fontSize: 9
					}
				},
			}
		};
        // let myChart = new Chart(ctx, data);
		
		if(this.props.recipients.length === 0){
			return (
				
				<Link className="button buttonGreen" to="/dashboard/order" style={{margin:'0 auto',width:207,marginTop:50,display:'block'}}>Send Cheerbox Now</Link>

			)
		}
        return (
            <Pie {...chartData} />
        )
	}
	
	prepareData = (data) => {
		const dataset = {};
		console.log(data)

		data.forEach(item => {
			dataset[item.preference] = dataset[item.preference] ? dataset[item.preference]+1 : 1
		});
		
		const theData = [];
		const theLabels = [];

		Object.keys(dataset).forEach(item => {
			theLabels.push(item);
			theData.push(dataset[item]);
		})
		console.log(theLabels)
		this.setState({
			theData, theLabels
		})
	}

}


const mapStateToProps = state => {
    return {
        recipients: state.dashboard.orders,
        
    };
  };
  const mapDispatchToProps = dispatch => {
    return {
      onDateChanged: (date, type) => dispatch(actionTypes.filterDate(date, type)),
    }
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(PieChart);
