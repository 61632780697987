import React, { Component } from 'react'
import './react-datepicker.css';
import './datepicker.css';
import DatePicker from 'react-datepicker';

class DateSelector extends Component {

    state = {
        show: false,
    }

    render() {
        return (
            <div >
                <i 
                    className="la la-calendar date-selector-icon"
                    onClick={() => this.setState({
                        show: !this.state.show,
                    })}
                ></i>
                {
                    this.state.show && (
                        <DatePicker
                            selected={this.props.startDate}
                            onChange={this.onDateSelected}
                            inline 
                            />
                    )
                }
            </div>
        );
    }

    onDateSelected = (date) => {
        this.setState({
            show: false
        })
        date = new Date(date);
        console.log("Date selected: ", date);
        if (this.props.handleChange) {
            this.props.handleChange(date);
        }
    } 
}


export default DateSelector;