import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

// import "./Cheerbox.css";
import Wrapper from '../../wrapper/Wrapper';
import * as actionTypes from '../../../actions/cheerActions';

class CheerNote extends Component {
    componentDidMount() {
        document.title = 'Cheerbox - Add Cheerbox Note';
    }
    render() {
        return (
            <Wrapper>
                <div className="header">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-3"></div>
                            <div className="col-md-6 ">
                                <div className="page-title">
                                    <span>What personalized note do you want included?</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="form-box">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-2"></div>
                            <div className="col-md-8">
                                <form>
                                    <div className="form-row">
                                        <div className="col-2"></div>
                                        <div className="col-8">
                                            <div className="form-group">
                                                <textarea value={this.props.note} className="form-control" id="exampleFormControlTextarea1" rows="10" placeholder="Give us more details...."
                                                onChange={e => {
                                                    this.props.onSetNote(e.target.value, this.props.expertNote);
                                                }}></textarea>
                                            </div>
                                            <div className="form-group form-check">
                                                <input type="checkbox" className="form-check-input" id="exampleCheck1" value={this.props.expertNote} checked={this.props.expertNote} onChange={e => {
                                                    this.props.onSetNote(this.props.note, e.target.checked);
                                                }} />
                                                <label className="form-check-label" htmlFor="exampleCheck1">Let our happiness experts write a well-crafted note on your behalf</label>
                                            </div>
                                        </div>
                                        <div className="col-2"></div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="col-md-2"></div>
                    </div>
                </div>
                <div className="container">
                    <div className="row button-row">
                        <div className="col-md-4"></div>
                        <div className="col-md-4">
                            <div className="button-group">
                                <Link to="/cheerbox/set-relationship" className="button buttonGreen">Continue
    <div className="ripples buttonRipples"><span className="ripplesCircle"></span></div>
                                </Link>
                            </div>
                        </div>
                        <div className="col-md-4"></div>
                    </div>
                </div>
            </Wrapper>
        );
    }
}
const mapStateToProps = state => {
    return {
      note: state.cheer.note,
      expertNote: state.cheer.expertNote,
    };
  };
  const mapDispatchToProps = dispatch => {
    return {
      onSetNote: (note, expertNote) => dispatch({type: actionTypes.SET_NOTE, payload: {note, expertNote}}),
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(CheerNote);
