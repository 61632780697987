import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import Wrapper from '../../../components/wrapper/Wrapper';
import {passwordReset,resetPassword } from '../../../actions/authActions'


import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';

import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Mixpanel } from '../../../Mixpanel'


const styles = theme => ({
  layout: {
    width: 'auto',
    display: 'block', // Fix IE11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing.unit * 8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
    background:'inherit',
    boxShadow:'none'
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE11 issue.
    marginTop: theme.spacing.unit,
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
  },
});

class ResetPassword extends Component {


  constructor(props){
    super(props);

    this.state ={
      email:'',
      status:false,
      code:''
    }
  }

  handleSubmit = e=>{

    e.preventDefault();

    const user = {
      email: this.state.email
        };
        this.setState({ status: true })

        Mixpanel.track("Password Reset Sent")
    this.props.passwordReset(user);





  }
    
  handleChange = e=>{

    this.setState({ [e.target.name]: e.target.value })

  }


  handleReset = e=>{
    e.preventDefault();

    const reset_code = {
      code: this.state.code
        };
        Mixpanel.track("Password Reset Code Entered")

    this.props.resetPassword(reset_code);

  }



  render() {
    if(this.props.reset === true){
      this.props.history.push('new-password')
    }
    const { classes } = this.props;
    const { resetERROR } = this.props
    const { postERROR } = this.props

    //this.props.reset ? this.props.history.push('/home'): null 

    if(this.props.resetERROR === true || this.props.resetERROR === null){


    return (

        <main className={classes.layout}>
                <CssBaseline />

        <div style={{padding:'20px',display:'block',width:'100%',textAlign:'center'}}>

            </div>
          <Paper className={classes.paper}>
            <form className='auth-form'>
                <div className="group">
                  <input className="auth-input" type="email"  name="email"   onChange={event => this.handleChange(event)} /><span className="highlight"></span><span className="bar"></span>
                  <label className="auth-label">Email</label>
                </div>
                { this.props.fetching ? (<div style={{textAlign:'center'}}><CircularProgress  /></div>) :null}

                <div className="button-group">
                <button type="button" className="button buttonGreen" onClick={this.handleSubmit} >Reset Password
                  <div className="ripples buttonRipples"><span className="ripplesCircle"></span></div>
                </button>
                </div>
          </form>


            

            { resetERROR ===true ? <span>Please try again</span>:null}

            <Link style={{padding:20,textDecoration:'none'}} to='login'>Sign In</Link>
          </Paper>
        </main>
    );
  }else if(!this.props.reset || this.props.resetERROR === false){

    return(




        <main className={classes.layout}>
                <CssBaseline />

        <div style={{padding:'20px',display:'block',width:'100%',textAlign:'center'}}>

            </div>
          <Paper className={classes.paper}>
            <Typography variant="title">We've emailed you a reset code.</Typography>
            <Typography variant="caption">Enter that code below</Typography>

            <form className={classes.form} >
              <FormControl margin="normal" required fullWidth>
                <InputLabel htmlFor="code">Reset Code</InputLabel>
                <Input id="code" name="code" type='number' onChange={event => this.handleChange(event)}/>
              </FormControl>
              { this.props.fetching ? (<div style={{textAlign:'center'}}><CircularProgress  /></div>) :null}

              <Button
                type="submit"
                fullWidth
                variant="raised"
                className="buttonGreen"
                onClick={this.handleReset}
              >
               Continue
              </Button>
            </form>
            

            { this.props.reset===false ? <span>Please try again </span>:null}

            <Link style={{padding:20,textDecoration:'none'}} to='/signin'>Sign In</Link>
          </Paper>
        </main>


    )



  }



    return (
      <Wrapper>
			<form className='auth-form'>
  <div className="group">
    <input className="auth-input" type="email"/><span className="highlight"></span><span className="bar"></span>
    <label className="auth-label">Email</label>
  </div>
  
  <div className="button-group">
  <button type="button" className="button buttonGreen">Reset Password
    <div className="ripples buttonRipples"><span className="ripplesCircle"></span></div>
  </button>
  </div>
</form>
<div className="bottom-links">
<p>Remembered your password? <Link to="/login">Login</Link></p>
</div>
      </Wrapper>
    );
  }
}



ResetPassword.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) =>{
  return{
    resetERROR: state.auth.resetERROR,
    postERROR: state.auth.postERROR,    
    reset:state.auth.reset,
    fetching:state.auth.fetching
  }
}

const mapDispatchToProps =(dispatch)=>{
  return{
    passwordReset:(credentials)=>dispatch(passwordReset(credentials)),
    resetPassword:(credentials)=>dispatch(resetPassword(credentials))

  }
}

export default connect(mapStateToProps,mapDispatchToProps) (withStyles(styles)(ResetPassword));

