import { INVITE_REQUEST,INVITE_USER,INVITE_ERROR,INVITE_REFERRER } from '../actions/types'

const initialState = {
    invite_status:false,
    fetching:false,
    referrer_status:false

}

const reducer = (state = initialState, action) => {
    switch(action.type) {
        case INVITE_USER:
            return {
                ...state,
                invite_status:true,
                fetching:false
                
            };
        case INVITE_ERROR:
        //console.log('error')
            return {
                ...state,
                invite_status:false,
                fetching:false


            };
        case INVITE_REQUEST:
            return {
                ...state,
                fetching:true
            }
        case INVITE_REFERRER:
            return{
                ...state,
                fetching:false,
                referrer_status:true

            }
        default: 
            return {...state};
    }
}

export default reducer;