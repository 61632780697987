import React, { Component } from 'react'
import { connect } from 'react-redux'
import CircularProgress from '@material-ui/core/CircularProgress';
import {inviteUserLearn} from '../../actions/inviteAction'
import { Mixpanel } from '../../Mixpanel'



const results  =[
'15-18	No sign of burnout here.',
'19-32 Little sign of burnout here, unless some factors are particularly severe.',
'33-49 Be careful – you may be at risk of burnout, particularly if several scores are high.',
'50-59 You are at severe risk of burnout – do something about this urgently.',
'60-75 You are at very severe risk of burnout – do something about this urgently'
]

class BurnoutResults extends Component{

    state = {
        meaning:'',
        email:null,
        credits:null,
        invoice:null,
        name:null
    }

    componentDidMount(){
        let windowsize = this.props.results             

        if (15 < windowsize && windowsize < 18) {

            this.setState({meaning:results[0]})
        }else if (15 < windowsize && windowsize < 18) {
            this.setState({meaning:results[0]})

        }else if (19 < windowsize && windowsize < 32) {
            this.setState({meaning:results[1]})

        }else if (33 < windowsize && windowsize < 49) {
            this.setState({meaning:results[2]})

        }else if (50 < windowsize && windowsize < 59) {
            this.setState({meaning:results[3]})

        }else if (60 < windowsize && windowsize <= 75) {
            this.setState({meaning:results[4]})

        }
        
    }

    componentDidUpdate(oldProps,oldState){

        if(this.props.results !== oldProps.results){
               let windowsize = this.props.results             
                if (15 < windowsize && windowsize < 18) {

                    this.setState({meaning:results[0]})
                }else if (15 < windowsize && windowsize < 18) {
                    this.setState({meaning:results[0]})

                }else if (19 < windowsize && windowsize < 32) {
                    this.setState({meaning:results[1]})

                }else if (33 < windowsize && windowsize < 49) {
                    this.setState({meaning:results[2]})

                }else if (50 < windowsize && windowsize < 59) {
                    this.setState({meaning:results[3]})

                }else if (60 < windowsize && windowsize <= 75) {
                    this.setState({meaning:results[4]})

                }
                            
        }
    }

    handleChange = e=>{

        if(e.target.name === 'email'){
            this.setState({ [e.target.name]:e.target.value })
        }
        if(e.target.name === 'name'){
            this.setState({ [e.target.name]:e.target.value })
        }



  
      }

      handleSave = ()=>{
        let data ={
            name:this.state.name,
            email:this.state.email,
            permissions:'Burnout Quiz-'+this.props.results
        }


        Mixpanel.track('Burnout Quiz Email')


        this.props.createInvite(data)
        
      }




    render(){

        return(
            <div style={{width:490,margin:'0 auto'}}>
            <h3 style={{textAlign:'center'}}>And your result is ...  </h3>

                <h1 style={{textAlign:'center'}}>{this.props.results}</h1>

                <h3 style={{textAlign:'center'}} >{this.state.meaning}</h3>






                <div class="row flex-row">
                            <div class="col-xl-12 col-12">
                                <div class="widget has-shadow">
                                    <div class="widget-body">
                                    <h2 style={{textAlign:'center'}}>Get workplace mental health tips.</h2>
                                    <div class="form-group row d-flex align-items-top mb-5">
                                                <div class="col-lg-9" style={{margin:'0 auto'}}>
                                                <div class="form-group">
                                                        <div class="input-group">
                                                            <span class="input-group-addon addon-primary" style={{background:'#1bb1c3'}}>
                                                                <i class="la la-user"></i>
                                                            </span>
                                                            <input type="text" name="name" onChange={this.handleChange} placeholder="Name" class="form-control" style={{marginTop:0}} />
                                                        </div>
                                                    </div>

                                                    <div class="form-group">
                                                        <div class="input-group">
                                                            <span class="input-group-addon addon-primary" style={{background:'#1bb1c3'}}>
                                                                <i class="la la-envelope"></i>
                                                            </span>
                                                            <input type="text" name="email" onChange={this.handleChange} placeholder="Email Address" class="form-control" style={{marginTop:0}} />
                                                        </div>
                                                    </div>
                                    
                                                </div>

                                    </div>

                                    {this.props.invite_status===true ?(
<div>
  <div className="alert alert-outline-success" role="alert">
                                  <strong>Yay!</strong> You'll recieve workplace mental health tips in your inbox.
                              </div>

</div>):null}

                                    <div className="em-separator separator-dashed"></div>

                                    {this.props.fetching===true ?<CircularProgress style={{display:'block',margin:'0 auto'}} />:(
                                                                        <div className="button text-center" style={{display:'block',margin:'0 auto'}}>
                                                                        <button type="submit" className="button buttonGreen"  onClick={this.handleSave}>
                                                                        Send
                                                    <div className="ripples buttonRipples"><span className="ripplesCircle"></span></div>
                                                  </button>
                                                                        </div>)} 

                                        
                                    </div>

                                </div>
                            </div>
                        </div>



            </div>
            
        )
    }
}


const mapStateToProps = state =>{
    return{
        invite_status:state.invite.invite_status,
        fetching:state.invite.fetching
    }
}

const mapDispatchToProps = dispatch =>{
    return{
        createInvite:(data)=>dispatch(inviteUserLearn(data))
    }
}

export default connect(mapStateToProps,mapDispatchToProps) (BurnoutResults)