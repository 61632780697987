import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import Wrapper from '../../../components/wrapper/Wrapper';
import {loginAsync} from '../../../actions/authActions';

import {Redirect} from 'react-router-dom'
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import { Mixpanel } from '../../../Mixpanel'


const styles = theme => ({

  cssLabel: {
    '&$cssFocused': {
      color:"#1aa8bc",
    },
    '&:after': {
      borderBottomColor:"#1aa8bc",
    }  
  },
  cssFocused: {},
  cssUnderline: {
    '&:after': {
      borderBottomColor: "#1aa8bc",
    },
  },
  cssOutlinedInput: {
    '&$cssFocused $notchedOutline': {
      borderColor:  "#1aa8bc",
    },
  },

});

class Login extends Component {
  state = {
    email: '',
    password: '',
  }

  componentDidMount(){
    document.title="MentalHappy | Login "
    Mixpanel.track('Login Pageview');

  }
  render() {

    if(this.props.istemp === true){
      return(
        <Redirect to="/new-password" />

      )

    }
    if(this.props.auth === true ){
      return(
        <Redirect to="/dashboard" />
      )
    }

    const { classes } = this.props;

    //var errors;
    const {errorMessages} = this.props

   /* if(errorMessages.length !==0){
       errors = errorMessages.errors.map((error,index)=>{
        return(
          <div>
            <div className="alert alert-danger" role="alert">
              <strong>Opps!</strong> {error}
            </div>
          </div>

        )
      })
    }else{
       errors = false
    }
    */

    return (
      <Wrapper>
			<form className='auth-form' onSubmit={this.login}>

      <TextField
          label="Email"
          margin="normal"
          className="auth-input "

          value={this.state.email} onChange={this.updateEmail} 
          InputLabelProps={{
            classes:{
              root: classes.cssLabel,
              focused: classes.cssFocused
            }
          }}
        
        />

      <TextField
          label="Password"
          className="auth-input "
          margin="normal"
          type="password"
          value={this.state.password} onChange={this.updatePassword}     
          InputLabelProps={{
            classes:{
              root: classes.cssLabel,
              focused: classes.cssFocused

            }
          }}  
        />

  <div className="button-group">
  <button type="submit" className="button buttonGreen">Log in
    <div className="ripples buttonRipples"><span className="ripplesCircle"></span></div>
  </button>
  </div>
</form>
<div className="bottom-links">
<p className="reset-password-link">  Forgot Password? <Link to="reset-password">Reset</Link></p>
<p>Don't have an account? <Link to='/register'>Register</Link></p>
</div>

{this.props.error && (          <div>
  <div className="alert alert-danger" role="alert">
    <strong>Oops!</strong> Please check your credentials and try again.
  </div>
</div>)}
      </Wrapper>
    );
  }

  updateEmail = (e) => {
    this.setState({
      email: e.target.value,
    });
  }

  updatePassword = (e) => {
    this.setState({
      password: e.target.value,
    })
  }

  login = (e) => {
    e.preventDefault();
    //TODO: validate email and password
    //console.log(this.state)
     this.props.login(this.state);
    //this.props.history.push("/dashboard");
  }
}

const mapStateToProps = state => {
   return {
     errorMessages: state.auth.errorMessage,
     error:state.auth.error,
     auth:state.auth.auth,
     istemp:state.auth.istemp
   }
 }

const mapDispatchToProps = dispatch => {
  return {
    login: (data) => dispatch(loginAsync(data)),
  }
}


export default connect(mapStateToProps, mapDispatchToProps) (withStyles(styles)(Login));
