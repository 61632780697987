import React from 'react';
import {injectStripe} from 'react-stripe-elements';

import CardSection from './CardSection';
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom';

import {creditsPayment,setToken } from '../../actions/paymentActions'

class CheckoutForm extends React.Component {

    state ={
      token:null,
      buttonClass:'',
      error:false,
      errormessage:'',
      buttontext:'Confirm Order'
    }
  handleSubmit = (ev) => {
    // We don't want to let default form submission happen here, which would refresh the page.
    ev.preventDefault();

    // Within the context of `Elements`, this call to createToken knows which Element to
    // tokenize, since there's only one in this group.
  this.props.stripe.createToken({name: this.props.user.firstName+' '+this.props.user.lastName}).then(({token, error}) => {

    if (error) {

      this.setState({error:true,errormessage:error.message,errorcode:error.code})
      
    } else {


      this.setState({error:false})
      this.props.setToken(token.id)

    var data ={
      token: token.id,
      charge_type:'credits',
      resource_id:this.props.setprice.id
      

    }

    this.props.makePayment(data)
    if(this.props.fetching === true){
      this.setState({buttontext:'Processing'})
    }else{
      this.setState({buttonClass:''})
    }


  }

  }).catch(err => alert(err)) ;
  



    // However, this line of code will do the same thing:
    //
    // this.props.stripe.createToken({type: 'card', name: 'Jenny Rosen'});

    // You can also use createSource to create Sources. See our Sources
    // documentation for more: https://stripe.com/docs/stripe-js/reference#stripe-create-source
    //
    // this.props.stripe.createSource({type: 'card', owner: {
    //   name: 'Jenny Rosen'
    // }});
  };

  render() {

    if(this.props.ispaid === true){
      this.props.history.push('success')
  
      }
      return (
        <div>
        <form onSubmit={this.handleSubmit}  >
                            
          <CardSection />
  
                                <button to="/cheerbox/set-recipient-name" className={"button buttonGreen "+this.state.buttonClass}>{this.state.buttontext}
                                    <div className="ripples buttonRipples"><span className="ripplesCircle"></span></div>
                                </button>


                      </form>

                      {this.props.ispaid===false && (          <div>
                        <div className="alert alert-danger" role="alert">
                          <strong>Payment Error!</strong> We had an issue processing your payment. Please try again or contact us at support@cheerbox.com
                        </div>
                      </div>)}

                      {this.state.error===true && (          <div>
                        <div className="alert alert-danger" role="alert">
                          <strong>Payment Error!</strong> {this.state.errormessage}
                        </div>
                      </div>)}
                      </div>
      );


  }
}


const mapStateToProps = state =>{
  return{
    user: state.user.user,
    setprice:state.pricing.setprice,
    fetching:state.payment.fetching,
    ispaid:state.payment.ispaiderror

  }
}

const mapDispatchToProps = dispatch =>{
  return{
   setToken: token => dispatch(setToken(token)),
   makePayment:(data)=>dispatch(creditsPayment(data))
  }
}

export default withRouter(connect(mapStateToProps,mapDispatchToProps) (injectStripe(CheckoutForm)));