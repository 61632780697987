import React, { Component } from 'react';
import CheerboxLocatorMapLive from './CheerboxLocatorMapLive';
import DateSelector from '../date-picker/DateSelector';
import { connect } from 'react-redux';
import * as actionTypes from '../../actions/dashboardActions';
class CheerboxLocator extends Component {
  render() {
    return (

      <div className="row">
  
  <div className="col-md-6 col-sm-6">
            <div className="widget widget-12 has-shadow">
              <div className="widget-body">
              <div style={{ height: '66vh', width: '90%' }}>
            
        <CheerboxLocatorMapLive places={this.props.LocationsCounter}/>
           
                   </div>
                   </div>
        </div>
      </div>
    
<div className="col-md-6" >
<div className="widget has-shadow">
                                    <div className="widget-header bordered no-actions d-flex align-items-center">
                                        <h4>Kit Locator  </h4>



                                    </div>
        <div className="widget-body">
        <div className="table-responsive">
<div id="sorting-table_wrapper" className="dataTables_wrapper container-fluid dt-bootstrap4 no-footer">

<div className="row">
<div className="col-sm-12 "  style={{height:345,overflow:'scroll'}}>
<table id="sorting-table" className="table mb-0 dataTable no-footer" role="grid" aria-describedby="sorting-table_info">
<thead>
<tr role="row">
<th className="sorting" tabIndex="0" aria-controls="sorting-table" rowSpan="1" colSpan="1" aria-label="Order ID: activate to sort column ascending" style={{width: 76}}>Location</th>
<th className="sorting" tabIndex="0" aria-controls="sorting-table" rowSpan="1" colSpan="1" aria-label="Customer Name: activate to sort column ascending" style={{width: 143}}> Cheerboxes</th>




</tr>
</thead>
<tbody>




{  this.props.LocationsCounter.map((location,index) => {
return (<tr role="row" className="odd" key={index}>


<td className="sorting_1">{location.city}</td>
<td className="sorting_1">{location.total}</td>



</tr>


)
})}




</tbody>
</table>
</div>
</div>

</div>
</div>
</div>
    </div>

</div>


</div>
    );
  }
}


const mapStateToProps = state => {
    return {
        LocatorStartDate: state.dashboard.LocatorStartDate,
        LocationsCounter: state.dashboard.groupstates
        
    };
  };
  const mapDispatchToProps = dispatch => {
    return {
      onDateChanged: (date, type) => dispatch(actionTypes.filterDate(date, type)),
    }
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(CheerboxLocator);

