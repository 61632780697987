import axios from 'axios'
export const SEND_CHEER = "SEND_CHEER";
export const SET_TYPE = "SET_TYPE";
export const SET_NAME = "SET_NAME";
export const SET_NOTE = "SET_NOTE";
export const SET_REASON = "SET_REASON";
export const SET_RELATIONSHIP = "SET_RELATIONSHIP";
export const SET_DETAILS = "SET_DETAILS";
export const SET_ADDRESS = "SET_ADDRESS";
export const CREATE_ORDER ="CREATE_ORDER"
export const CREATE_REQUEST ="CREATE_REQUEST"
export const REQUEST_ERROR ="REQUEST_ERROR"
export const CREDIT_ERROR ="CREDIT_ERROR"

const basedev = "https://api.mentalhappy.com/api/"

const sendCheer = () => {
    return {
        type: SEND_CHEER,
    }
}

export const sendCheerAsync = () => {
    return dispatch => {
        setTimeout(()=>{
            dispatch(sendCheer());
        }, 4000);
    }
}


export const createOrder=(data)=>dispatch=>{

    
    var endpoint = basedev +'order'
    var headers = {
        'Accept': 'application/json',
        'Authorization':"Bearer " + localStorage.getItem('access_token')

    } 
        dispatch({
            type:CREATE_REQUEST
        })
      axios.post(endpoint,data, {headers: headers})
        .then(res => {
            if(res.data.status ==='failed' && res.data.reason === 'no_credits'){
                dispatch({
                    type:CREDIT_ERROR,
                    payload:null
                })                
            }else{
                dispatch({
                    type:CREATE_ORDER,
                    payload: res.data
                })
            }


        }).catch(error=>{

                dispatch({
                    type:REQUEST_ERROR,
                    payload:null
                })


        })
}

