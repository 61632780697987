import React, { Component } from 'react';
import { Map, GoogleApiWrapper, InfoWindow, Marker  } from 'google-maps-react';

const mapStyles = {
    width: '92%',
    height: '92%'
  };


class CheerboxLocatorMapLive extends Component {
   
  state = {
    showingInfoWindow: false,  //Hides or the shows the infoWindow
    activeMarker: {},          //Shows the active marker upon click
    selectedPlace: {}          //Shows the infoWindow to the selected place upon a marker
  };


  onMarkerClick = (props, marker, e) =>
  this.setState({
    selectedPlace: props,
    activeMarker: marker,
    showingInfoWindow: true
  });

onClose = props => {
  if (this.state.showingInfoWindow) {
    this.setState({
      showingInfoWindow: false,
      activeMarker: null
    });
  }
};

    render() {
  
        return (
       
     
    <Map google={this.props.google} zoom={3} style={mapStyles}
      initialCenter={{ lat: 40.6710729, lng: -73.9988001 }}

      
      center={{ lat: 40.6710729, lng: -73.9988001 }}
    places={this.props.places} 
    >






    {this.props.places.map(place => {

    let lat = parseFloat(place.latitude, 10);
    let lng = parseFloat(place.longitude, 10);

  

      return ( <Marker
        id={place.id} 
        key={place.id}
        position={{ lat: place.latitude, lng: place.longitude }}
      
        title={place.city}
  
      />)
    }) }
   
{/* 
        <InfoWindow
          marker={this.state.activeMarker}
          visible={this.state.showingInfoWindow}
          onClose={this.onClose}
        >
          <div>
            <h4>{this.state.selectedPlace.name}</h4>
          </div>
        </InfoWindow> */}

      </Map>
           
    
        );
      }
    }
    
    export default GoogleApiWrapper({
      apiKey: 'AIzaSyAveZg5wxB7FynC1nftLglJubXbsAYiCfw'
    })(CheerboxLocatorMapLive);

