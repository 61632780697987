import React, { Component } from 'react';
import DateSelector from '../date-picker/DateSelector';

class SummaryWidget extends Component {
  render() {
    return (
      <div className="col-xl-4 col-md-6 col-sm-6">
        <div className="widget widget-12 has-shadow">
          <div className="widget-body">
            <div className="media">
              <div className="align-self-center ml-5 mr-5">

                <span className="dashboard-count total-cheerbox-sent">
                  {this.props.count} </span>
                {/* <div> <i className="la la-calendar"></i> </div> */}


            
                <div style={{position: 'relative'}}>
              
                 
                </div>
              </div>

              <div className="media-body align-self-center">
                <div className="title text-twitter">{this.props.title}</div>
                <div className="number">{this.props.action}</div>
            
                {/*<div className="col-lg-12">
                                                    <div className="form-group">
                                                        <div className="input-group">
                                                            <span className="input-group-addon">
                                                                <i className="la la-calendar"></i>
                                                            </span>
                                                            <input type="text" className="form-control date" id="date" placeholder="Select value"/>
                                                        </div>
                                                    </div>
    </div>*/}


              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SummaryWidget;
