import * as actions from '../actions/dashboardActions';

const initialState = {
    totalDate : new Date(),
    totalCount : 0,
    pendingDate : new Date(),
    pendingCount : 0,
    creditDate : new Date(),
    creditCount : 0,
    recipientStartDate: new Date(),
    LocatorStartDate: new Date(),
    FeedbackStartDate: new Date(),
    groupstates:[],
    orders:[],
    company:{},
    made:0,
    notmade:0,
    paid:0,
    cheerboxFeedback: [
        {
        name: 'Tom Smith',
        feedback: "I was touched by the Cheerbox I got.",
        date: new Date(),
        },

        {
            name: 'Mark Wilson',
            feedback: "This cheerbox came at the right time. Thanks for the love",
            date: new Date(),
            },
            {
                name: 'Jane Doe',
                feedback: "Thanks for the cheerbox. It sparkiling",
                date: new Date(),
                },
      
    ],
    cheerboxLocations: [
        {
            "id":1,
            "name": "Park Slope",
            "latitude": "40.6711937",
            "longitude": "-73.9989605",

            cheerbox_count: 45
          },
          {
            "id": 2,
            "name": "Bushwick",
            "latitude": "40.6942861",
            "longitude": "-73.9389312",
            cheerbox_count: 15
          },
          {
            "id": 3,
            "name": "East New York",
            "latitude": "40.6577799",
            "longitude": "-73.9147716",
            cheerbox_count: 6
          },
          {
            "id": 4,
            "name": "New York",
            "latitude": "40.6976701",
            "longitude": "-74.2598758",
            cheerbox_count: 11
          },

          {
            "id": 5,
            "name": "Denver",
            "latitude": "39.7645187",
            "longitude": "-104.9951982",
            cheerbox_count: 19
          },

          {
            "id": 5,
            "name": "Washington D.C",
            "latitude": "38.89378",
            "longitude": "-77.1546629",
            cheerbox_count: 9
          },

       
        
                            
    ],
    cheerboxRecipients: [
        {
            recipient: 'kofi',
            type: 'loss',
            sender: 'john',
            date: new Date(),
        },
        {
            recipient: 'kofi',
            type: 'illness',
            sender: 'john',
            date: new Date(),
        },
        {
            recipient: 'kofi',
            type: 'death',
            sender: 'john',
            date: new Date(),
        },
        {
            recipient: 'kofi',
            type: 'loss',
            sender: 'john',
            date: new Date(),
        },
        {
            recipient: 'kofi',
            type: 'loss',
            sender: 'john',
            date: new Date(),
        },
        {
            recipient: 'kofi',
            type: 'death',
            sender: 'john',
            date: new Date(),
        },
        {
            recipient: 'kofi',
            type: 'loss',
            sender: 'john',
            date: new Date(),
        },
        {
            recipient: 'kofi',
            type: 'illness',
            sender: 'john',
            date: new Date(),
        },
        {
            recipient: 'kofi',
            type: 'loss',
            sender: 'john',
            date: new Date(),
        }
    ]
    
}

const reducer = (state = initialState, action) => {
    switch(action.type) {
        case actions.SET_DATE:
            console.log("datee set successfully", action.payload);
            return {
                ...state,
                type: action.payload.type,
            };
        case actions.FILTER_DATE:
            console.log("datea set successfully", action.payload);
            return {
                ...state,
                type: action.payload.type,
            };
        case actions.FILTER_DATE_ASYNC:
            console.log("dates set successfully", action.payload);
            return {
                ...state,
                type: action.payload.type,
            };
        case actions.GET_DASHBOARD:
            console.log("dates set successfully", action.payload);
            return {
                ...state,
                type: action.payload.type,
                totalCount:action.payload.orders.length,
                pendingCount:action.payload.unpaid,
                groupstates:action.payload.groupstates,
                orders:action.payload.orders,
                company:action.payload.clients,
                creditCount:action.payload.totalcredits,
                made:action.payload.made,
                notmade:action.payload.notmade

            };            
        default: 
            return {...state};
    }
}

export default reducer;