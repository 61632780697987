import axios from 'axios';
import { INVITE_REQUEST,INVITE_USER,INVITE_ERROR,INVITE_REFERRER} from './types'

const basedev = "https://api.mentalhappy.com/api/"

export const inviteUser = (data) =>dispatch=> {

    var endpoint = basedev +'invite'
    var headers = {
        'Authorization':"Bearer " + localStorage.getItem('access_token')

    } 
        dispatch({
            type:INVITE_REQUEST
        })
      axios.post(endpoint,data, {headers: headers})
        .then(res => {

            dispatch({
                type:INVITE_USER,
                payload: res.data
            })
        }).catch(error=>{
            dispatch({
                type:INVITE_ERROR,
                payload:null
            })
        })
}



export const inviteUserLearn = (data) =>dispatch=> {

    var endpoint = basedev +'mhtips'
    var headers = {
        'Authorization':null

    } 
        dispatch({
            type:INVITE_REQUEST
        })
      axios.post(endpoint,data, {headers: headers})
        .then(res => {

            dispatch({
                type:INVITE_USER,
                payload: res.data
            })
        }).catch(error=>{
            dispatch({
                type:INVITE_ERROR,
                payload:null
            })
        })
}


export const referrerInvite = (data) =>dispatch=> {

    var endpoint = basedev +'invitereferrer'
    var headers = {
        'Authorization':"Bearer " + localStorage.getItem('access_token')

    } 
        dispatch({
            type:INVITE_REQUEST
        })
      axios.post(endpoint,data, {headers: headers})
        .then(res => {

            dispatch({
                type:INVITE_REFERRER,
                payload: res.data
            })
        }).catch(error=>{
            dispatch({
                type:INVITE_ERROR,
                payload:null
            })
        })

}

