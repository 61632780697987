import React, { Component } from 'react';

class Notification extends Component {
  render() {
    return (
      <li className="nav-item dropdown"><a id="notifications" rel="nofollow" data-target="#" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className="nav-link"><i className="la la-bell animated infinite swing"></i><span className="badge-pulse"></span></a>
        <ul aria-labelledby="notifications" className="dropdown-menu notification">
          <li>
            <div className="notifications-header">
              <div className="title">Notifications (4)</div>
              <div className="notifications-overlay"></div>
            </div>
          </li>
          <li>
            <a href="#">
              <div className="message-icon">
                <i className="la la-user"></i>
              </div>
              <div className="message-body">
                <div className="message-body-heading">
                  New user registered
                                        </div>
                <span className="date">2 hours ago</span>
              </div>
            </a>
          </li>
          <li>
            <a href="#">
              <div className="message-icon">
                <i className="la la-calendar-check-o"></i>
              </div>
              <div className="message-body">
                <div className="message-body-heading">
                  New event added
                                        </div>
                <span className="date">7 hours ago</span>
              </div>
            </a>
          </li>
          <li>
            <a href="#">
              <div className="message-icon">
                <i className="la la-history"></i>
              </div>
              <div className="message-body">
                <div className="message-body-heading">
                  Server rebooted
                                        </div>
                <span className="date">7 hours ago</span>
              </div>
            </a>
          </li>
          <li>
            <a href="#">
              <div className="message-icon">
                <i className="la la-twitter"></i>
              </div>
              <div className="message-body">
                <div className="message-body-heading">
                  You have 3 new followers
                                        </div>
                <span className="date">10 hours ago</span>
              </div>
            </a>
          </li>
          <li>
            <a rel="nofollow" href="#" className="dropdown-item all-notifications text-center">View All Notifications</a>
          </li>
        </ul>
      </li>
    );
  }
}

export default Notification;
