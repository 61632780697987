import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';

import './Auth.css';
import Wrapper from '../../components/wrapper/Wrapper';
import CheerBoxLogo from '../../assets/img/cheerbox-logo.svg';
import Email from '../../assets/img/email.svg';
import Login from './login/Login';
import Register from './register/SignUp';
import VerifyEmail from './verify-email/VerifyEmail';
import ResetPassword from './reset-password/ResetPassword';
import NewPassword from './reset-password/NewPassword';

class Auth extends Component {
  render() {
    return (
      <Wrapper>
        <div className="logo-section">
			<div className="container">
				<div className="row">
					<div className="col-md-4 head-group">
						<div className="mental-happy-logo">
							<img src='../../assets/img/mental-happy-logo.png' alt="Mental happy logo" width='120'/>
						</div>
					</div>
				</div>
			</div>
		</div>
			<div className="header">
				<div className="container">
					<div className="row">
						<div className="col-md-4"></div>
						<div className="col-md-4 ">
			<div className="page-title">

			<Switch>
				<Route path='/login' render={() => (<h5>LOGIN TO YOUR ACCOUNT</h5>)}/>
				<Route path='/join' render={() => (<h5>REGISTER FOR AN ACCOUNT</h5>)}/>
				<Route path='/verify-email' render={() => (<Wrapper>
					<h5>VERIFY YOUR EMAIL</h5>
					<p className=""> <img src={Email} width="60"/> </p>
				</Wrapper>)}/>
				<Route path='/reset-password' render={() => (<h5>RESET YOUR PASSWORD</h5>)}/>
				<Route path='/new-password' render={() => (<h5>CREATE NEW PASSWORD</h5>)}/>
				<Route path='/' render={() => (<h5>LOGIN TO YOUR ACCOUNT</h5>)}/>
			</Switch>

			</div>
						</div>
					</div>
				</div>
			</div>
			<div className="form-box">
				<div className="container">
					<div className="row">
						<div className="col-md-4">
						
						</div>
						<div className="col-md-4">
						
			<Switch>
				<Route path='/login' component={Login}/>
				<Route path='/join' component={Register}/>
				<Route path='/verify-email' component={VerifyEmail}/>
				<Route path='/reset-password' component={ResetPassword}/>
				<Route path='/new-password' component={NewPassword}/>

				<Route path='/' component={Login}/>

			</Switch>


			</div>
			<div className="col-md-4"></div>
						</div>
					</div>
				</div>
			<div className="footer">
				<div className="container">
					<div className="row">
					<div className="col-md-4"></div>
						<div className="col-md-4">
						</div>
						
					</div>
				</div>
			</div>
      	</Wrapper>
    );
  }
}
export default Auth;
