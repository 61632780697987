import React, { Component } from 'react';
import { Route, Switch, BrowserRouter,HashRouter } from 'react-router-dom';

import Dashboard from './screens/dashboard/Dashboard';
import Auth from './screens/auth/Auth';
import Cheerbox from './screens/cheerbox/Cheerbox';
import Pricing from './screens/pricing/Pricing';
import Payment from './screens/payment/Payment';
import {StripeProvider} from 'react-stripe-elements';
import OrderFlow from './screens/order/OrderFlow';
import agreement from './screens/onboarding/agreement';
import PaymentSuccess from './screens/payment/PaymentSuccess';
import {connect} from 'react-redux'
import {getCompany} from '../src/actions/clientActions'
import Signup from './screens/auth/register/SignUp'
import ReferrerPage from './screens/payment/ReferrerPage';
import burnout from './screens/quizes/burnout';
class App extends Component {

  componentDidMount(){
    this.props.getCompany()
  }
  render() {
    return (

<HashRouter  >
        <Switch>
        <Route path='/dashboard' component={Dashboard} />
        <Route path='/(login|join|reset-password|verify-email|new-password)/' component={Auth} />
        <Route path='/cheerbox' component={Cheerbox} />
        <Route path='/pricing'  component={Pricing} />
        <Route path='/payment' component={Payment} />
        <Route path='/order' component={OrderFlow} />
        <Route path='/register' component={agreement} />
        <Route path='/success' component={PaymentSuccess} />
        <Route path='/signup' component={Signup} />
        <Route path='/referrer' component={ReferrerPage} />
        <Route path='/burnout/quiz' component={burnout} />

        <Route exact={true} path='/' component={Auth} />
          <Route path='*' render={() => (<div>Not found</div>)} />
          {/* <Route path='*' component={Dashboard} /> */}
        </Switch>
        </HashRouter  >
    );
  }
}

const mapDispatchToProps = dispatch=>{
  return{
    getCompany:()=>dispatch(getCompany())
  }
}

export default connect(null,mapDispatchToProps)(App);
